import { SlotId } from '../config';
import { EventTypes } from '../global.d';
import {
  setGameMode,
  setIsErrorMessage,
  setUserLastBetResult,
  setPrevReelsPosition,
  setSlotConfig,
  setIsSlotBusy,
  setIsSpinInProgress,
} from '../gql/cache';
import SlotMachine from '../slotMachine';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_START_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_ID,
  BONUS_SYMBOLS_ID,
  CHERRY_SYMBOLS_ID,
  REELS_AMOUNT,
  SlotMachineState,
  eventManager,
} from '../slotMachine/config';
import { Icon } from '../slotMachine/d';
import { isFreeSpinsMode } from './helper';

const getFreeSpinSymbolSoundId = (spinResult: Array<Icon>): Array<number> => {
  const soundIds = [0, 0, 0];
  const line = spinResult.slice(3, 6);

  for (let index = 0; index < line.length; index++) {
    const symbol = line[index];
    if (symbol.id === SlotId.GC) {
      soundIds[index] = index + 1;
    } else {
      break;
    }
  }

  return soundIds;
};

const calcStopSoundSymbolCount = (
  spinResult: Array<Icon>,
  cal: number,
  symbol: Array<SlotId>[],
): Array<number> => {
  const iSoundCnt = [0, 0, 0];

  setSlotConfig().winLines.forEach((line: number[]) => {
    symbol.forEach((slot) => {
      for (let x = 0; x < slot.length; x++) {
        if (spinResult?.length && spinResult[line[x]].id === slot[x]) {
          if (x === 0) {
            iSoundCnt[x] = cal;
          } else {
            iSoundCnt[x] = iSoundCnt[x - 1] + 1;
          }
        } else {
          break;
        }
      }
    });
  });
  return iSoundCnt;
};

const getCherryAnticipationReelId = (
  spinResult: Array<Icon>,
): [number, number] => {
  const lineResult = setSlotConfig().winLines.map((line: number[]) =>
    line.map((v) => spinResult[v]),
  );

  const count = lineResult.reduce((max, line) => {
    let count = 0;
    for (const icon of line) {
      if (icon.id === SlotId.G) {
        count += 1;
      } else {
        break;
      }
    }
    return Math.max(count, max);
  }, 0);

  const startId = count > 0 ? 0 : REELS_AMOUNT;
  const endId = count;

  return [startId, endId];
};

const getStopSoundSymbolCount = (spinResult: Array<Icon>): Array<number> => {
  let reelStopSound: number[];
  if (isFreeSpinsMode(setGameMode())) {
    reelStopSound = getFreeSpinSymbolSoundId(spinResult);
  } else {
    reelStopSound = calcStopSoundSymbolCount(spinResult, 1, BONUS_SYMBOLS_ID);

    if (reelStopSound.every((current) => current === 0)) {
      reelStopSound = calcStopSoundSymbolCount(
        spinResult,
        4,
        CHERRY_SYMBOLS_ID,
      );
    }
  }
  return reelStopSound;
};

const getAnticipationStartReelId = (spinResult: Array<Icon>): number => {
  if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
  let minReelId = REELS_AMOUNT;

  const lineResult = setSlotConfig().winLines.map((line) =>
    line.map((v) => spinResult[v]),
  );

  ANTICIPATION_SYMBOLS_ID.forEach((symbolId, i) => {
    const count = ANTICIPATION_START_SYMBOLS_AMOUNT[i];
    if (
      lineResult.some((line) =>
        line.slice(0, count).every((icon: Icon) => icon.id === symbolId),
      )
    ) {
      minReelId = Math.min(minReelId, count - 1);
    }
  });
  return minReelId;
};

export const fallBackReelPosition = () => {
  setIsErrorMessage(true);
  if (!SlotMachine.getInstance()) return;
  if (setUserLastBetResult().result.spinResult) {
    console.log(setUserLastBetResult());
    eventManager.emit(
      EventTypes.SETUP_REEL_POSITIONS,
      setUserLastBetResult().result.reelPositions,
      getStopSoundSymbolCount(setUserLastBetResult().result.spinResult!),
      getAnticipationStartReelId(setUserLastBetResult().result.spinResult!),
      getCherryAnticipationReelId(setUserLastBetResult().result.spinResult!),
    );
    eventManager.emit(EventTypes.FORCE_STOP_REELS, true);
    eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
  } else {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    SlotMachine.getInstance().state = SlotMachineState.IDLE;
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, true);
    eventManager.emit(
      EventTypes.SLOT_MACHINE_STATE_CHANGE,
      SlotMachineState.IDLE,
    );
    setIsSpinInProgress(false);
    setIsSlotBusy(false);
    eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
  }
  // eventManager.emit(EventTypes.FORCE_STOP_REELS);
  // eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
};
