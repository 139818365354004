import { GAME_CONTAINER_WIDTH } from '../config';

export const BANNER_POSITION_X = GAME_CONTAINER_WIDTH / 2;
export const BANNER_POSITION_Y = -120;

// WIN BANNER
export const MESSAGE_WIN_BANNER_TITLE_Y = 130;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_Y = 330;
export const MESSAGE_WIN_BANNER_BONUS_STREAK_Y = 600;
export const MESSAGE_WIN_BANNER_BONUS_STREAK_MARGIN = 45;
