export enum ResourceTypes {
  freeSpinReelFrame = 'freeSpinReelFrame',
  freeSpinReelFrameRedtile = 'freeSpinReelFrameRedtile',
  freeSpinLight = 'freeSpinLight',
  freeSpinLine = 'freeSpinLine',
  messageBannerBonus = 'messageBannerBonus',
  reelFrame = 'reelFrame',
  reelFrameRedtile = 'reelFrameRedtile',
  titleLogo = 'titleLogo',
  messageBanner = 'messageBanner',
  largeLine = 'largeLine',
  logo = 'logo',
  mediumLine = 'mediumLine',
  shortLine = 'shortLine',
  blurFreeSpinSymbolBBlank = 'blurFreeSpinSymbolBBlank',
  blurSymbolCLion = 'blurSymbolCLion',
  blurSymbolDWatermelon = 'blurSymbolDWatermelon',
  blurSymbolEBell = 'blurSymbolEBell',
  blurSymbolFBlue = 'blurSymbolFBlue',
  blurSymbolGCherry = 'blurSymbolGCherry',
  freeSpinSymbolAGoldenCherry = 'freeSpinSymbolAGoldenCherry',
  freeSpinSymbolBBlank = 'freeSpinSymbolBBlank',
  symbolASeven = 'symbolASeven',
  symbolBBar = 'symbolBBar',
  symbolCLion = 'symbolCLion',
  symbolDWatermelon = 'symbolDWatermelon',
  symbolEBell = 'symbolEBell',
  symbolFBlue = 'symbolFBlue',
  symbolGCherry = 'symbolGCherry',
  backgroundBase = 'backgroundBase',
  backgroundStoneWall = 'backgroundStoneWall',
  backgroundThicket = 'backgroundThicket',
  highChance2BGBase = 'highChance2BGBase',
  highChance2BGStoneWall = 'highChance2BGStoneWall',
  highChance2BGThicket = 'highChance2BGThicket',
  highChanceBGBase = 'highChanceBGBase',
  highChanceBGStoneWall = 'highChanceBGStoneWall',
  highChanceBGThicket = 'highChanceBGThicket',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  infoPaylines2 = 'infoPaylines2',
  intro01 = 'intro01',
  intro02 = 'intro02',
  intro03 = 'intro03',
  intro04 = 'intro04',
  introBg = 'introBg',
  textLogo = 'textLogo',
}
