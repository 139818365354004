import _ from 'lodash';
import * as PIXI from 'pixi.js';

import {
  MAPPED_SYMBOLS,
  MAPPED_SYMBOLS_ANIMATIONS,
  SlotId,
  SymbolAnimationType,
} from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import { setSlotConfig } from '../../gql/cache';
import { destroySpine } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import SpriteAnimation from '../animations/sprite';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  APPLICATION_FPS,
  eventManager,
  REEL_WIDTH,
  REELS_AMOUNT,
  SHOW_ALL_LINES_ON_WIN,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT,
  WIN_SLOT_ANIMATION_DURATION,
  WIN_SLOT_ANIMATION_SCALE,
} from '../config';
import { Icon, IWinLine } from '../d';
import { Slot } from '../slot/slot';

class WinSlotsContainer extends ViewContainer {
  private winSlotsContainer: ViewContainer[];

  private slotsContainer: Slot[][];

  public animation: AnimationChain | null = null;

  public loopAnimation: Animation | null = null;

  constructor() {
    super();
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.winSlotsContainer = [];
    this.slotsContainer = [];
    eventManager.addListener(
      EventTypes.SKIP_WIN_SLOTS_ANIMATION,
      this.skipWinSlotsAnimation.bind(this),
    );
    eventManager.addListener(
      EventTypes.START_WIN_ANIMATION,
      this.onStartWinAnimation.bind(this),
    );
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const slotsArray = [];
      const container = new ViewContainer();
      container.width = SLOT_WIDTH;
      container.height = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
      container.x = i * REEL_WIDTH;
      for (let j = 0; j < SLOTS_PER_REEL_AMOUNT; j++) {
        const slot = new Slot(j, SlotId.A);
        slot.anchor.set(0.5, 0.5);
        slot.y += SLOT_HEIGHT / 2;
        slot.visible = false;
        slotsArray.push(slot);
        container.addChild(slot);
      }
      this.slotsContainer.push(slotsArray);
      this.winSlotsContainer.push(container);
      this.addChild(container);
    }
  }

  private getIconBySlotId(slotId: SlotId): Icon {
    return _.find(setSlotConfig().icons, (icon) => icon.id === slotId)!;
  }

  private onStartWinAnimation(
    nextResult: ISettledBet,
    isTurboSpin: boolean,
  ): void {
    this.showWin(nextResult, isTurboSpin);
  }

  private skipWinSlotsAnimation(): void {
    this.animation?.skip();
    this.loopAnimation?.skip();
  }

  private createSymbolShining(id: number): Animation {
    const dummy = Tween.createDelayAnimation(2000);
    // let animation: SpineAnimation | undefined;
    // dummy.addOnStart(() => {
    //   animation = new SpineAnimation(
    //     {},
    //     PIXI.Loader.shared.resources.win_frame.spineData,
    //   );
    //   animation.spine.x = REEL_WIDTH / 2;
    //   animation.spine.y = SLOT_HEIGHT * Math.floor(id / 5) + SLOT_HEIGHT / 2;
    //   this.winSlotsContainer[id % 5].addChild(animation.spine);
    //   animation.spine.state.setAnimation(0, 'win_frame', false);
    // });

    // dummy.addOnComplete(() => {
    //   if (animation?.spine)
    //     this.winSlotsContainer[id % 5].removeChild(animation.spine);
    // });
    // dummy.addOnSkip(() => {
    //   if (animation?.spine)
    //     this.winSlotsContainer[id % 5].removeChild(animation.spine);
    // });
    return dummy;
  }

  public highlightSlots(
    slots: number[],
    spinResult: Icon[],
    isTurboSpin: boolean | undefined,
  ): AnimationGroup {
    const animationGroup = new AnimationGroup({});
    slots.forEach((slotId) => {
      if (
        MAPPED_SYMBOLS_ANIMATIONS[spinResult[slotId].id].type ===
        SymbolAnimationType.SPINE
      ) {
        // animationGroup.addAnimation(this.createSymbolShining(slotId));
        animationGroup.addAnimation(
          this.createSlotSpineAnimation(
            slotId,
            MAPPED_SYMBOLS_ANIMATIONS[spinResult[slotId].id].src!,
            MAPPED_SYMBOLS_ANIMATIONS[spinResult[slotId].id].animation!,
            !!isTurboSpin,
          ),
        );
      }

      if (
        MAPPED_SYMBOLS_ANIMATIONS[spinResult[slotId].id].type ===
        SymbolAnimationType.SPRITE
      ) {
        const sheet = this.getSlotAnimationSheet(spinResult[slotId].id);
        animationGroup.addAnimation(
          this.createSlotSpriteAnimation(sheet, slotId, isTurboSpin),
        );
      }

      if (
        MAPPED_SYMBOLS_ANIMATIONS[spinResult[slotId].id].type ===
        SymbolAnimationType.DEFAULT
      ) {
        const slot =
          this.slotsContainer[slotId % REELS_AMOUNT][
            Math.floor(slotId / REELS_AMOUNT)
          ];
        slot.texture = PIXI.Texture.from(MAPPED_SYMBOLS[spinResult[slotId].id]);
        slot.visible = false;
        animationGroup.addAnimation(
          this.createSlotScaleAnimation(slot, isTurboSpin),
        );
        animationGroup.addOnStart(() => {
          slot.visible = true;
        });
      }
    });
    animationGroup.addOnStart(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], false);
    });
    animationGroup.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], true);
      this.hideAllSlots();
    });
    animationGroup.addOnSkip(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], true);
      this.hideAllSlots();
    });
    return animationGroup;
  }

  private createSlotSpineAnimation(
    id: number,
    srcName: string,
    animationName: string,
    isTurboSpine: boolean,
  ): Animation {
    const dummy = Tween.createDelayAnimation(2000);
    let animation: SpineAnimation | undefined;
    dummy.addOnStart(() => {
      animation = new SpineAnimation(
        {},
        PIXI.Loader.shared.resources[srcName].spineData,
      );
      animation.spine.x = REEL_WIDTH / 2;
      animation.spine.y =
        SLOT_HEIGHT * Math.floor(id / REELS_AMOUNT) + SLOT_HEIGHT / 2;
      this.winSlotsContainer[id % REELS_AMOUNT].addChild(animation.spine);
      animation.spine.state.setAnimation(0, animationName, false);
    });

    dummy.addOnComplete(() => {
      if (animation) {
        this.winSlotsContainer[id % REELS_AMOUNT].removeChild(animation.spine);
        destroySpine(animation);
      }
    });
    dummy.addOnSkip(() => {
      if (animation) {
        this.winSlotsContainer[id % REELS_AMOUNT].removeChild(animation.spine);
        destroySpine(animation);
      }
    });
    return dummy;
  }

  private getSlotAnimationSheet(slotId: SlotId): PIXI.Spritesheet | undefined {
    return _.get(
      PIXI.Loader.shared.resources,
      MAPPED_SYMBOLS_ANIMATIONS[slotId].src!,
    ).spritesheet;
  }

  private createSlotSpriteAnimation(
    sheet: PIXI.Spritesheet | undefined,
    id: number,
    isTurboSpin: boolean | undefined,
  ): Animation {
    const animatedSprite = new SpriteAnimation(
      {},
      Object.values(sheet?.textures),
    );
    animatedSprite.spriteAnimation.animationSpeed =
      (isTurboSpin
        ? animatedSprite.spriteAnimation.totalFrames *
          TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT
        : animatedSprite.spriteAnimation.totalFrames) / APPLICATION_FPS;
    animatedSprite.spriteAnimation.x = SLOT_WIDTH / 2;
    animatedSprite.spriteAnimation.y =
      SLOT_HEIGHT * Math.floor(id / 5) + SLOT_HEIGHT / 2;
    const container = this.winSlotsContainer[id % 5];
    animatedSprite.addOnStart(() => {
      container.addChild(animatedSprite.spriteAnimation);
    });
    animatedSprite.addOnSkip(() => {
      container.removeChild(animatedSprite.spriteAnimation);
    });
    animatedSprite.addOnComplete(() => {
      container.removeChild(animatedSprite.spriteAnimation);
    });
    return animatedSprite;
  }

  private createSlotScaleAnimation(
    sprite: PIXI.Sprite,
    isTurboSpin: boolean | undefined,
  ): AnimationGroup {
    const animation: AnimationGroup = new AnimationGroup({});
    const { x, y } = sprite.scale;
    const animationChainX = new AnimationChain();
    const animationDuration = isTurboSpin
      ? WIN_SLOT_ANIMATION_DURATION / 4
      : WIN_SLOT_ANIMATION_DURATION / 2;
    animationChainX.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.X,
        propertyBeginValue: x,
        target: x * WIN_SLOT_ANIMATION_SCALE,
        duration: animationDuration,
      }),
    );
    animationChainX.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.X,
        propertyBeginValue: x * WIN_SLOT_ANIMATION_SCALE,
        target: x,
        duration: animationDuration,
      }),
    );
    const animationChainY = new AnimationChain();
    animationChainY.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.Y,
        propertyBeginValue: y,
        target: y * WIN_SLOT_ANIMATION_SCALE,
        duration: animationDuration,
      }),
    );
    animationChainY.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.Y,
        propertyBeginValue: y * WIN_SLOT_ANIMATION_SCALE,
        target: y,
        duration: animationDuration,
      }),
    );
    animation.addAnimation(animationChainX);
    animation.addAnimation(animationChainY);
    return animation;
  }

  public hideAllSlots(): void {
    for (let i = 0; i < this.slotsContainer.length; i++) {
      for (let j = 0; j < this.slotsContainer[i].length; j++) {
        this.slotsContainer[i][j].visible = false;
        this.slotsContainer[i][j].scale.set(1, 1);
      }
    }
    for (let i = 0; i < this.winSlotsContainer.length; i++) {
      for (let j = 0; j < this.winSlotsContainer[i].children.length; j++) {
        this.winSlotsContainer[i].children[j].visible = false;
      }
    }
  }

  private showWin(
    nextResult: ISettledBet,
    isTurboSpin: boolean | undefined,
  ): void {
    const { paylines } = nextResult;
    const { spinResult } = nextResult.bet.result;
    this.animation = new AnimationChain();
    const set = new Set<number>();
    paylines.forEach((payline) => {
      payline.winPositions.forEach((position) => {
        set.add(position);
      });
    });
    const allSlotsHighlight = this.highlightSlots(
      Array.from(set),
      spinResult,
      isTurboSpin,
    );
    this.animation.addOnStart(() => {
      // eventManager.emit(EventTypes.SHOW_TINT, true);
    });
    allSlotsHighlight.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
      this.hideAllSlots();
    });
    if (SHOW_ALL_LINES_ON_WIN)
      this.animation.appendAnimation(allSlotsHighlight);
    const animationChain = this.createHighlightChainAnimation(
      paylines,
      spinResult,
      isTurboSpin,
      false,
    );
    this.loopAnimation = this.createHighlightChainAnimation(
      paylines,
      spinResult,
      isTurboSpin,
      true,
    );
    this.loopAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
      this.hideAllSlots();
    });
    if (paylines.length > 1) this.animation.appendAnimation(animationChain);
    animationChain.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
    });
    this.animation.addOnComplete(() => this.loopAnimation?.start());
    this.animation?.start();
  }

  public createHighlightChainAnimation(
    paylines: IWinLine[],
    spinResult: Icon[],
    isTurboSpin: boolean | undefined,
    isLoop: boolean,
  ): Animation {
    const animationChain = new AnimationChain({ isLoop });
    paylines.forEach((payline) => {
      const chain = this.highlightSlots(
        payline.winPositions,
        spinResult,
        isTurboSpin,
      );
      animationChain!.appendAnimation(chain);
    });
    return animationChain;
  }
}

export default WinSlotsContainer;
