import * as PIXI from 'pixi.js';

import { MessageWinBannerProps } from '../../global';
import { ResourceTypes } from '../../resources.d';
import { isMobile } from 'mobile-device-detect';
import { updateTextScale } from '../../utils/utils';
import { BaseMessageBanner } from './baseMessageBanner';
import {
  MESSAGE_WIN_BANNER_BONUS_STREAK_MARGIN,
  MESSAGE_WIN_BANNER_BONUS_STREAK_Y,
  MESSAGE_WIN_BANNER_TITLE_Y,
  MESSAGE_WIN_BANNER_WIN_AMOUNT_Y,
} from './config';
import {
  bonusStreakTextStyle,
  totalWinAmountTextStyles,
  totalWinTitleStyles,
} from './textStyles';

export class MessageWinBanner extends BaseMessageBanner {
  protected props: MessageWinBannerProps;

  private title?: PIXI.Text;

  private totalWin?: PIXI.Text;

  private bonusStreak?: PIXI.Container;

  constructor(props: MessageWinBannerProps) {
    super(props);
    this.props = props;
  }

  public init(): MessageWinBanner {
    super.init();

    this.title = this.initTitle(this.props.title);
    this.totalWin = this.initWinTotal(this.props.totalWin);
    this.bonusStreak = this.initBonusStreak(this.props.bonusStreak);
    this.addChild(this.title, this.totalWin, this.bonusStreak);

    return this;
  }

  protected initTitle(titleText: string): PIXI.Text {
    const winTitle = new PIXI.Text(titleText, totalWinTitleStyles);
    winTitle.resolution = 1;
    winTitle.anchor.set(0.5, 0);
    winTitle.y = MESSAGE_WIN_BANNER_TITLE_Y;
    updateTextScale(winTitle, isMobile ? 670 : 800, isMobile ? 150 : 250);
    return winTitle;
  }

  protected initWinTotal(totalWin: string): PIXI.Text {
    const winTotal = new PIXI.Text(totalWin, totalWinAmountTextStyles);
    winTotal.resolution = 1;
    winTotal.anchor.set(0.5, 0);
    winTotal.y = MESSAGE_WIN_BANNER_WIN_AMOUNT_Y;
    return winTotal;
  }

  protected initBonusStreak(num: string): PIXI.Container {
    const container = new PIXI.Container();

    const image = new PIXI.Sprite(
      PIXI.Texture.from(ResourceTypes.messageBannerBonus),
    );
    image.anchor.set(0, 0.5);

    const streak = new PIXI.Text(`x ${num}`, bonusStreakTextStyle);
    streak.resolution = 1;
    streak.anchor.set(0, 0.5);
    streak.x = image.width + MESSAGE_WIN_BANNER_BONUS_STREAK_MARGIN;

    container.addChild(image, streak);
    container.pivot.x = container.width / 2;

    container.y = MESSAGE_WIN_BANNER_BONUS_STREAK_Y;

    return container;
  }
}
