import { DropShadowFilter, DropShadowFilterOptions } from 'pixi-filters';

import { SlotId } from '../config';
import {
  bonusIds,
  GameMode,
  HighChanceModeBonus,
  ReelSet,
  reelSets,
} from '../global.d';
import { IConfig } from '../gql/d';
import SentryRaven from '../sentryRaven';
import SpineAnimation from '../slotMachine/animations/spine';
import { Icon } from '../slotMachine/d';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value,
    };
  }, {});
  return param as T;
};

export const goToLobby = (): void => {
  const { home } = parseQuery<{ home: string }>();
  if (home) {
    window.parent.postMessage(`goTo:${home}`, '*');
  } else {
    window.parent.postMessage('goTo:', '*');
  }
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const getUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    if (config[name] === undefined) {
      Object.assign(config, { [name]: value });
      localStorage.setItem('config', JSON.stringify(config));
    }
    return config[name] as boolean;
  }
  localStorage.setItem('config', JSON.stringify({ [name]: value }));
  return value;
};

export const setUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    Object.assign(config, { [name]: value });
    localStorage.setItem('config', JSON.stringify(config));
  }
  return value;
};

export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return (
    ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || 10)) / 100
  );
};

const createFasBetArrMin = (amount: number, defaultValue: number) => {
  const arr = new Array(amount).fill(defaultValue);
  return arr.map((item, index) => item * (index + 1));
};

const createFastBetArrMax = (minBet: number, maxBet: number) => {
  const arr = [0, 10, 18, 27, 38, 50, 67, 78, 90, 100];
  const cef = maxBet / minBet / 100;
  return arr.map((item) => {
    const i = Math.round(cef * item);
    return minBet * i || minBet;
  });
};

// const createFastBetArrMax = (minBet: number, maxBet: number) => {
//   const cef = maxBet / minBet / 10;
//   const arr = new Array(10).fill(minBet);
//   return arr.map((item, index) => {
//     const v = index ? index + 1 : 1;
//     const i = Math.floor(cef * v);
//     return item * i;
//   });
// };

export const createFastBet = (minBet = 25, maxBet?: number): number[] => {
  if (!maxBet) {
    return [25, 50, 75, 100];
  }

  if (!(maxBet % minBet)) {
    const amount = maxBet / minBet;
    if (amount <= 10) {
      return createFasBetArrMin(amount, minBet);
    }
    return createFastBetArrMax(minBet, maxBet);
  }

  return [];
};

export const getIconById = (icons: Array<Icon>, id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  SentryRaven.captureException<Error>(error);
  throw error;
};
export const getSpinResult3x3 = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Array<Icon>;
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      const prevRandom =
        random - 1 < 0 ? reelSet.layout[index].length - 1 : random - 1;
      return getIconById(icons, reelSet.layout[index][prevRandom]);
    }),
    ...reelPositions.map((random, index) => {
      return getIconById(icons, reelSet.layout[index][random]);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom =
        random + 1 >= reelSet.layout[index].length ? 0 : random + 1;
      return getIconById(icons, reelSet.layout[index][nextRandom]);
    }),
  ];
  return spinResult;
};
export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  localStorage.setItem('positions', btoa(positions));
  window.dispatchEvent(new CustomEvent('setpos'));
};

export const destroySpine = (spine: SpineAnimation): void => {
  setImmediate(() => {
    if (spine.getSpine() && spine.getSpine().skeleton) {
      spine.getSpine().destroy({ children: true });
    }
  });
};
export const isBaseGameMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR;
};
export const isFreeSpinsMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS;
};
export const isHighChanceMode = (mode: GameMode): boolean => {
  switch (mode) {
    case GameMode.HCM_MODE_A4:
    case GameMode.HCM_MODE_A5:
    case GameMode.HCM_MODE_A6:
    case GameMode.HCM_MODE_B2:
    case GameMode.HCM_MODE_B3:
    case GameMode.HCM_MODE_B4:
    case GameMode.HCM_MODE_B5:
    case GameMode.HCM_MODE_C1:
    case GameMode.HCM_MODE_C2:
    case GameMode.HCM_MODE_C3:
    case GameMode.HCM_MODE_C4:
    case GameMode.HCM_MODE_C5:
    case GameMode.HCM_MODE_D1:
    case GameMode.HCM_MODE_D2:
    case GameMode.HCM_MODE_D3:
    case GameMode.HCM_MODE_D4:
    case GameMode.HCM_MODE_D5:
    case GameMode.HCM_MODE_E1:
    case GameMode.HCM_MODE_E2:
    case GameMode.HCM_MODE_E3:
    case GameMode.HCM_MODE_E4:
    case GameMode.HCM_MODE_E5:
    case GameMode.HCM_MODE_F1:
    case GameMode.HCM_MODE_F2:
    case GameMode.HCM_MODE_F3:
    case GameMode.HCM_MODE_G1:
    case GameMode.HCM_MODE_G2:
    case GameMode.HCM_MODE_G3:
      return true;
    default:
      return false;
  }
};

export const hasCZ1Reel = (hcmBonus: HighChanceModeBonus): boolean => {
  return hcmBonus.bonusList.some(
    (bonus) => bonus.bonus.reelSetId === reelSets[GameMode.HCM_MODE_G1],
  );
};

export const isScatter = (slotId: SlotId): boolean => {
  return false;
};

export const getGameModeByReelSetId = (reelSetId: string): GameMode => {
  for (const [gameMode, id] of Object.entries(reelSets)) {
    if (id === reelSetId) {
      return Number(gameMode) as GameMode;
    }
  }

  return GameMode.REGULAR;
};

export const getGameModeByBonusId = (bonusId: string): GameMode => {
  for (const [gameMode, id] of Object.entries(bonusIds)) {
    if (id === bonusId) {
      return Number(gameMode) as GameMode;
    }
  }

  return GameMode.REGULAR;
};

export const calcPercentage = (
  initialValue: number,
  percent: number,
): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  bonusCurrentRound,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isOpenedMessageBanner,
  isInTransition,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  bonusCurrentRound: number;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isOpenedMessageBanner: boolean;
  isInTransition: boolean;
}): boolean => {
  if (isInTransition) {
    return false;
  }

  if (
    (gameMode === GameMode.REGULAR || isHighChanceMode(gameMode)) &&
    isFreeSpinsWin
  ) {
    return false;
  }

  if (isFreeSpinsMode(gameMode) && (bonusCurrentRound === 0 || !isSlotBusy)) {
    if (isOpenedMessageBanner) return true;
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  return true;
};

export const dropShadowFilter = (options: Partial<DropShadowFilterOptions>) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore:next-line
  return new DropShadowFilter(options) as PIXI.Filter;
};
