import * as PIXI from 'pixi.js';

import { EventTypes, GameMode, PatLampAnimType } from '../../global.d';
import { isFreeSpinsMode } from '../../utils/helper';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { eventManager, SLOTS_CONTAINER_WIDTH } from '../config';

export class ForegroundLamp extends ViewContainer {
  private lamp_front: SpineAnimation;

  constructor() {
    super();

    this.lamp_front = new SpineAnimation(
      {},
      PIXI.Loader.shared.resources.patlamp_front.spineData,
    );

    this.lamp_front.setAnimation(PatLampAnimType.IDLE, false);
    this.lamp_front.spine.y = 36;

    this.addChild(this.lamp_front.spine);
    this.position.set(SLOTS_CONTAINER_WIDTH / 2, 16);

    eventManager.on(
      EventTypes.PATLAMP_ANIMATION,
      this.animationPatLamp.bind(this),
    );

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(
      EventTypes.MANUAL_CHANGE_BACKGROUND,
      this.onModeChange.bind(this),
    );
  }

  private animationPatLamp(lampPattern: PatLampAnimType) {
    this.lamp_front.setAnimation(lampPattern, false);
    this.lamp_front.addAnimation(PatLampAnimType.IDLE, true, 0);
  }

  private onModeChange(settings: { mode: GameMode }) {
    this.visible = !isFreeSpinsMode(settings.mode);
  }
}
