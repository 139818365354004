import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IUserBalance } from '../../global';
import { getBetsSetting } from '../../gql/fromFragment';
import { getUserGql } from '../../gql/query';
import { formatNumber } from '../../utils';
import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);

  const {
    user: { balance },
  } = userData!;
  const betSettings = getBetsSetting();
  const { currency } = balance;
  const minBet = formatNumber(currency, betSettings.minBetCount);
  const maxBet = formatNumber(currency, betSettings.maxBetCount);
  const version = window.__ENV__?.APP_VERSION ?? 'develop';

  const i18n = useTranslation();
  const { t } = i18n;

  const gameRules = [
    {
      key: 'infoGameRules1_1',
      text: i18n.t('infoGameRules1_1'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoGameRules1_2',
      text: i18n.t('infoGameRules1_2'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoGameRules1_3',
      text: i18n.t('infoGameRules1_3'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoGameRules1_4',
      text: i18n.t('infoGameRules1_4'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoGameRules1_5',
      text: i18n.t('infoGameRules1_5'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoGameRules1_6',
      text: i18n.t('infoGameRules1_6'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoGameRules1_7',
      text: i18n.t('infoGameRules1_7'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoGameRules1_8',
      text: i18n.t('infoGameRules1_8'),
      buyFeatureOnly: false,
    },
  ];

  return (
    <div className={styles.gameRules}>
      <h1 className={styles.title}>{t('infoGameRulesTitle')}</h1>
      <section>
        <div className={`${styles.p} ${styles.left}`}>
          {gameRules.map((v) => (
            <div key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} />
          ))}
        </div>
        <div className={`${styles.p} ${styles.left}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: t('infoGameRules1', {
                minBet,
              }),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('infoGameRules2', {
                maxBet,
              }),
            }}
          />
        </div>
        <div className={`${styles.p} ${styles.left}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: t('infoGameRules3', {
                rtp: '96.57%',
              }),
            }}
          />
        </div>
        <div
          className={`${styles.p} ${styles.left}`}
          dangerouslySetInnerHTML={{
            __html: t('infoGameRulesVersion', {
              version,
            }),
          }}
        />
      </section>
    </div>
  );
};

export default GameRules;
