import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import AudioHowl from '@phoenix7dev/play-music';
import { Loader, ProgressBar } from '@phoenix7dev/shared-components';
import React, { useEffect, useState } from 'react';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import { remoteStorage } from '../../utils/remoteStorage';

import { getHighChanceModeBackground } from '../../anticipation';
import {
  audioSprite,
  audioSpriteVolume,
  LOADER_MAPPED_SYMBOLS,
  LOADER_TEXTURES,
  SPINE_LOADER_TEXTURES,
} from '../../config';
import {
  BonusStatus,
  EventTypes,
  freeRoundBonusId,
  GameMode,
  HighChanceModeBonus,
  IAuthInput,
  ISettledBet,
  IUserBalance,
  reelSets,
  ReplayFreeSpinBets,
  UserBonus,
} from '../../global.d';
import {
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setCurrentHCM,
  setIsAuthorized,
  setIsMobile,
  setIsSoundOn,
  setIsEnabledSpaceSpin,
  setIsLeftHandMode,
  setIsMiniPayTable,
  setIsTurboSpin,
  setReservedHCM,
  setSkipIntroScreen,
  setSlotConfig,
  setUserLastBetResult,
  setProgress,
  setIsSuspended,
  setReplayBet,
  setReplayFreeSpinReelSetId,
  setReplayFreeSpinBets,
  setFreeRoundBonus,
  setFreeSpinsTotalWin,
  setFreeRoundsTotalWin,
} from '../../gql/cache';
import client from '../../gql/client';
import { IConfig, ISlotHistoryData } from '../../gql/d';
import { authGql } from '../../gql/mutation';
import {
  betsByInitialRoundId,
  configGql,
  getProgressGql,
  getSlotGql,
  getUserBonuses,
  getUserGql,
  slotBetGql,
  slotHistoryGql,
  userBonusBetsGql,
} from '../../gql/query';
import { isMobile } from 'mobile-device-detect';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import {
  getGameModeByBonusId,
  getGameModeByReelSetId,
  isFreeSpinsMode,
  loadErrorHandler,
  loadPixiAssets,
  parseQuery,
  queryParams,
} from '../../utils';
import Resources from '../../utils/resources';
import styles from './loadScreen.module.scss';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { ISlotData } from '../../slotMachine/d';

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent, setShowContent] = useState(false);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });

  useEffect(() => {
    const getReplayBetFreeSpins = async () => {
      const replayBetFreeSpins = await client.query<
        {
          betsByInitialRoundId: ReplayFreeSpinBets[];
        },
        { initialRoundId: string }
      >({
        query: betsByInitialRoundId,
        variables: {
          initialRoundId: setReplayBet(),
        },
      });

      if (replayBetFreeSpins.data.betsByInitialRoundId.length) {
        const replayFreeSpins =
          replayBetFreeSpins.data.betsByInitialRoundId.map((e) => e.id);
        setReplayFreeSpinBets(replayFreeSpins);
        setReplayFreeSpinReelSetId(
          replayBetFreeSpins.data.betsByInitialRoundId[0].reelSetId,
        );
      }
    };

    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setCurrency(userBalance.data.user.balance.currency);
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0]) {
        setUserLastBetResult(betsData.data.bets.edges[0].node);
      }
    };
    // const getPurchasableBonuses = async () => {
    //   const bonusData = await client.query<{ bonuses: IBonus[] }>({
    //     query: getBonuses,
    //     variables: { input: { purchasable: true } },
    //     fetchPolicy: 'network-only',
    //   });
    //   setBonuses(bonusData.data.bonuses);
    // };
    const checkBonusGame = async () => {
      // TODO(FRB) Be sure to turn it ON when pushing to master
      const activeUserBonusData = await client.query<{
        userBonuses: UserBonus[];
      }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });

      // TODO(FRB) Be sure to turn it OFF when pushing to master
      // ->its locally for testing.
      /* const originActiveUserBonusData = await client.query<{
        userBonuses: UserBonus[];
      }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });
      const activeUserBonusData: ApolloQueryResult<{
        userBonuses: UserBonus[];
      }> = JSON.parse(JSON.stringify(originActiveUserBonusData));

      activeUserBonusData.data.userBonuses.push({
        ...(setCurrentBonus() as UserBonus),
        id: freeRoundBonusId,
        bonusId: freeRoundBonusId,
        coinAmount: 1,
        coinValue: 100,
        rounds: 2,
        totalWinAmount: 0,
        isActive: true,
        currentRound: 0,
      });
      // <-its locally for testing.*/

      const slotData = await client.query<{
        slot: ISlotData;
      }>({
        query: getSlotGql,
        variables: {
          input: { id: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });
      const { slot } = slotData.data;

      const inActiveUserBonusData = await client.query<{
        userBonuses: UserBonus[];
      }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.INACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });

      const allBonus = [
        ...activeUserBonusData.data.userBonuses,
        ...inActiveUserBonusData.data.userBonuses,
      ];

      const freeRoundBonus = activeUserBonusData.data.userBonuses.find(
        (e) => e.bonusId === freeRoundBonusId,
      );

      if (freeRoundBonus) {
        setFreeRoundBonus({
          id: freeRoundBonus.id,
          bonusId: freeRoundBonus.bonusId,
          coinAmount: freeRoundBonus.coinAmount,
          coinValue: freeRoundBonus.coinValue,
          status: freeRoundBonus.status,
          roundsPlayed: freeRoundBonus.roundsPlayed,
          rounds: freeRoundBonus.rounds,
          totalWinAmount: freeRoundBonus.totalWinAmount,
          isActive: true,
          currentRound: 0,
        });
        setFreeRoundsTotalWin(freeRoundBonus.totalWinAmount);
      }

      const hcms = allBonus
        .filter((userBonus) => userBonus.bonus.type === 'GAME_MODE')
        .filter((userBonus) =>
          slot.clientSettings.coinAmounts.default.find(
            (coinAmount) => coinAmount === userBonus.coinAmount,
          ),
        )
        .reduce<Record<number, HighChanceModeBonus>>((hcms, bonus) => {
          const hcm = hcms[bonus.coinAmount] ?? {
            isActive: true,
            activateChecked: true,
            background: 'dusk',
            currentRound: 5,
            totalRounds: 5,
            bonusList: [],
          };

          hcm.bonusList.push(bonus);
          hcm.currentRound = hcm.currentRound - 1;

          hcms[bonus.coinAmount] = hcm;
          return hcms;
        }, {});

      for (const coin in hcms) {
        hcms[coin].bonusList = hcms[coin].bonusList.sort((a, b) => {
          if (a.id < b.id) return -1;
          else if (a.id > b.id) return 1;
          else return 0;
        });
        const lastUserBonus =
          hcms[coin].bonusList[hcms[coin].bonusList.length - 1];
        hcms[coin].background = getHighChanceModeBackground(
          lastUserBonus.id,
          getGameModeByBonusId(lastUserBonus.bonus.id),
        );
      }

      setReservedHCM(hcms);
      const lastbet = setUserLastBetResult();
      if (lastbet.coinAmount in hcms) {
        setCurrentHCM(hcms[lastbet.coinAmount]);
      }

      const freeSpinsBonus = activeUserBonusData.data.userBonuses.find(
        (ub) => ub.bonus.type === 'FREE_SPIN',
      );
      if (freeSpinsBonus) {
        setBrokenGame(true);
        setCurrentBonus({
          ...freeSpinsBonus,
          isActive: true,
          currentRound: 0,
          totalRounds: 0,
        });
        const totalWinAmount = freeSpinsBonus?.totalWinAmount
          ? freeSpinsBonus?.totalWinAmount / freeSpinsBonus.coinValue
          : 0;

        setCurrentFreeSpinsTotalWin(totalWinAmount);
        const userBonusBetsData = await client.query<{
          bets: ISlotHistoryData;
        }>({
          query: userBonusBetsGql,

          variables: {
            input: {
              filter: {
                userBonusId: setCurrentBonus().id,
              },
            },
          },
          fetchPolicy: 'network-only',
        });

        setCurrentBonus({
          ...setCurrentBonus(),
          currentRound: userBonusBetsData.data.bets.pageInfo.count,
          rounds:
            setCurrentBonus().rounds +
            userBonusBetsData.data.bets.pageInfo.count,
        });

        if (userBonusBetsData.data.bets.pageInfo.count === 0) {
          const lastBetRes = { ...setUserLastBetResult() };
          lastBetRes.reelSetId = reelSets[GameMode.FREE_SPINS];
          lastBetRes.result = {
            ...lastBetRes.result,
            reelPositions: [0, 0, 0],
          };
        }
      } else {
        if (
          isFreeSpinsMode(
            getGameModeByReelSetId(setUserLastBetResult().reelSetId),
          )
        ) {
          // for current mode is normal or high chance and prev spin is freespin
          const lastBet = await client.query<ISettledBet>({
            query: slotBetGql,
            variables: { input: { id: setUserLastBetResult().id } },
            fetchPolicy: 'network-only',
          });
          const bet = await client.query<ISettledBet>({
            query: slotBetGql,
            variables: { input: { id: lastBet.data.bet.userBonus.betId } },
            fetchPolicy: 'network-only',
          });

          const lastBetRes = { ...setUserLastBetResult() };
          lastBetRes.reelSetId = bet.data.bet.reelSetId;
          lastBetRes.result = {
            ...lastBetRes.result,
            reelPositions: bet.data.bet.result.reelPositions,
          };
          setUserLastBetResult(lastBetRes);
        }
      }
    };
    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(20, ELoaderStages.AUTH, async (stage) => {
        setIsMobile(isMobile);
        const { token, clientId } =
          parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({
          variables: { input: { token, clientId } },
        });

        await remoteStorage.init(data?.auth.sessionId as string);
        window.remoteStorage = remoteStorage;
        rebuildStorageCache('config', {
          isLeftHandMode: setIsLeftHandMode,
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
          isMiniPayTable: setIsMiniPayTable,
          isEnabledSpaceSpin: setIsEnabledSpaceSpin,
          isSkipIntroScreen: setSkipIntroScreen,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.BONUS_GAME, async (stage) => {
        if (queryParams.has('replayBetId')) {
          await getReplayBetFreeSpins();
        }

        await getUserBalance();
        // await getPurchasableBonuses();
        await getLastBet();
        await checkBonusGame();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets(
          [
            ...LOADER_MAPPED_SYMBOLS,
            ...LOADER_TEXTURES,
            ...SPINE_LOADER_TEXTURES(setIsMobile()),
          ],
          setIsMobile(),
        );
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async (stage) => {
        AudioHowl.initialize(
          audioSprite,
          audioSpriteVolume,
          true,
          isSoundOn,
          setIsSuspended,
        ).then(() => {
          eventManager.emit(
            EventTypes.SOUND_INITIALIZED,
            AudioHowl.isRestricted &&
              !(!AudioHowl.restrictionChangedOnIntroScreen && !setIsSoundOn()),
          );
        });
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: setSkipIntroScreen(),
          });
          setShowContent(false);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles.loadScreenWrapper}>
      <div className={styles.logo}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles.companyLogo}
        />
      </div>
      <ProgressBar
        className={styles.progressBar}
        type="line"
        trailWidth={2}
        trailColor="#000000"
        strokeWidth={2}
        strokeColor="#fcf7cd"
        percent={progress?.status || 0}
      />
    </div>
  );
};

export default LoadScreen;
