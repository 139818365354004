import { GameMode, reelSets } from '../global.d';
import {
  setGameMode,
  setNextResult,
  setUserLastBetResult,
  setUserLastNotFreeSpinsBetResult,
} from '../gql/cache';
import { isFreeSpinsMode } from './helper';
import { fallBackReelPosition } from './math';

export const fallBackReelPositionWrapper = () => {
  const lastBet = setUserLastBetResult();
  const gameMode = setGameMode();
  // If the previous spin was FS, return to the current spin state.
  if (
    lastBet.reelSet.id === reelSets[GameMode.FREE_SPINS] &&
    !isFreeSpinsMode(gameMode)
  ) {
    const bet = setUserLastNotFreeSpinsBetResult();
    setUserLastBetResult({
      ...bet,
    });
    setNextResult({
      ...setNextResult()!,
      bet: {
        ...setNextResult()!.bet,
        result: {
          ...setNextResult()!.bet.result,
          reelPositions: bet.result.reelPositions,
          spinResult: bet.result.spinResult!,
        },
      },
    });
  }
  fallBackReelPosition();
};
