import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setIsMobile } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinsMode, isHighChanceMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { eventManager } from '../config';
import { FireWorks } from './fireworks';

export type BgSkin = 'daytime' | 'dusk' | 'night';

class Background extends ViewContainer {
  private bgTextures: Record<BgSkin, PIXI.Texture> = {
    daytime: PIXI.Texture.from(ResourceTypes.backgroundBase),
    dusk: PIXI.Texture.from(ResourceTypes.highChanceBGBase),
    night: PIXI.Texture.from(ResourceTypes.highChance2BGBase),
  };

  private BushTextures: Record<BgSkin, PIXI.Texture> = {
    daytime: PIXI.Texture.from(ResourceTypes.backgroundThicket),
    dusk: PIXI.Texture.from(ResourceTypes.highChanceBGThicket),
    night: PIXI.Texture.from(ResourceTypes.highChance2BGThicket),
  };

  private wallTextures: Record<BgSkin, PIXI.Texture> = {
    daytime: PIXI.Texture.from(ResourceTypes.backgroundStoneWall),
    dusk: PIXI.Texture.from(ResourceTypes.highChanceBGStoneWall),
    night: PIXI.Texture.from(ResourceTypes.highChance2BGStoneWall),
  };

  private cloud: SpineAnimation;

  private palmL: SpineAnimation;

  private palmR: SpineAnimation;

  private bgSprite = new PIXI.Sprite();

  private wall = new PIXI.Sprite();

  private thicket = new PIXI.Sprite();

  private fireworks: FireWorks;

  private currentSkin?: BgSkin;

  constructor(skin: BgSkin = 'daytime') {
    super();

    this.bgSprite.anchor.set(0.5);

    this.wall.anchor.set(0.5, 1);

    this.cloud = this.initCloud();
    this.palmL = this.initPalmL();
    this.palmR = this.initPalmR();

    this.fireworks = new FireWorks();

    this.addChild(
      this.bgSprite,
      this.cloud.getSpine(),
      this.fireworks,
      this.thicket,
      this.palmR.getSpine(),
      this.palmL.getSpine(),
      this.wall,
    );

    this.setSkin(skin);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.onChangeMode.bind(this),
    );
    eventManager.addListener(
      EventTypes.MANUAL_CHANGE_BACKGROUND,
      this.onChangeMode.bind(this),
    );
  }

  public setSkin(skinName: BgSkin) {
    if (this.currentSkin === skinName) return;
    this.currentSkin = skinName;

    this.bgSprite.texture = this.bgTextures[skinName];
    this.thicket.texture = this.BushTextures[skinName];
    this.thicket.pivot.set(
      this.thicket.texture.width / 2,
      this.thicket.texture.height + 152,
    );
    this.wall.texture = this.wallTextures[skinName];
    this.cloud.setAnimation(`cloud_${skinName}`, true);
    this.palmL.setSkin(skinName);
    this.palmR.setSkin(skinName);
  }

  private initCloud(): SpineAnimation {
    const cloud = new SpineAnimation(
      {},
      PIXI.Loader.shared.resources.cloud.spineData,
    );
    cloud.setAnimation('cloud_daytime', false);

    return cloud;
  }

  private initPalmL(): SpineAnimation {
    const palm = new SpineAnimation(
      {},
      PIXI.Loader.shared.resources.palmtree.spineData,
    );
    palm.setAnimation('palmtree', true);
    palm.getSpine().scale.x = -1;
    return palm;
  }

  private initPalmR(): SpineAnimation {
    const parm = new SpineAnimation(
      {},
      PIXI.Loader.shared.resources.palmtree.spineData,
    );
    parm.setAnimation('palmtree', true);

    return parm;
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    const { mode, background } = settings;
    if (isFreeSpinsMode(mode)) {
      this.fireworks.start();
      this.setSkin('night');
    } else if (isHighChanceMode(mode)) {
      if (background === undefined) {
        return;
      }
      this.fireworks.stop();
      this.setSkin(background ?? 'dusk');
    } else {
      this.fireworks.stop();
      this.setSkin('daytime');
    }
  }

  private resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const tex = this.bgSprite.texture;
    const bgAspectRatio = tex.width / tex.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.bgSprite.scale.set(height / tex.height);
    } else {
      this.bgSprite.scale.set(width / tex.width);
    }

    this.fireworks.pivot.set(tex.width / 2, tex.height / 2);

    const scale = this.bgSprite.scale.x;
    this.cloud.getSpine().scale.set(scale);
    this.palmL.getSpine().scale.set(-scale, scale);
    this.palmR.getSpine().scale.set(scale);
    this.wall.scale.set(scale);
    this.wall.y = height / 2;
    this.thicket.scale.set(scale);
    this.thicket.y = height / 2;
    this.fireworks.scale.set(scale);

    if (setIsMobile() && aspectRatio < 1) {
      this.palmL.getSpine().position.set(-width / 1.5, height * 0.4);
      this.palmR.getSpine().position.set(width / 1.5, height * 0.5);
    } else {
      this.palmL.getSpine().position.set(-width / 2, height / 2);
      this.palmR.getSpine().position.set(width / 2, height / 2);
    }
  }
}

export default Background;
