export const backgroundSelectionTable = [
  [100, 100], // Mode A
  [100, 100], // Mode B
  [100, 100], // Mode C
  [100, 100], // Mode D
  [75, 100], // Mode E
  [70, 100], // Mode F
  [65, 100], // Mode G
];

export const patlampTable = [
  [25, 80, 85, 90, 100],
  [25, 90, 95, 100, 100],
];

export const nullPatlampTable = [80, 100];

export const girlLampTable = [84, 100];
