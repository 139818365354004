import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '../../config';
import { IUserBalance } from '../../global';
import { setCoinValue, setSlotConfig } from '../../gql/cache';
import { getBetAmountGql, getUserGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { countCoins, formatNumber, showCurrency } from '../../utils';
import Resources from '../../utils/resources';
import { IPaytableData } from './d';
import styles from './info.module.scss';

const hasTranslate = (slug: ResourceTypes): boolean => {
  return false;
};
const calcMultiplier = (
  multiplier: number,
  betAmount: number,
  slug?: ResourceTypes,
): number => {
  // if (slug === ResourceTypes.scatter)
  //   return (
  //     countCoins({ totalAmount: betAmount, coinValue: setCoinValue() }) *
  //     multiplier
  //   );
  return (
    countCoins({
      totalAmount: betAmount / setSlotConfig().lineSet.coinAmountMultiplier,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const {
    user: { balance },
  } = userData!;

  const { betAmount } = dataBet!;

  const specialCombos = config.specialComboData;
  const baseData: IPaytableData[] = config.payTableData;
  const FeatureData: IPaytableData[] = config.payTableDataFeature;

  const { currency } = balance;

  return (
    <section>
      <h1 className={styles.title}>{t('infoPayTable_BaseTitle')}</h1>
      {/* dynamic used keys */}
      {/* t('infoAABFreeSpins') */}
      {/* t('infoAAAFreeSpins') */}

      <div className={styles['special-combo-list']}>
        {specialCombos.map(({ combo, multiplier, awardText }) => (
          <div className={styles['special-combo-item']} key={`${awardText}`}>
            <div className={styles.combo}>
              {combo.map((symbol, i) => (
                <img
                  draggable="false"
                  src={Resources.getSource(symbol)}
                  alt={symbol}
                  key={`combo-${i}`}
                />
              ))}
            </div>
            <p className={styles.p}>
              <span>
                {formatNumber(
                  currency,
                  calcMultiplier(multiplier, betAmount),
                  showCurrency(currency),
                )}
              </span>
              {' + ' + t(awardText)}
            </p>
          </div>
        ))}
      </div>

      <div className={styles['paytable-list']}>
        {baseData.map(({ combos, slug }) => (
          <div key={slug} className={styles['paytable-list__item']}>
            <div className={styles.img}>
              {/* t('infoPayTable_symbolEBell') 
              t('infoPayTable_symbolGCherry')
              t('infoPayTable_symbolBBar') 
              t('infoPayTable_symbolCLion') 
              t('infoPayTable_symbolASeven')
              t('infoPayTable_symbolFBlue') 
              t('infoPayTable_symbolDWatermelon')
              t('infoPayTable_scatter')
              t('infoPayTable_wild') */}
              {/* t('infoPayTable_freeSpinSymbolAGoldenCherry') */}
              <img
                draggable="false"
                alt={slug}
                src={Resources.getSource(slug)}
              />
              <div className={styles['paytable-list__title']}>
                <p
                  className={`${styles.p} ${styles['symbol-name']}`}
                  dangerouslySetInnerHTML={{
                    __html: t(`infoPayTable_${slug}`),
                  }}
                />
              </div>
            </div>
            <div className={styles.content}>
              {combos.map((i) => (
                <div key={i.pattern}>
                  <span className={styles.multiplier}>
                    {t(`${i.pattern}`)}{' '}
                  </span>
                  {formatNumber(
                    currency,
                    calcMultiplier(i.multiplier, betAmount, slug),
                    showCurrency(currency),
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.paytableFreeSpinInfo__item}>
        <p
          className={styles.p}
          dangerouslySetInnerHTML={{
            __html: t('infoPayTableCherry'),
          }}
        />
      </div>

      <h1 className={styles.title}>{t('infoPayTable_FeatureTitle')}</h1>
      <div className={styles['paytable-list']}>
        {FeatureData.map(({ combos, slug }) => (
          <div key={slug} className={styles['paytable-list__item']}>
            <div className={styles.img}>
              <img
                draggable="false"
                alt={slug}
                src={Resources.getSource(slug)}
              />
              <div className={styles['paytable-list__title']}>
                <p
                  className={`${styles.p} ${styles['symbol-name']}`}
                  dangerouslySetInnerHTML={{
                    __html: t(`infoPayTable_${slug}`),
                  }}
                />
              </div>
            </div>
            <div className={styles.content}>
              {combos.map((i) => (
                <div key={i.pattern}>
                  <span className={styles.multiplier}>{i.pattern} </span>
                  {formatNumber(
                    currency,
                    calcMultiplier(i.multiplier, betAmount, slug),
                    showCurrency(currency),
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PaytableComponent;
