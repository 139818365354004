import { GameMode } from '../global.d';

export const parseUUID = (uuid: string): Uint8Array => {
  let v: number;
  const arr = new Uint8Array(16);

  // Parse ########-....-....-....-............
  arr[0] = (v = parseInt(uuid.slice(0, 8), 16)) >>> 24;
  arr[1] = (v >>> 16) & 0xff;
  arr[2] = (v >>> 8) & 0xff;
  arr[3] = v & 0xff;

  // Parse ........-####-....-....-............
  arr[4] = (v = parseInt(uuid.slice(9, 13), 16)) >>> 8;
  arr[5] = v & 0xff;

  // Parse ........-....-####-....-............
  arr[6] = (v = parseInt(uuid.slice(14, 18), 16)) >>> 8;
  arr[7] = v & 0xff;

  // Parse ........-....-....-####-............
  arr[8] = (v = parseInt(uuid.slice(19, 23), 16)) >>> 8;
  arr[9] = v & 0xff;

  // Parse ........-....-....-....-############
  // (Use "/" to avoid 32-bit truncation when bit-shifting high-order bytes)
  arr[10] = ((v = parseInt(uuid.slice(24, 36), 16)) / 0x10000000000) & 0xff;
  arr[11] = (v / 0x100000000) & 0xff;
  arr[12] = (v >>> 24) & 0xff;
  arr[13] = (v >>> 16) & 0xff;
  arr[14] = (v >>> 8) & 0xff;
  arr[15] = v & 0xff;

  // return new DataView(arr.buffer, 0).getUint32(0, true);
  return arr;
};

export const getRandomArrayFromUUID = (
  uuid: string,
  maxArray: number[],
): number[] => {
  const uuidArray = parseUUID(uuid);

  if (maxArray.length > uuidArray.length) {
    throw new Error('maxArray length is too long');
  }
  const result = maxArray.map((max, idx) =>
    Math.trunc((uuidArray[idx] / 256) * max),
  );

  return result;
};

type RandomIndexType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;

export const getRandomFromUUID = (
  uuid: string,
  max: number,
  idx: RandomIndexType = 0,
): number => {
  const uuidArray = parseUUID(uuid);
  return Math.trunc((uuidArray[idx] / 256) * max);
};

export const getResultFromTable = (table: number[], rand: number): number => {
  for (let i = 0; i < table.length; i++) {
    if (rand < table[i]) return i;
  }
  return 0;
};

export const getModeId = (mode: GameMode): number => {
  switch (mode) {
    case GameMode.HCM_MODE_A4:
    case GameMode.HCM_MODE_A5:
    case GameMode.HCM_MODE_A6:
      return 0;
    case GameMode.HCM_MODE_B2:
    case GameMode.HCM_MODE_B3:
    case GameMode.HCM_MODE_B4:
    case GameMode.HCM_MODE_B5:
      return 1;
    case GameMode.HCM_MODE_C1:
    case GameMode.HCM_MODE_C2:
    case GameMode.HCM_MODE_C3:
    case GameMode.HCM_MODE_C4:
    case GameMode.HCM_MODE_C5:
      return 2;
    case GameMode.HCM_MODE_D1:
    case GameMode.HCM_MODE_D2:
    case GameMode.HCM_MODE_D3:
    case GameMode.HCM_MODE_D4:
    case GameMode.HCM_MODE_D5:
      return 3;
    case GameMode.HCM_MODE_E1:
    case GameMode.HCM_MODE_E2:
    case GameMode.HCM_MODE_E3:
    case GameMode.HCM_MODE_E4:
    case GameMode.HCM_MODE_E5:
      return 4;
    case GameMode.HCM_MODE_F1:
    case GameMode.HCM_MODE_F2:
    case GameMode.HCM_MODE_F3:
      return 5;
    case GameMode.HCM_MODE_G1:
    case GameMode.HCM_MODE_G2:
    case GameMode.HCM_MODE_G3:
      return 6;
    default:
      return 0;
  }
};
