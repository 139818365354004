import {
  EventTypes,
  GameMode,
  HighChanceModeBonus,
  ISettledBet,
} from '../../global.d';
import {
  setCurrentBonus,
  setCurrentHCM,
  setFreeRoundBonus,
  setFreeRoundsTotalWin,
  setGameMode,
  setIsFreeRoundBonus,
  setNextResult,
} from '../../gql/cache';
import { isBaseGameMode, isFreeSpinsMode, isHighChanceMode } from '../../utils';
import { SlotMachineState, eventManager } from '../config';
import { FreeRoundBonusTransitionTypes } from './d';
import { FreeRoundsPopup } from './freeRoundsPopup';
import { FreeRoundsEndPopup } from './freeRoundsPopupEnd';
import { isGetFreeSpinBonus, isGetHighChanceModeBonuses } from './helper';

const finishedFreeRounds = () => {
  return (
    setFreeRoundBonus().currentRound === setFreeRoundBonus().rounds &&
    setFreeRoundBonus().isActive &&
    !setCurrentBonus().isActive
  );
};

const openEndPopup = () => {
  if (!setFreeRoundBonus().isActive) return;
  setFreeRoundBonus({ ...setFreeRoundBonus(), isActive: false });
  eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
  eventManager.emit(EventTypes.OPEN_POPUP_FREE_ROUNDS_END);
};

export class FreeRoundBonus {
  private freeRoundsPopup: FreeRoundsPopup;

  private freeRoundsEndPopup: FreeRoundsEndPopup;

  private prevSlotMachineState: SlotMachineState = SlotMachineState.IDLE;

  private transitionType: FreeRoundBonusTransitionTypes =
    FreeRoundBonusTransitionTypes.NONE;

  constructor() {
    this.freeRoundsPopup = new FreeRoundsPopup();
    this.freeRoundsEndPopup = new FreeRoundsEndPopup();

    eventManager.addListener(
      EventTypes.SLOT_MACHINE_STATE_CHANGE,
      this.slotMachineStateChange.bind(this),
    );
    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.onChangeMode.bind(this),
    );
    eventManager.addListener(
      EventTypes.MANUAL_CHANGE_BACKGROUND,
      this.onChangeMode.bind(this),
    );

    setIsFreeRoundBonus(true);
    if (!setCurrentBonus().isActive) {
      eventManager.emit(
        EventTypes.OPEN_POPUP_FREE_ROUNDS,
        setFreeRoundBonus().rounds,
      );
    }
  }

  private slotMachineStateChange(state: SlotMachineState) {
    if (!setIsFreeRoundBonus()) return;

    // check transition
    if (state === SlotMachineState.STOP || state === SlotMachineState.JINGLE) {
      const nextResult = setNextResult();
      const gameMode = setGameMode();
      const hcm = setCurrentHCM();
      this.transitionType = FreeRoundBonusTransitionTypes.NONE;
      if (isBaseGameMode(gameMode)) {
        if (
          isGetHighChanceModeBonuses(nextResult!) &&
          !isGetFreeSpinBonus(nextResult!)
        ) {
          this.transitionType = FreeRoundBonusTransitionTypes.BASE_TO_HCM;
        }
      } else if (isHighChanceMode(gameMode)) {
        if (
          hcm.isActive &&
          hcm.currentRound === hcm.totalRounds &&
          hcm.bonusList.length === 0 &&
          !isGetFreeSpinBonus(nextResult!)
        ) {
          this.transitionType = FreeRoundBonusTransitionTypes.HCM_TO_BASE;
        }
      } else if (isFreeSpinsMode(gameMode)) {
        if (
          setCurrentBonus().currentRound > 0 &&
          setCurrentBonus().rounds === setCurrentBonus().currentRound
        ) {
          this.transitionType = FreeRoundBonusTransitionTypes.FS_TO_HCM;
        }
      }
    }

    if (state === SlotMachineState.IDLE) {
      if (this.prevSlotMachineState != SlotMachineState.IDLE) {
        const winAmount = setNextResult()!.bet.result.winCoinAmount;
        if (winAmount > 0) {
          if (setFreeRoundsTotalWin() === 0) {
            setFreeRoundsTotalWin(winAmount);
          } else {
            setFreeRoundsTotalWin(setFreeRoundsTotalWin() + winAmount);
          }
          eventManager.emit(
            EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE,
            setFreeRoundsTotalWin(),
          );
        }
      }
      // end frb
      if (finishedFreeRounds()) {
        const gameMode = setGameMode();
        const hcm = setCurrentHCM();
        if (isBaseGameMode(gameMode)) {
          if (this.transitionType === FreeRoundBonusTransitionTypes.NONE) {
            openEndPopup();
          }
        } else if (isHighChanceMode(gameMode)) {
          if (this.transitionType === FreeRoundBonusTransitionTypes.NONE) {
            openEndPopup();
          }
        }
      }
    }

    this.prevSlotMachineState = state;
  }

  private onChangeMode(settings: { mode: GameMode }) {
    const mode = settings.mode;
    const hcm = setCurrentHCM();
    if (isFreeSpinsMode(mode)) {
      if (setFreeRoundsTotalWin() > 0) {
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setFreeRoundsTotalWin(),
        );
      }
    } else if (isHighChanceMode(mode)) {
      eventManager.emit(
        EventTypes.UPDATE_TOTAL_WIN_VALUE,
        setFreeRoundsTotalWin(),
      );
      if (!this.freeRoundsPopup.isOnceOpened) {
        eventManager.emit(
          EventTypes.OPEN_POPUP_FREE_ROUNDS,
          setFreeRoundBonus().rounds - setFreeRoundBonus().currentRound,
        );
      } else if (finishedFreeRounds()) {
        openEndPopup();
      }
    } else {
      if (finishedFreeRounds()) {
        openEndPopup();
      }
    }
  }
}
