import AudioHowl from '@phoenix7dev/play-music';
import * as PIXI from 'pixi.js';

import { ISongs } from '../../config';
import { EventTypes, GameMode, PatLampAnimType } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinsMode, isHighChanceMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { eventManager, SLOTS_CONTAINER_WIDTH } from '../config';
import { HighChanceModeCounter } from './highChanceModeCounter';

class GameTitle extends ViewContainer {
  private lamp: SpineAnimation;

  private roof: PIXI.Sprite;

  private title: PIXI.Sprite;

  private patLampDef: PIXI.spine.Spine;

  private hcmCounter: ViewContainer;

  constructor() {
    super();

    this.roof = new PIXI.Sprite(
      PIXI.Texture.from(ResourceTypes.reelFrameRedtile),
    );
    this.roof.anchor.set(0.5, 0);

    this.title = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.titleLogo));
    this.title.anchor.set(0.5, 0);

    this.lamp = new SpineAnimation(
      {},
      PIXI.Loader.shared.resources.patlamp.spineData,
    );

    this.patLampDef = this.lamp.getSpine();
    this.lamp.setAnimation(PatLampAnimType.IDLE, false);
    this.patLampDef.y = 36;

    this.hcmCounter = new HighChanceModeCounter();
    this.hcmCounter.visible = false;

    this.addChild(this.patLampDef, this.roof, this.title, this.hcmCounter);

    this.pivot.set(0, this.roof.height);
    this.position.set(SLOTS_CONTAINER_WIDTH / 2, 16);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(
      EventTypes.MANUAL_CHANGE_BACKGROUND,
      this.onModeChange.bind(this),
    );

    eventManager.on(
      EventTypes.PATLAMP_ANIMATION,
      (patLmpAnimPtn: PatLampAnimType) => {
        this.animationPatLamp(patLmpAnimPtn);
      },
    );
  }

  private onModeChange(settings: { mode: GameMode }) {
    this.visible = !isFreeSpinsMode(settings.mode);
    this.hcmCounter.visible = isHighChanceMode(settings.mode);
  }

  private animationPatLamp(patLmpAnimPtn: PatLampAnimType) {
    this.lamp.setAnimation(patLmpAnimPtn, false);
    this.lamp.addAnimation(PatLampAnimType.IDLE, true, 0);

    if (patLmpAnimPtn === PatLampAnimType.IDLE) {
      this.soundStopPatLamp();
    } else {
      this.soundPlayPatLamp(patLmpAnimPtn);
    }
  }

  private soundStopPatLamp() {
    AudioHowl.stop({ type: ISongs.Quin });
    AudioHowl.stop({ type: ISongs.FSTrigger });
    AudioHowl.stop({ type: ISongs.QQQ });
  }

  private soundPlayPatLamp(patLmpAnimPtn: PatLampAnimType) {
    switch (patLmpAnimPtn) {
      case PatLampAnimType.X1:
        AudioHowl.play({ type: ISongs.Quin });
        break;
      case PatLampAnimType.X3:
        AudioHowl.play({ type: ISongs.FSTrigger });
        break;
      case PatLampAnimType.LONG:
        AudioHowl.play({ type: ISongs.QQQ });
        break;
      case PatLampAnimType.NULL:
        break;
    }
  }
}

export default GameTitle;
