import * as PIXI from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const textStyle = {
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: [
    '#b3ffff',
    '#0078ff',
    '#007dd4',
    '#20edff',
    '#2d28ff',
    '#0600ff',
    '#5b53ff',
    '#00065b',
  ],
  fillGradientStops: [0.2, 0.38, 0.5, 0.51, 0.53, 0.59, 0.66, 0.8],
  fontFamily: font,
  fontSize: 86,
  fontWeight: 800,
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#ffffff',
  strokeThickness: 10,
};

export const spinsStyle = {
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: [
    '#b3ffff',
    '#0078ff',
    '#007dd4',
    '#20edff',
    '#2d28ff',
    '#0600ff',
    '#5b53ff',
    '#00065b',
  ],
  fillGradientStops: [0.2, 0.38, 0.5, 0.51, 0.53, 0.59, 0.66, 0.8],
  fontFamily: font,
  fontSize: 86,
  fontWeight: 800,
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#ffffff',
  strokeThickness: 10,
};
