export const CAROUSEL_CONTROLS_COLOR = 0x2e2e2e;
export const CAROUSEL_DOTS_DEFAULT_COLOR = '#2e2e2e';
export const CAROUSEL_DOTS_ACTIVE_COLOR = '#ff00ba';
export const CAROUSEL_DOTS_BLUR_SIZE = 5;
export const CAROUSEL_DOTS_SIZE = 24;
export const CAROUSEL_DOTS_GAP = 10;
export const CAROUSEL_ARROWS_SIZE = 24;
export const CAROUSEL_TEXT_SIZE = 30;
export const CAROUSEL_GAME_FLOW_TEXT_SIZE = 19;
export const CAROUSEL_LETTER_SIZE = 30;
export const CAROUSEL_LETTERSCALE_SIZE = 36;
export const CAROUSEL_TEXT_LINE_HEIGHT = 25;
export const CAROUSEL_TEXT_COLOR = 0xff00ba;
export const CAROUSEL_GAME_FLOW_TEXT_COLOR = 0xff0000;
export const CAROUSEL_STROKE_COLOR = 0xffffff;
export const CAROUSEL_GAME_FLOW_STROKE_COLOR = 0xffffff;
export const CAROUSEL_TEXT_SHADOW = 0xff00ff;
export const CAROUSEL_TEXT_DIVIDE_COLOR = 0xfcff00;
export const CAROUSEL_TEXT_DIVIDE_STROKE_COLOR = 0xff00ba;
export const CAROUSEL_TEXT_WORD_WRAP_WIDTH = undefined;
export const CAROUSEL_ANIMATION_DURATION = 500;
