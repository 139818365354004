import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;
  const featuresFreeSpins = [
    {
      key: 'infoFeatureFreeSpinDesc1',
      text: i18n.t('infoFeatureFreeSpinDesc1'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoFeatureFreeSpinDesc2',
      text: i18n.t('infoFeatureFreeSpinDesc2'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoFeatureFreeSpinDesc3',
      text: i18n.t('infoFeatureFreeSpinDesc3'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoFeatureFreeSpinDesc4',
      text: i18n.t('infoFeatureFreeSpinDesc4'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoFeatureFreeSpinDesc5',
      text: i18n.t('infoFeatureFreeSpinDesc5'),
      buyFeatureOnly: false,
    },
  ];

  const featuresHighChanceMode = [
    {
      key: 'infoFeatureHighChanceModeDesc1',
      text: i18n.t('infoFeatureHighChanceModeDesc1'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoFeatureHighChanceModeDesc2',
      text: i18n.t('infoFeatureHighChanceModeDesc2'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoFeatureHighChanceModeDesc3',
      text: i18n.t('infoFeatureHighChanceModeDesc3'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoFeatureHighChanceModeDesc4',
      text: i18n.t('infoFeatureHighChanceModeDesc4'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoFeatureHighChanceModeDesc5',
      text: i18n.t('infoFeatureHighChanceModeDesc5'),
      buyFeatureOnly: false,
    },
    {
      key: 'infoFeatureHighChanceModeDesc6',
      text: i18n.t('infoFeatureHighChanceModeDesc6'),
      buyFeatureOnly: false,
    },
  ];

  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoFeatureFreeSpinTitle')}</h1>
      <div className={`${styles.p} ${styles.left}`}>
        {featuresFreeSpins.map((v) => (
          <div key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} />
        ))}
      </div>
      <h1 className={styles.title}>{t('infoFeatureHighChanceModeTitle')}</h1>
      <div className={`${styles.p} ${styles.left}`}>
        {featuresHighChanceMode.map((v) => (
          <div key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div className={styles.gameRules}>
      <FreeSpinFeature />
    </div>
  );
};

export default Features;
