import * as PIXI from 'pixi.js';

import { FreeSpinsTitleProps } from '../../global';
import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import { SLOTS_CONTAINER_WIDTH } from '../config';
import { FREESPINS_COUNTER_POS_Y } from './config';
import { FreeSpinCounter } from './freeSpinCounter';

export class FreeSpinsPanel extends ViewContainer {
  private spinCounter: FreeSpinCounter;

  private roof: PIXI.Sprite;

  constructor(props: FreeSpinsTitleProps) {
    super();

    this.roof = new PIXI.Sprite(
      PIXI.Texture.from(ResourceTypes.freeSpinReelFrameRedtile),
    );
    this.roof.anchor.set(0.5, 1);

    this.spinCounter = new FreeSpinCounter(props);
    this.spinCounter.y = FREESPINS_COUNTER_POS_Y;

    this.addChild(this.roof, this.spinCounter);

    this.position.set(SLOTS_CONTAINER_WIDTH / 2, 23);
  }

  // override destroy(options?: {
  public destroy(options?: {
    children?: boolean;
    texture?: boolean;
    baseTexture?: boolean;
  }): void {
    super.destroy(options);
  }
}
export default FreeSpinsPanel;
