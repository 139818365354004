import { SlotId } from './config';
import { ReelSetType } from './gql/query';
import Animation from './slotMachine/animations/animation';
import type { RemoteStorage } from '@phoenix7dev/setting-store-client';
import Tween from './slotMachine/animations/tween';
import { BgSkin } from './slotMachine/background/background';
import { SlotMachineState } from './slotMachine/config';
import { Icon, IWinLine, LineSet } from './slotMachine/d';

export interface IBalance {
  amount: number;
  currency: string;
}

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: IBalance;
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
  coinCode?: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    // slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    // userBonusId: string;
    userBonus: {
      betId: string;
      //   id: string;
      bonusId: string;
      //   lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    // data: {
    //   isBonus: boolean;
    //   bonuses: UserBonus[];
    //   features: {
    //     isFreeSpins: boolean;
    //   };
    // };
    createdAt: string;
    updatedAt: string;
  };
  paylines: IWinLine[];
  balance: {
    placed: IBalance;
    settled: IBalance;
  };
  rewards: BetReward[];
}
export type BetRewardType =
  | 'BetBonusReward'
  | 'BetCoinReward'
  | 'ReplayBonusReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};

export type BonusType = 'FREE_SPIN' | 'GAME_MODE' | '';
export type BetReward = BetBonusReward | BetCoinReward;
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
};
export type UserBonus = {
  id: string;
  bonus: Bonus;
  bonusId: string;
  betId: string;
  status: BonusStatus;
  coinValue: number;
  coinAmount: number;
  rounds: number;
  roundsPlayed: number;
  data: unknown;
  totalWinAmount: number;
  reelSetId: string;
  // front end side
  isActive: boolean;
  currentRound: number;
  totalRounds: number;
};

export type FreeRoundBonus = {
  id: string;
  bonusId: string;
  status: BonusStatus;
  coinAmount: number;
  coinValue: number;
  rounds: number;
  roundsPlayed: number;
  totalWinAmount: number;
  // front end side
  isActive: boolean;
  currentRound: number;
};

export type Bonus = {
  id: string;
  slotId: string;
  reelSetId: string;
  type: BonusType;
  title?: string;
  description?: string;
  lineSetId: string | null;
  coinAmount: number;
  purchasable: boolean;
  rounds: number;
  data: unknown;
};
export enum GameMode {
  REGULAR,
  HCM_MODE_A4,
  HCM_MODE_A5,
  HCM_MODE_A6,
  HCM_MODE_B2,
  HCM_MODE_B3,
  HCM_MODE_B4,
  HCM_MODE_B5,
  HCM_MODE_C1,
  HCM_MODE_C2,
  HCM_MODE_C3,
  HCM_MODE_C4,
  HCM_MODE_C5,
  HCM_MODE_D1,
  HCM_MODE_D2,
  HCM_MODE_D3,
  HCM_MODE_D4,
  HCM_MODE_D5,
  HCM_MODE_E1,
  HCM_MODE_E2,
  HCM_MODE_E3,
  HCM_MODE_E4,
  HCM_MODE_E5,
  HCM_MODE_F1,
  HCM_MODE_F2,
  HCM_MODE_F3,
  HCM_MODE_G1,
  HCM_MODE_G2,
  HCM_MODE_G3,
  FREE_SPINS,
}
export const reelSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: '924da72a-5bcc-4198-84da-95511e6d4b5d',
  [GameMode.HCM_MODE_A4]: '99d879a4-b070-4239-99d7-7f2ed3fc0bc2',
  [GameMode.HCM_MODE_A5]: '1b7622ba-cf62-4788-b6fc-349b1e04fb08',
  [GameMode.HCM_MODE_A6]: 'd497cceb-b38c-452c-9c0a-96076494e175',
  [GameMode.HCM_MODE_B2]: '474de6ed-f28a-4e96-a263-b30b38b23388',
  [GameMode.HCM_MODE_B3]: 'd9caff0e-20ad-4b76-9616-7eb9341d267b',
  [GameMode.HCM_MODE_B4]: '99d879a4-b070-4239-99d7-7f2ed3fc0bc2',
  [GameMode.HCM_MODE_B5]: '1b7622ba-cf62-4788-b6fc-349b1e04fb08',
  [GameMode.HCM_MODE_C1]: '3e488bb0-15af-4acc-a276-f72aab7a102f',
  [GameMode.HCM_MODE_C2]: '474de6ed-f28a-4e96-a263-b30b38b23388',
  [GameMode.HCM_MODE_C3]: 'd9caff0e-20ad-4b76-9616-7eb9341d267b',
  [GameMode.HCM_MODE_C4]: '99d879a4-b070-4239-99d7-7f2ed3fc0bc2',
  [GameMode.HCM_MODE_C5]: '1b7622ba-cf62-4788-b6fc-349b1e04fb08',
  [GameMode.HCM_MODE_D1]: '3e488bb0-15af-4acc-a276-f72aab7a102f',
  [GameMode.HCM_MODE_D2]: '474de6ed-f28a-4e96-a263-b30b38b23388',
  [GameMode.HCM_MODE_D3]: 'd9caff0e-20ad-4b76-9616-7eb9341d267b',
  [GameMode.HCM_MODE_D4]: '99d879a4-b070-4239-99d7-7f2ed3fc0bc2',
  [GameMode.HCM_MODE_D5]: '1b7622ba-cf62-4788-b6fc-349b1e04fb08',
  [GameMode.HCM_MODE_E1]: '3e488bb0-15af-4acc-a276-f72aab7a102f',
  [GameMode.HCM_MODE_E2]: '474de6ed-f28a-4e96-a263-b30b38b23388',
  [GameMode.HCM_MODE_E3]: 'd9caff0e-20ad-4b76-9616-7eb9341d267b',
  [GameMode.HCM_MODE_E4]: '99d879a4-b070-4239-99d7-7f2ed3fc0bc2',
  [GameMode.HCM_MODE_E5]: '1b7622ba-cf62-4788-b6fc-349b1e04fb08',
  [GameMode.HCM_MODE_F1]: '3e488bb0-15af-4acc-a276-f72aab7a102f',
  [GameMode.HCM_MODE_F2]: '474de6ed-f28a-4e96-a263-b30b38b23388',
  [GameMode.HCM_MODE_F3]: 'd9caff0e-20ad-4b76-9616-7eb9341d267b',
  [GameMode.HCM_MODE_G1]: '3e488bb0-15af-4acc-a276-f72aab7a102f',
  [GameMode.HCM_MODE_G2]: '474de6ed-f28a-4e96-a263-b30b38b23388',
  [GameMode.HCM_MODE_G3]: 'd9caff0e-20ad-4b76-9616-7eb9341d267b',
  [GameMode.FREE_SPINS]: '61787922-a39d-4723-94df-378b79f19503',
};

export const bonusIds: Record<GameMode, string> = {
  [GameMode.HCM_MODE_A4]: 'a4657157-b404-45a9-a0d1-deadbeef1337',
  [GameMode.HCM_MODE_A5]: 'c85d3f0f-9f2a-4872-8e7c-dfc530a91584',
  [GameMode.HCM_MODE_A6]: '6b00ac98-274f-4e4f-9159-57070cfa80f8',
  [GameMode.HCM_MODE_B2]: '62354584-c05a-4701-bf06-56181b9f70d9',
  [GameMode.HCM_MODE_B3]: '22bd6183-b44d-41c2-88cf-b707bc6da60d',
  [GameMode.HCM_MODE_B4]: '110aa64d-6360-4215-bed5-846d29c166cc',
  [GameMode.HCM_MODE_B5]: '17870ef5-f6e8-413c-ae5d-0f7db32cb386',
  [GameMode.HCM_MODE_C1]: 'cb542d8c-357c-4b71-bcad-2a57fbf7fdd4',
  [GameMode.HCM_MODE_C2]: '7602539b-705c-41dc-996c-dafd77340649',
  [GameMode.HCM_MODE_C3]: 'b871a9f5-0548-4efc-9fed-2a72514694fe',
  [GameMode.HCM_MODE_C4]: '45d53302-aa0c-455f-8ddd-ce99063640f3',
  [GameMode.HCM_MODE_C5]: '3662814b-3cea-4c10-951a-ccc6e7250bd0',
  [GameMode.HCM_MODE_D1]: 'b336ccbb-616d-464a-9f8e-69ae5d08026a',
  [GameMode.HCM_MODE_D2]: '03f9757d-1018-4bc7-b293-c5581b52851d',
  [GameMode.HCM_MODE_D3]: 'c640512e-f67b-41a6-b177-2c21a7b1b8d4',
  [GameMode.HCM_MODE_D4]: 'b670d278-5473-4ed2-8e1d-7904945743fc',
  [GameMode.HCM_MODE_D5]: '2ea539a7-07cd-4909-9e10-8566f5d8b566',
  [GameMode.HCM_MODE_E1]: '3d361e23-6f1c-4494-b760-ef68da7dd752',
  [GameMode.HCM_MODE_E2]: 'ed9f82d0-7c9a-49cb-be88-5ac0b46cc39b',
  [GameMode.HCM_MODE_E3]: '37c53206-5d64-4cc2-bfc6-d5919e5a1cba',
  [GameMode.HCM_MODE_E4]: 'e679e882-e482-438e-87dc-ce6da7a5d1a8',
  [GameMode.HCM_MODE_E5]: '9a6622ee-f550-4e84-bdb5-d7f24cde51bb',
  [GameMode.HCM_MODE_F1]: 'ef2e3e93-92e1-4b78-bee7-2155ea2e8ad4',
  [GameMode.HCM_MODE_F2]: '60ecbb03-a18e-4fea-9c98-3850636eecb1',
  [GameMode.HCM_MODE_F3]: '8fc6bb8b-7548-4271-bbac-875368c9e682',
  [GameMode.HCM_MODE_G1]: '425ac50d-13c1-432c-9157-b15f7c5fbaaf',
  [GameMode.HCM_MODE_G2]: '757a6482-9378-43d3-b83f-9b14e6689cf8',
  [GameMode.HCM_MODE_G3]: 'db4705cb-1551-42a4-abb8-298d144b9251',
  [GameMode.FREE_SPINS]: '20b0ce82-3a98-4f02-b152-1555c18f2212',
};

export const freeRoundBonusId = '5720a497-f3a8-44b6-909b-9f932232a400';

export const lineSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_A4]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_A5]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_A6]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_B2]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_B3]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_B4]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_B5]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_C1]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_C2]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_C3]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_C4]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_C5]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_D1]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_D2]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_D3]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_D4]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_D5]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_E1]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_E2]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_E3]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_E4]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_E5]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_F1]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_F2]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_F3]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_G1]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_G2]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.HCM_MODE_G3]: 'c309022e-4dc3-477f-b5bd-25ff34640324',
  [GameMode.FREE_SPINS]: '74a00335-a42d-4ff7-94fa-c903c72deefa',
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}
export interface GetUserBonusesInput {
  id: string;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: number;
  currentSpin: number;
};

export type BaseMessageBannerProps = {
  preventDefaultDestroy?: boolean;
  callback?: () => void;
  onInitCallback?: () => void;
};

export type MessageFreeSpinsBannerProps = BaseMessageBannerProps & {
  title: string;
  description: string;
  btnText: string;
};

export type MessageWinBannerProps = BaseMessageBannerProps & {
  title: string;
  totalWin: string;
  bonusStreak: string;
};

export type ReplayFreeSpinBets = {
  id: string;
  reelSetId: string;
};

export enum EventTypes {
  ANTICIPATION_STARTS = 'anticipationStarts',
  ANTICIPATION_ANIMATIONS_START = 'anticipationAnimationsStart',
  ANTICIPATION_ANIMATIONS_END = 'anticipationAnimationsEnd',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_FREE_SPINS_TITLE = 'createFreeSpinsTitle',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',
  END_RETRIGGER_FEATURE = 'endRetriggerFeature',
  JINGLE_START = 'jingleStart',
  HIDE_COINS = 'hideCoins',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_FREE_SPINS_TITLE = 'removeFreeSpinsTitle',
  REMOVE_ANIMATOR = 'removeAnimator',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  SET_WIN_LABEL_TEXT = 'setWinLabelText',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_RETRIGGER_ANIMATION = 'startRetrigger',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_FADE_ANTICIPATION = 'startFadeAnticipation',
  START_FREE_ROUNDS_END_FADE = 'startFreeRoundsEndFade',
  SHOW_WIN_LINES = 'showWinLines',
  SHOW_WIN_LABEL = 'showWinLabel',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_TINT = 'showTint',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  REMOVE_ANTICIPATION_TINT = 'removeAnticipationTint',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE = 'updateFreeRoundBonusTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  HANDLE_UPDATE_HIGH_CHANCE_MODE_COUNT = 'handleUpdateHighChanceModeCount',
  OPEN_POPUP_FREE_ROUNDS = 'openPopupFreeRounds',
  OPEN_POPUP_FREE_ROUNDS_END = 'openPopupFreeRoundsEnd',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  SET_IS_FREE_ROUND_BONUS = 'isFreeRoundBonus',
  SET_IS_OPENED_MESSAGE_BANNER = 'isOpenedMessageBanner',
  SET_IS_IN_TRANSITION = 'isInTransition',
  SET_IS_POPUP_FREE_ROUNDS_OPENED = 'isOpenPopupFreeRounds',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  PATLAMP_ANIMATION = 'patLampAnimation',
  GIRL_LAMP_ANIMATION = 'girlLampAnimation',
  SOUND_INITIALIZED = 'soundInitialized',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  CREATE_HCM_MESSAGE = 'createHcmMessage',
  HANDLE_SKIP_HCM_MESSAGE = 'handleSkipHcmMessage',
  START_CHERRY_BLINK_ANIMATION = 'startCherryBlinkAnimation',
  END_CHERRY_BLINK_ANIMATION = 'endCherryBlinkAnimation',
  PLACE_BET_COMPLETED = 'placeBetCompleted',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoplay',
  SET_REPLAY_BET_ID = 'setReplayBetId',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoplay',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    Howler: {
      _spriteBaseUrl: string;
    };
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export interface Events {
  [EventTypes.ANTICIPATION_STARTS]: (index: number) => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_START]: () => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_END]: () => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.COUNT_UP_END]: () => void;
  [EventTypes.CHANGE_MODE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    background?: BgSkin;
    isRetrigger?: boolean;
  }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: {
    reelSet: ReelSet;
    reelPositions: number[];
  }) => void;
  [EventTypes.CREATE_FREE_SPINS_TITLE]: (props: FreeSpinsTitleProps) => void;
  [EventTypes.CREATE_MESSAGE_BANNER]: (
    props: MessageFreeSpinsBannerProps,
  ) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (
    props: MessageWinBannerProps,
  ) => void;
  [EventTypes.DISABLE_ALL_MINI_PAY_TABLES]: () => void;
  [EventTypes.DISABLE_PAY_TABLE]: (isVisible: boolean) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean) => void;
  [EventTypes.JINGLE_START]: () => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HANDLE_CHANGE_RESTRICTION]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: () => void;
  [EventTypes.HANDLE_SKIP_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.NEXT_FREE_SPINS_ROUND]: () => void;
  [EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: {
    mode: GameMode;
    background?: BgSkin;
  }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;
  [EventTypes.REGISTER_ANIMATOR]: (
    animator: () => void,
    priority?: number,
  ) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.REMOVE_FREE_SPINS_TITLE]: () => void;
  [EventTypes.REMOVE_ANIMATOR]: (animator: () => void) => void;
  [EventTypes.ROLLBACK_REELS]: (positions: number[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.SET_SLOTS_VISIBILITY]: (
    slots: number[],
    visible: boolean,
  ) => void;
  [EventTypes.SET_WIN_LABEL_TEXT]: () => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_WIN_ANIMATION]: (
    nextResult: ISettledBet,
    isTurboSpin: boolean,
  ) => void;
  [EventTypes.START_SPIN_ANIMATION]: () => void;
  [EventTypes.START_RETRIGGER_ANIMATION]: () => void;
  [EventTypes.START_MODE_CHANGE_FADE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
    background?: BgSkin;
  }) => void;
  [EventTypes.SHOW_WIN_LINES]: () => void;
  [EventTypes.SHOW_WIN_LABEL]: () => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (
    spinResult: Icon[],
    reelId?: number,
  ) => void;
  [EventTypes.SHOW_TINT]: (state: boolean, reelId?: number) => void;
  [EventTypes.SHOW_PAY_TABLE]: (i: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_BIG_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MEGA_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GREAT_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_EPIC_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    reelPositions: Array<number>,
    scatterNo: Array<number>,
    anticipationStartReelId: number,
    [cherryAnticipationStartReelId, cherryAnticipationEndReelId]: [
      number,
      number,
    ],
  ) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: () => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.REMOVE_ANTICIPATION_TINT]: () => void;
  [EventTypes.UPDATE_BET]: (betAmount?: number) => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: {
    currency: string;
    amount: number;
  }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE]: (
    newValue: number,
  ) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE]: (
    spins: number,
    curr: number,
    immediately: boolean,
  ) => void;
  [EventTypes.HANDLE_UPDATE_HIGH_CHANCE_MODE_COUNT]: (
    spins: number,
    curr: number,
    immediately: boolean,
  ) => void;
  [EventTypes.SET_IS_LEFT_HAND_MODE]: (isLeftHandMode: boolean) => void;
  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean) => void;
  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREESPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.SET_IS_FREE_ROUND_BONUS]: (isFreeRoundBonus: boolean) => void;
  [EventTypes.SET_IS_OPENED_MESSAGE_BANNER]: (
    isOpenedMessageBanner: boolean,
  ) => void;
  [EventTypes.SET_IS_IN_TRANSITION]: (isInTransition: boolean) => void;
  [EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED]: (
    isPopupFreeRoundsOpened: boolean,
  ) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.PATLAMP_ANIMATION]: (patLmpAnimPtn: PatLampAnimType) => void;
  [EventTypes.GIRL_LAMP_ANIMATION]: (girlLampPtn: GirlLampType) => void;
  [EventTypes.SOUND_INITIALIZED]: (isError: boolean) => void;
  [EventTypes.ENABLE_SOUND_LOADER]: () => void;
  [EventTypes.CREATE_HCM_MESSAGE]: () => void;
  [EventTypes.HANDLE_SKIP_HCM_MESSAGE]: () => void;
  [EventTypes.START_FADE_ANTICIPATION]: (settings: {
    fadeOutDuration: number;
    fadeInDuration: number;
    callback: () => void;
  }) => void;
  [EventTypes.START_CHERRY_BLINK_ANIMATION]: (reelId: number) => void;
  [EventTypes.END_CHERRY_BLINK_ANIMATION]: () => void;
  [EventTypes.PLACE_BET_COMPLETED]: () => void;
  [EventTypes.SET_REPLAY_BET_ID]: () => void;
  [EventTypes.FORCE_STOP_AUTOPLAY]: () => void;

  [EventTypes.UPDATE_FREE_ROUNDS_LEFT]: (amount: number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS]: (rounds: number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS_END]: (isExpired?: boolean) => void;
  [EventTypes.START_FREE_ROUNDS_END_FADE]: (settings: {
    outDuration: number;
    inDuration: number;
    callback: () => void;
  }) => void;
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum PatLampAnimType {
  IDLE = 'lamp_idle',
  LONG = 'lamp_long',
  NULL = 'lamp_null',
  X1 = 'lamp_x1',
  X3 = 'lamp_x3',
}

export enum GirlLampType {
  IDLE = 'idle',
  IDLE_LIGHT = 'idle_light',
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  NONE = null,
}

export type HighChanceModeBonus = {
  isActive: boolean;
  activateChecked: boolean;
  background: BgSkin;
  currentRound: number;
  totalRounds: number;
  bonusList: UserBonus[];
};

export enum BgmSoundTypes {
  BASE = 'regular',
  HCM_DUSK = 'hcm:dusk',
  HCM_NIGHT = 'hcm:night',
  FS = 'fs',
}
