import AudioHowl from '@phoenix7dev/play-music';
import * as PIXI from 'pixi.js';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setIsLeftHandMode } from '../../gql/cache';
import { calcPercentage } from '../../utils';
import { isMobile } from 'mobile-device-detect';
import { eventManager } from '../config';

class Button extends PIXI.Container {
  public btn: PIXI.Sprite;

  public size: number;

  public isDisabled: boolean;

  public isLeftHandMode: boolean;

  public applicationSize: { width: number; height: number };

  public gameContainerSize: {
    width: number;
    height: number;
    x: number;
    y: number;
  };

  public gameContainerBottomPosition: number;

  public underGameContainerSpaceHeight: number;

  public isMobile: boolean;

  public isLandscapeMode: boolean;

  public isPortraitMode: boolean;

  protected btnSheet: PIXI.Spritesheet;

  private intent: string;

  private isActive: boolean;

  private isError: boolean;

  constructor(intent: string, size: number) {
    super();
    this.btnSheet = PIXI.Loader.shared.resources.buttonsSprite.spritesheet!;
    this.x = 0;
    this.y = 0;
    this.size = size;
    this.intent = intent;
    this.isActive = false;
    this.isError = false;
    this.isDisabled = false;
    this.applicationSize = { width: 0, height: 0 };
    this.gameContainerSize = { width: 0, height: 0, x: 0, y: 0 };
    this.gameContainerBottomPosition = 0;
    this.underGameContainerSpaceHeight = 0;
    this.isMobile = isMobile;
    this.isLeftHandMode = setIsLeftHandMode() && this.isMobile;
    this.isLandscapeMode = false;
    this.isPortraitMode = false;
    this.btn = this.initButton(intent);
    this.addChild(this.btn);
    eventManager.on(
      EventTypes.SET_IS_LEFT_HAND_MODE,
      this.handleLeftHandMode.bind(this),
    );
    eventManager.on(
      EventTypes.RESIZE_GAME_CONTAINER,
      this.gameContainerResize.bind(this),
    );
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
  }

  public updateIntent = (intent: string): void => {
    this.intent = intent;
    this.btn.texture = this.btnSheet.textures[intent];
  };

  public setActive(isActive: boolean): void {
    if (!this.isDisabled) {
      this.isActive = isActive;
      if (this.isActive) {
        this.setIntent(`${this.intent}_active`);
      } else {
        this.setIntent(this.intent);
      }
    }
  }

  public setError(hasError: boolean): void {
    this.isError = hasError;
    if (hasError) {
      this.setIntent(`${this.intent}_error`);
    } else {
      this.setIntent(`${this.intent}`);
    }
  }

  public setDisable(disable: boolean): void {
    this.isDisabled = disable;
    this.btn.interactive = !this.isDisabled;
    if (this.isDisabled) {
      this.setIntent(`${this.intent}_disable`);
    } else {
      this.setIntent(this.intent);
    }
  }

  public setSize(size: number): void {
    this.size = size;
    this.btn.width = size;
    this.btn.height = size;
    this.width = size;
    this.height = size;
  }

  public handlePosition(): void {}

  public isFreezed(): boolean {
    return false;
  }

  private handleLeftHandMode = (isLhm: boolean): void => {
    this.isLeftHandMode = isLhm && this.isMobile;
    this.handlePosition();
  };

  private gameContainerResize = (
    width: number,
    height: number,
    x: number,
    y: number,
  ): void => {
    this.gameContainerSize.width = width;
    this.gameContainerSize.height = height;
    this.gameContainerSize.x = x;
    this.gameContainerSize.y = y;
    this.gameContainerBottomPosition =
      this.gameContainerSize.y + this.gameContainerSize.height;
    this.underGameContainerSpaceHeight =
      this.applicationSize.height - this.gameContainerBottomPosition;
    this.handlePosition();
  };

  private applicationResize = (width: number, height: number): void => {
    this.isLandscapeMode = width >= height && this.isMobile;
    this.isPortraitMode = width < height && this.isMobile;
    this.applicationSize.width = width;
    this.applicationSize.height = this.isPortraitMode
      ? calcPercentage(height, 92.5)
      : calcPercentage(height, 95);
  };

  private initButton(intent: string) {
    const btn = new PIXI.Sprite(this.btnSheet.textures[intent]);
    btn.anchor.set(0.5, 0.5);
    btn.width = this.size;
    btn.height = this.size;
    btn.x = 0;
    btn.y = 0;
    btn.buttonMode = !this.isDisabled;
    btn.interactive = !this.isDisabled;
    btn.on('mousedown', () => {
      if (this.isFreezed()) return;

      this.setIntent(`${this.intent}_active`);
    });
    btn.on('mouseup', () => {
      if (this.isFreezed()) return;

      this.setIntent(this.intent);
    });
    btn.on('mouseover', () => {
      if (this.isFreezed()) return;

      AudioHowl.play({ type: ISongs.SFX_UI_Hover });
      if (!this.isError) {
        this.setIntent(`${this.intent}_hover`);
      }
    });
    btn.on('mouseout', () => {
      if (this.isFreezed()) return;

      if (!this.isActive && !this.isError) {
        this.setIntent(this.intent);
      }
    });
    return btn;
  }

  private setIntent = (intent: string): void => {
    this.btn.texture = this.btnSheet.textures[intent];
  };
}

export default Button;
