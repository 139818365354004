import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import ViewContainer from '../components/container';
import {
  eventManager,
  REELS_AMOUNT,
  SLOT_WIDTH,
  SLOTS_BACKGROUND_HEIGHT,
  SLOTS_CONTAINER_HEIGHT,
  TINT_ALPHA,
  TINT_BACKGROUND_COLOR,
} from '../config';

class TintContainer extends ViewContainer {
  public tints: PIXI.Graphics[] = [];

  constructor() {
    super();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const tint = new PIXI.Graphics()
        .beginFill(TINT_BACKGROUND_COLOR, TINT_ALPHA)
        .drawRect(
          0,
          SLOTS_CONTAINER_HEIGHT - SLOTS_BACKGROUND_HEIGHT,
          SLOT_WIDTH,
          SLOTS_BACKGROUND_HEIGHT,
        )
        .endFill();
      tint.x = i * SLOT_WIDTH;
      tint.visible = false;

      this.addChild(tint);
      this.tints.push(tint);
    }
    eventManager.addListener(EventTypes.SHOW_TINT, this.showTint.bind(this));

    eventManager.on(EventTypes.ANTICIPATION_ANIMATIONS_START, () => {
      this.tints.forEach((tint) => (tint.visible = true));
    });

    eventManager.on(EventTypes.ANTICIPATION_ANIMATIONS_END, () => {
      this.tints.forEach((tint) => (tint.visible = false));
    });
  }

  private showTint(state: boolean, reelId?: number): void {
    if (reelId !== undefined) {
      this.tints[reelId].visible = state;
      return;
    }

    this.tints.forEach((tint) => (tint.visible = state));
  }
}

export default TintContainer;
