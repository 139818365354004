import * as PIXI from 'pixi.js';

import { EventTypes, GameMode, reelSets } from '../../global.d';
import {
  setIsInTransition,
  setReplayBet,
  setReplayFreeSpinInReelPos,
} from '../../gql/cache';
import {
  isFreeSpinsMode,
  isHighChanceMode,
  updateCoinValueAfterBonuses,
} from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { DOUBLE_CHERRY_FAKE_FADE_TARGET, eventManager } from '../config';
import SlotMachine from '..';

class FadeArea extends ViewContainer {
  private sprite: PIXI.Sprite;

  constructor() {
    super();
    this.interactive = false;
    this.sprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.sprite.width = 100;
    this.sprite.height = 100;
    this.sprite.tint = 0x000000;
    this.sprite.alpha = 0;
    this.addChild(this.sprite);
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(
      EventTypes.START_MODE_CHANGE_FADE,
      this.startModeChangeFade.bind(this),
    );
    eventManager.addListener(
      EventTypes.START_FADE_ANTICIPATION,
      this.startFadeAnticipation.bind(this),
    );
    eventManager.addListener(
      EventTypes.START_FREE_ROUNDS_END_FADE,
      this.startFreeRoundsEndFade.bind(this),
    );
  }

  private resize(width: number, height: number): void {
    this.sprite.width = width;
    this.sprite.height = height;
  }

  private startModeChangeFade(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
  }): void {
    if (setReplayBet()) {
      if (isFreeSpinsMode(settings.mode) || isHighChanceMode(settings.mode)) {
        setReplayFreeSpinInReelPos(
          SlotMachine.getInstance().nextResult!.bet.result.reelPositions,
        );
      }
    }
    if (setReplayBet() && isHighChanceMode(settings.mode)) {
      settings.reelPositions = setReplayFreeSpinInReelPos();
      settings.reelSetId = reelSets[GameMode.REGULAR];
    }
    const animationChain: AnimationChain = new AnimationChain();
    const fadeOut = this.getFadeAnimation(1, settings.fadeOutDuration, 0);
    fadeOut.addOnComplete(() => {
      if (!isFreeSpinsMode(settings.mode)) {
        updateCoinValueAfterBonuses();
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
      }
      eventManager.emit(EventTypes.CHANGE_MODE, settings);
      if (isHighChanceMode(settings.mode)) {
        eventManager.emit(EventTypes.CREATE_HCM_MESSAGE);
      }
    });
    const fadeIn = this.getFadeAnimation(0, settings.fadeInDuration, 1);
    fadeIn.addOnComplete(() => {
      this.interactive = false;
      setIsInTransition(false);
    });
    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);
    animationChain.start();

    this.interactive = true;
    setIsInTransition(true);
  }

  private getFadeAnimation(
    alpha: number,
    duration: number,
    begin: number,
  ): Animation {
    const animation = new Tween({
      object: this.sprite,
      duration,
      propertyBeginValue: begin,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }

  private startFadeAnticipation(settings: {
    fadeOutDuration: number;
    fadeInDuration: number;
    callback: () => void;
  }): void {
    const animationChain: AnimationChain = new AnimationChain();

    const fadeOut = this.getFadeAnimation(
      DOUBLE_CHERRY_FAKE_FADE_TARGET,
      settings.fadeOutDuration,
      0,
    );
    const fadeIn = this.getFadeAnimation(
      0,
      settings.fadeInDuration,
      DOUBLE_CHERRY_FAKE_FADE_TARGET,
    );

    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);

    fadeIn.addOnComplete(() => {
      this.interactive = false;
      setIsInTransition(false);
      settings.callback();
    });

    animationChain.start();

    this.interactive = true;
    setIsInTransition(true);
  }

  private startFreeRoundsEndFade(settings: {
    outDuration: number;
    inDuration: number;
    callback: () => void;
  }) {
    const animationChain: AnimationChain = new AnimationChain();

    const fadeOut = this.getFadeAnimation(1, settings.outDuration, 0);
    const fadeIn = this.getFadeAnimation(0, settings.inDuration, 1);

    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);

    fadeIn.addOnStart(() => {
      settings.callback();
    });

    fadeIn.addOnComplete(() => {
      this.interactive = false;
      setIsInTransition(false);
    });

    animationChain.start();

    this.interactive = true;
    setIsInTransition(true);
  }
}

export default FadeArea;
