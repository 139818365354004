import { RemoteStorage } from '@phoenix7dev/setting-store-client';

export const urlSearchParams = new URLSearchParams(window.location.search);

export const isDemo = urlSearchParams.has('isDemo');

export const remoteStorage = new RemoteStorage({
  namespace: window.location.hostname,
  isDemo,
});
