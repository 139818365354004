import { BetBonusReward, BetReward, ISettledBet } from '../../global.d';

export const isGetFreeSpinBonus = (nextResult: ISettledBet) => {
  const isBonusReward = (reward: BetReward): reward is BetBonusReward =>
    reward.__typename === 'BetBonusReward';

  const freeSPinBonus = nextResult?.rewards
    .filter(isBonusReward)
    .find((reward) => {
      return reward.userBonus?.bonus.type === 'FREE_SPIN';
    })?.userBonus;

  return freeSPinBonus ? true : false;
};

export const isGetHighChanceModeBonuses = (nextResult: ISettledBet) => {
  const isBonusReward = (reward: BetReward): reward is BetBonusReward =>
    reward.__typename === 'BetBonusReward';

  return (
    nextResult!.rewards.filter(
      (reward): reward is BetBonusReward =>
        isBonusReward(reward) && reward.userBonus?.bonus.type === 'GAME_MODE',
    ).length > 0
  );
};
