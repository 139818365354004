import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import i18n from '../../i18next';
import { isMobile } from 'mobile-device-detect';
import { updateTextScale } from '../../utils/utils';
import Animation from '../animations/animation';
import { createZoomAnimation } from '../animations/helper';
import ViewContainer from '../components/container';
import { eventManager } from '../config';
import {
  HIGH_CHANCE_MODE_COUNTER_ANIMATION_DELAY,
  HIGH_CHANCE_MODE_COUNTER_ANIMATION_LOOP,
  HIGH_CHANCE_MODE_COUNTER_ANIMATION_SCALE,
  HIGH_CHANCE_MODE_COUNTER_POS_X,
  HIGH_CHANCE_MODE_COUNTER_POS_Y,
  spinsStyle,
  textStyle,
} from './config';

export class HighChanceModeCounter extends ViewContainer {
  private titleText: PIXI.Text;

  private spinsText: PIXI.Text;

  private pulsAnimation: Animation | null = null;

  constructor() {
    super();

    this.titleText = this.initTitleText(i18n.t('CHANCE'));
    this.spinsText = this.initSpinsText(5, 0);

    this.init();

    eventManager.addListener(
      EventTypes.HANDLE_UPDATE_HIGH_CHANCE_MODE_COUNT,
      (spins: number, curr: number, immediately = false): void =>
        this.handleUpdate(spins, curr, immediately),
    );
  }

  private init(): void {
    this.name = 'counter';
    this.addChild(this.titleText);
    this.addChild(this.spinsText);
    this.pivot.set(0, this.height);
    this.position.set(
      HIGH_CHANCE_MODE_COUNTER_POS_X,
      HIGH_CHANCE_MODE_COUNTER_POS_Y,
    );
  }

  private initTitleText(titleText: string): PIXI.Text {
    const text = new PIXI.Text(i18n.t(titleText), textStyle);
    text.resolution = 1;
    text.anchor.set(0, 0);
    updateTextScale(text, isMobile ? 220 : 400, isMobile ? 150 : 250);

    return text;
  }

  private initSpinsText(spins: number, currentSpin: number): PIXI.Text {
    const spinsText = new PIXI.Text(
      this.formatSpins(spins, currentSpin),
      spinsStyle,
    );
    spinsText.resolution = 1;
    spinsText.anchor.set(0.5, 0.5);
    spinsText.position.set(
      this.titleText.width / 2,
      this.titleText.height + spinsText.height / 2,
    );

    return spinsText;
  }

  private handleUpdate(
    spins: number,
    currentSpin: number,
    immediately = false,
  ): void {
    this.spinsText.text = this.formatSpins(spins, currentSpin);

    if (immediately) return;
    if (!this.visible) return;

    this.pulsAnimation = createZoomAnimation(
      this.spinsText,
      HIGH_CHANCE_MODE_COUNTER_ANIMATION_SCALE,
      HIGH_CHANCE_MODE_COUNTER_ANIMATION_DELAY,
      HIGH_CHANCE_MODE_COUNTER_ANIMATION_LOOP,
    );
    this.pulsAnimation?.start();
  }

  private formatSpins(spins: number, currentSpin: number): string {
    return `${currentSpin}/${spins}`;
  }
}
