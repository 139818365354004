import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';
import { SlotId } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.symbolASeven,
  [SlotId.B]: ResourceTypes.symbolBBar,
  [SlotId.C]: ResourceTypes.symbolCLion,
  [SlotId.D]: ResourceTypes.symbolDWatermelon,
  [SlotId.E]: ResourceTypes.symbolEBell,
  [SlotId.F]: ResourceTypes.symbolFBlue,
  [SlotId.G]: ResourceTypes.symbolGCherry,
  [SlotId.GC]: ResourceTypes.freeSpinSymbolAGoldenCherry,
  [SlotId.BL]: ResourceTypes.freeSpinSymbolBBlank,
};
export const MAPPED_BLURRED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.symbolASeven,
  [SlotId.B]: ResourceTypes.symbolBBar,
  [SlotId.C]: ResourceTypes.blurSymbolCLion,
  [SlotId.D]: ResourceTypes.blurSymbolDWatermelon,
  [SlotId.E]: ResourceTypes.blurSymbolEBell,
  [SlotId.F]: ResourceTypes.blurSymbolFBlue,
  [SlotId.G]: ResourceTypes.blurSymbolGCherry,
  [SlotId.GC]: ResourceTypes.freeSpinSymbolAGoldenCherry,
  [SlotId.BL]: ResourceTypes.blurFreeSpinSymbolBBlank,
};

export const MAPPED_SYMBOLS_STOP_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_A_Seven_announce',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_B_Bar_announce',
  },
  [SlotId.GC]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'FreeSpinSymbol_A_GoldenCherry_announce',
  },
};

export const MAPPED_SYMBOLS_ANIMATIONS: Record<
  SlotId,
  {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  }
> = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_A_Seven_win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_B_Bar_win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_C_Lion_win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_D_Watermelon_win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_E_Bell_win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_F_Blue_win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_G_Cherry_win',
  },
  [SlotId.GC]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'FreeSpinSymbol_A_GoldenCherry_win',
  },
  [SlotId.BL]: {
    type: SymbolAnimationType.DEFAULT,
  },
};

export const LOADER_MAPPED_SYMBOLS = [
  {
    name: ResourceTypes.symbolASeven,
    src: Resources.getSource(ResourceTypes.symbolASeven),
  },
  {
    name: ResourceTypes.symbolBBar,
    src: Resources.getSource(ResourceTypes.symbolBBar),
  },
  {
    name: ResourceTypes.symbolCLion,
    src: Resources.getSource(ResourceTypes.symbolCLion),
  },
  {
    name: ResourceTypes.symbolDWatermelon,
    src: Resources.getSource(ResourceTypes.symbolDWatermelon),
  },
  {
    name: ResourceTypes.symbolEBell,
    src: Resources.getSource(ResourceTypes.symbolEBell),
  },
  {
    name: ResourceTypes.symbolFBlue,
    src: Resources.getSource(ResourceTypes.symbolFBlue),
  },
  {
    name: ResourceTypes.symbolGCherry,
    src: Resources.getSource(ResourceTypes.symbolGCherry),
  },
  {
    name: ResourceTypes.freeSpinSymbolAGoldenCherry,
    src: Resources.getSource(ResourceTypes.freeSpinSymbolAGoldenCherry),
  },
  {
    name: ResourceTypes.freeSpinSymbolBBlank,
    src: Resources.getSource(ResourceTypes.freeSpinSymbolBBlank),
  },
];

export const generateTexturePath = (
  before: string,
  after: string,
  isMobile: boolean,
): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};
export const SPINE_LOADER_TEXTURES = (isMobile: boolean) => {
  return [
    {
      name: 'symbol_all',
      src: generateTexturePath(
        '/animations',
        'symbol_all/symbol_all.json',
        isMobile,
      ),
      isSpine: true,
      spineImages: {
        desktop: ['symbol_all.png', 'symbol_all1.jpg'],
        mobile: ['symbol_all.png', 'symbol_all1.jpg'],
      },
    },
    {
      name: 'patlamp',
      src: generateTexturePath('/animations', 'patlamp/patlamp.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['patlamp.png', 'patlamp1.jpg'],
        mobile: ['patlamp.png', 'patlamp1.jpg'],
      },
    },
    {
      name: 'patlamp_front',
      src: generateTexturePath(
        '/animations',
        'patlamp_front/patlamp_front.json',
        isMobile,
      ),
      isSpine: true,
      spineImages: {
        desktop: ['patlamp_front.jpg'],
        mobile: ['patlamp_front.jpg'],
      },
    },
    {
      name: 'ryukyucostume',
      src: generateTexturePath(
        '/animations',
        'ryukyucostume/ryukyucostume.json',
        isMobile,
      ),
      isSpine: true,
      spineImages: {
        desktop: ['ryukyucostume.png'],
        mobile: ['ryukyucostume.png'],
      },
    },
    {
      name: 'palmtree',
      src: generateTexturePath(
        '/animations',
        'palmtree/palmtree.json',
        isMobile,
      ),
      isSpine: true,
      spineImages: {
        desktop: ['palmtree.png'],
        mobile: ['palmtree.png'],
      },
    },
    {
      name: 'cloud',
      src: generateTexturePath('/animations', 'cloud/cloud.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['cloud.png', 'cloud2.png'],
        mobile: ['cloud.png', 'cloud2.png'],
      },
    },
    {
      name: 'reel',
      src: generateTexturePath('/animations', 'reel/reel.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['reel.jpg'],
        mobile: ['reel.jpg'],
      },
    },
    {
      name: 'fireworks',
      src: generateTexturePath(
        '/animations',
        'fireworks/fireworks.json',
        isMobile,
      ),
      isSpine: true,
      spineImages: {
        desktop: ['fireworks.jpg'],
        mobile: ['fireworks.jpg'],
      },
    },
    {
      name: 'near_miss',
      src: generateTexturePath(
        '/animations',
        'nearmiss/nearmiss.json',
        isMobile,
      ),
    },
    {
      name: 'coinsAnimation',
      src: generateTexturePath('/animations', 'coins/coins.json', isMobile),
    },
  ];
};

export const LOADER_TEXTURES = [
  {
    name: ResourceTypes.reelFrame,
    src: Resources.getSource(ResourceTypes.reelFrame),
  },
  {
    name: ResourceTypes.reelFrameRedtile,
    src: Resources.getSource(ResourceTypes.reelFrameRedtile),
  },
  {
    name: ResourceTypes.freeSpinReelFrame,
    src: Resources.getSource(ResourceTypes.freeSpinReelFrame),
  },
  {
    name: ResourceTypes.freeSpinReelFrameRedtile,
    src: Resources.getSource(ResourceTypes.freeSpinReelFrameRedtile),
  },
  {
    name: ResourceTypes.freeSpinLight,
    src: Resources.getSource(ResourceTypes.freeSpinLight),
  },
  {
    name: ResourceTypes.freeSpinLine,
    src: Resources.getSource(ResourceTypes.freeSpinLine),
  },
  {
    name: ResourceTypes.titleLogo,
    src: Resources.getSource(ResourceTypes.titleLogo),
  },
  {
    name: ResourceTypes.backgroundBase,
    src: Resources.getSource(ResourceTypes.backgroundBase),
  },
  {
    name: ResourceTypes.highChanceBGBase,
    src: Resources.getSource(ResourceTypes.highChanceBGBase),
  },
  {
    name: ResourceTypes.highChance2BGBase,
    src: Resources.getSource(ResourceTypes.highChance2BGBase),
  },
  {
    name: ResourceTypes.backgroundStoneWall,
    src: Resources.getSource(ResourceTypes.backgroundStoneWall),
  },
  {
    name: ResourceTypes.highChanceBGStoneWall,
    src: Resources.getSource(ResourceTypes.highChanceBGStoneWall),
  },
  {
    name: ResourceTypes.highChance2BGStoneWall,
    src: Resources.getSource(ResourceTypes.highChance2BGStoneWall),
  },
  {
    name: ResourceTypes.backgroundThicket,
    src: Resources.getSource(ResourceTypes.backgroundThicket),
  },
  {
    name: ResourceTypes.highChanceBGThicket,
    src: Resources.getSource(ResourceTypes.highChanceBGThicket),
  },
  {
    name: ResourceTypes.highChance2BGThicket,
    src: Resources.getSource(ResourceTypes.highChance2BGThicket),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.intro01,
    src: Resources.getSource(ResourceTypes.intro01),
  },
  {
    name: ResourceTypes.intro02,
    src: Resources.getSource(ResourceTypes.intro02),
  },
  {
    name: ResourceTypes.intro03,
    src: Resources.getSource(ResourceTypes.intro03),
  },
  {
    name: ResourceTypes.intro04,
    src: Resources.getSource(ResourceTypes.intro04),
  },
  {
    name: ResourceTypes.buttonOk,
    src: Resources.getSource(ResourceTypes.buttonOk),
  },
  {
    name: 'buttonsSprite',
    src: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    src: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    src: '/images/loader/loader.json',
  },
  {
    name: ResourceTypes.messageBanner,
    src: Resources.getSource(ResourceTypes.messageBanner),
  },
  {
    name: ResourceTypes.messageBannerBonus,
    src: Resources.getSource(ResourceTypes.messageBannerBonus),
  },
  {
    name: ResourceTypes.blurSymbolCLion,
    src: Resources.getSource(ResourceTypes.blurSymbolCLion),
  },
  {
    name: ResourceTypes.blurSymbolDWatermelon,
    src: Resources.getSource(ResourceTypes.blurSymbolDWatermelon),
  },
  {
    name: ResourceTypes.blurSymbolEBell,
    src: Resources.getSource(ResourceTypes.blurSymbolEBell),
  },
  {
    name: ResourceTypes.blurSymbolFBlue,
    src: Resources.getSource(ResourceTypes.blurSymbolFBlue),
  },
  {
    name: ResourceTypes.blurSymbolGCherry,
    src: Resources.getSource(ResourceTypes.blurSymbolGCherry),
  },
  {
    name: ResourceTypes.blurFreeSpinSymbolBBlank,
    src: Resources.getSource(ResourceTypes.blurFreeSpinSymbolBBlank),
  },
];
