const font = 'NotoSans-SemiCondensedBold';

export const titleTextStyle = {
  fontSize: 110,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: [
    '#FBC41C',
    '#F5FF3C',
    '#FFFFFF',
    '#FF8737',
    '#F9FF11',
    '#FBC41C',
    '#f08418',
    '#9f6518',
    '#ffeb19',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  letterSpacing: 0,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const descriptionTextStyle = {
  fontSize: 50,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  fill: '0xFFFFFF',
  align: 'center',
  fontVariant: 'normal',
  fontWeight: 'bolder',
  lineJoin: 'round',
  padding: 10,
};

export const btnTextStyle = {
  fontSize: 90,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: [
    '#FBC41C',
    '#F5FF3C',
    '#FFFFFF',
    '#FF8737',
    '#F9FF11',
    '#FBC41C',
    '#f08418',
    '#9f6518',
    '#ffeb19',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  letterSpacing: 0,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const totalWinTitleStyles = {
  ...titleTextStyle,
};

export const totalWinAmountTextStyles = {
  ...titleTextStyle,
};

export const bonusStreakTextStyle = {
  fontSize: 110,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: [
    '#b3ffff',
    '#0078ff',
    '#007dd4',
    '#20edff',
    '#2d28ff',
    '#0600ff',
    '#5b53ff',
    '#00065b',
    '#ffffff',
  ],
  fillGradientStops: [0.2, 0.38, 0.5, 0.53, 0.55, 0.59, 0.66, 0.8, 1.0],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  letterSpacing: 2,
  fontWeight: 'bolder',
  lineJoin: 'round',
};
