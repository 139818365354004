import { SlotId } from '../../config';
import { EventTypes, ReelSet } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import { isFreeSpinsMode } from '../../utils';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  BASE_REEL_ROLLING_SPEED,
  BASE_SPIN_TIME,
  CHERRY_ANTICIPATION_DELAY,
  CHERRY_ANTICIPATION_DURATION,
  CHERRY_ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT,
  eventManager,
  FORCE_STOP_SPIN_ANIMATION_DURATION,
  FORCE_STOP_SPIN_PER_EACH_DURATION,
  REEL_ENDING_SLOTS_AMOUNT,
  REELS_AMOUNT,
  ReelState,
  SEVEN_ANTICIPATION_DELAY,
  SEVEN_ANTICIPATION_DURATION,
  SEVEN_ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT,
  SEVEN_ANTICIPATION_REEL_FORMULA,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  TURBO_REEL_ROLLING_SPEED,
  TURBO_SPIN_TIME,
} from '../config';
import { Icon } from '../d';
import Reel from './reel';

class ReelsContainer extends ViewContainer {
  public reels: Reel[] = [];

  public forcedStop = false;

  constructor(reels: SlotId[][], startPosition: number[]) {
    super();
    this.initContainer();
    this.initReels(reels, startPosition);
    eventManager.addListener(
      EventTypes.SHOW_STOP_SLOTS_DISPLAY,
      this.hideSlots.bind(this),
    );
    eventManager.addListener(
      EventTypes.HIDE_STOP_SLOTS_DISPLAY,
      this.showSlots.bind(this),
    );
    eventManager.addListener(
      EventTypes.SETUP_REEL_POSITIONS,
      this.setupAnimationTarget.bind(this),
    );
    eventManager.addListener(
      EventTypes.FORCE_STOP_REELS,
      this.forceStopReels.bind(this),
    );
    eventManager.addListener(
      EventTypes.CHANGE_REEL_SET,
      this.changeReelSet.bind(this),
    );
    eventManager.addListener(
      EventTypes.ROLLBACK_REELS,
      this.rollbackReels.bind(this),
    );
    this.sortableChildren = true;
  }

  private hideSlots(spinResult: Icon[], reelId?: number): void {
    const arr = [];
    if (reelId !== undefined) {
      if (isFreeSpinsMode(setGameMode())) {
        arr.push(REELS_AMOUNT + reelId);
      } else {
        for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
          arr.push(i * REELS_AMOUNT + reelId);
        }
      }
    } else {
      if (isFreeSpinsMode(setGameMode())) {
        for (let i = 0; i < REELS_AMOUNT; i++) {
          arr.push(i + REELS_AMOUNT);
        }
      } else {
        for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
          arr.push(i);
        }
      }
    }
    this.setSlotsVisibility(arr, false);
  }

  private showSlots(): void {
    const arr = [];
    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) arr.push(i);
    this.setSlotsVisibility(arr, true);
  }

  private rollbackReels(positions: number[]): void {
    for (let i = 0; i < positions.length; i++) {
      eventManager.emit(
        EventTypes.REMOVE_TWEEN_ANIMATION,
        this.reels[i].spinAnimation?.getStarting() as Tween,
      );
      eventManager.emit(
        EventTypes.REMOVE_TWEEN_ANIMATION,
        this.reels[i].spinAnimation?.getFakeRolling() as Tween,
      );
      this.reels[i].position = this.reels[i].size - positions[i];
      this.reels[i].state = ReelState.IDLE;
    }
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
  }

  private changeReelSet(settings: {
    reelSet: ReelSet;
    reelPositions: number[];
  }): void {
    const reelLayout = settings.reelSet.layout.map((reel) =>
      reel.length < SLOTS_PER_REEL_AMOUNT + 2 ? [...reel, ...reel] : reel,
    );

    const reelPositions = settings.reelPositions
      .slice(0, REELS_AMOUNT)
      .map(
        (position, idx) =>
          (reelLayout[idx].length - position) % reelLayout[idx].length,
      );

    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i].clean();
      this.reels[i].init(reelLayout[i], reelPositions[i]);
    }
  }

  private initReels(reels: SlotId[][], startPosition?: number[]): void {
    reels = reels.map((reel) =>
      reel.length < SLOTS_PER_REEL_AMOUNT + 2 ? [...reel, ...reel] : reel,
    );

    for (let i = 0; i < REELS_AMOUNT; i++) {
      const position = startPosition ? startPosition[i] : 0;
      const reel = new Reel(i, reels[i], position);
      this.reels[i] = reel;
      this.addChild(reel.container);

      eventManager.emit(EventTypes.REGISTER_ANIMATOR, reel.animator);
    }
  }

  private forceStopReels(isTurboSpin: boolean): void {
    this.forcedStop = true;
    const stopAllReelsAtSameTime =
      Date.now() - this.reels[0].spinAnimation!.startTime <
      (isTurboSpin ? TURBO_SPIN_TIME : BASE_SPIN_TIME);

    if (stopAllReelsAtSameTime) {
      let [maxSoundNo, maxSoundIdx] = [-1, 0];
      for (let i = 0; i < this.reels.length; i++) {
        if (maxSoundNo < this.reels[i].stopSoundSymbolNo) {
          [maxSoundNo, maxSoundIdx] = [this.reels[i].stopSoundSymbolNo, i];
        }
        this.reels[i].isPlaySoundOnStop = false;
      }
      this.reels[maxSoundIdx].isPlaySoundOnStop = true;
    }

    for (let i = 0; i < this.reels.length; i++) {
      // if (stopAllReelsAtSameTime && i !== 0) {
      //   this.reels[i].isPlaySoundOnStop = false;
      // }
      this.reels[i].stopReel(
        stopAllReelsAtSameTime
          ? FORCE_STOP_SPIN_ANIMATION_DURATION
          : FORCE_STOP_SPIN_ANIMATION_DURATION +
              i * FORCE_STOP_SPIN_PER_EACH_DURATION,
      );
    }
  }

  private prolongTarget = (reel: Reel, minValue: number): number => {
    let res = 0;
    while (res < minValue) res += reel.data.length;
    return res;
  };

  private setupAnimationTarget(
    reelPositions: Array<number>,
    stopSoundSymbolNo: Array<number>,
    anticipationStartReelId: number,
    [cherryAnticipationStartReelId, cherryAnticipationEndReelId]: [
      number,
      number,
    ],
  ): void {
    const rollingSpeed = this.reels[0].isTurboSpin
      ? TURBO_REEL_ROLLING_SPEED
      : BASE_REEL_ROLLING_SPEED;

    for (let j = 0; j < this.reels.length; j++) {
      const fakeRollingAnimation =
        this.reels[j].spinAnimation!.getFakeRolling();
      fakeRollingAnimation.duration = 0;

      this.reels[j].stopSoundSymbolNo = stopSoundSymbolNo[j];

      const rollingAnimation = this.reels[j].spinAnimation!.getRolling();
      const endingAnimation = this.reels[j].spinAnimation!.getEnding();
      let target = this.reels[j].getTarget(
        this.reels[j].data.length - reelPositions[j],
      );

      if (j > anticipationStartReelId) {
        rollingAnimation.duration +=
          SEVEN_ANTICIPATION_DURATION * (j - anticipationStartReelId - 1) +
          SEVEN_ANTICIPATION_DELAY * (j - anticipationStartReelId);
        let beginValue =
          target -
          SEVEN_ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT -
          Math.round(rollingAnimation.duration * rollingSpeed);
        if (beginValue < 0) {
          const prolong = this.prolongTarget(
            this.reels[j],
            Math.abs(beginValue),
          );
          beginValue += prolong;
          target += prolong;
        }
        rollingAnimation.propertyBeginValue = beginValue;
        rollingAnimation.target =
          target - SEVEN_ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT;

        endingAnimation.propertyBeginValue =
          target - SEVEN_ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT;
        endingAnimation.target = target;
        endingAnimation.duration = SEVEN_ANTICIPATION_DURATION;
        endingAnimation.easing = SEVEN_ANTICIPATION_REEL_FORMULA;
        rollingAnimation.addOnComplete(() => {
          eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_START);
          eventManager.emit(EventTypes.ANTICIPATION_STARTS, j);
        });
        endingAnimation.addOnComplete(() => {
          eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_END);
        });
      } else if (j > cherryAnticipationStartReelId) {
        rollingAnimation.duration +=
          CHERRY_ANTICIPATION_DURATION *
            (j - cherryAnticipationStartReelId - 1) +
          CHERRY_ANTICIPATION_DELAY * (j - cherryAnticipationStartReelId);
        let beginValue =
          target -
          CHERRY_ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT -
          Math.round(rollingAnimation.duration * rollingSpeed);
        if (beginValue < 0) {
          const prolong = this.prolongTarget(
            this.reels[j],
            Math.abs(beginValue),
          );
          beginValue += prolong;
          target += prolong;
        }
        rollingAnimation.propertyBeginValue = beginValue;
        rollingAnimation.target =
          target - CHERRY_ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT;

        if (j <= cherryAnticipationEndReelId) {
          endingAnimation.propertyBeginValue =
            target - CHERRY_ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT;
          endingAnimation.target = target;
          endingAnimation.duration = CHERRY_ANTICIPATION_DURATION;
          endingAnimation.addOnStart(() => {
            // eventManager.emit(EventTypes.ANTICIPATION_STARTS, j);
          });
        } else {
          endingAnimation.propertyBeginValue =
            target - REEL_ENDING_SLOTS_AMOUNT;
          endingAnimation.target = target;
        }
      } else {
        let beginValue =
          target -
          REEL_ENDING_SLOTS_AMOUNT -
          Math.round(rollingAnimation.duration * rollingSpeed);
        if (beginValue < 0) {
          const prolong = this.prolongTarget(
            this.reels[j],
            Math.abs(beginValue),
          );
          beginValue += prolong;
          target += prolong;
        }
        rollingAnimation.propertyBeginValue = beginValue;
        rollingAnimation.target = target - REEL_ENDING_SLOTS_AMOUNT;

        endingAnimation.propertyBeginValue = target - REEL_ENDING_SLOTS_AMOUNT;
        endingAnimation.target = target;
      }

      // CHERRY BLINK
      if (
        cherryAnticipationStartReelId <= j &&
        j < cherryAnticipationEndReelId
      ) {
        endingAnimation.addOnComplete(() => {
          eventManager.emit(EventTypes.START_CHERRY_BLINK_ANIMATION, j);
          eventManager.emit(EventTypes.SHOW_TINT, true, j);
        });
      }
      if (j === cherryAnticipationEndReelId) {
        endingAnimation.addOnComplete(() => {
          eventManager.emit(EventTypes.END_CHERRY_BLINK_ANIMATION);
          eventManager.emit(EventTypes.SHOW_TINT, false);
        });
      }
    }
  }

  private setSlotsVisibility(slots: number[], visibility: boolean): void {
    slots.forEach((slotId) => {
      const x = slotId % REELS_AMOUNT;
      const y = Math.floor(slotId / REELS_AMOUNT);
      const position =
        this.reels[x].size -
        (this.reels[x].position % this.reels[x].size) +
        y -
        1;
      const normalizedPosition =
        position === -1
          ? this.reels[x].size - 1
          : position % this.reels[x].size;
      const slot = this.reels[x].slots[normalizedPosition];
      if (slot) slot.visible = visibility;
    });
  }
}

export default ReelsContainer;
