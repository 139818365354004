import _ from 'lodash';

import {
  MAPPED_SYMBOLS,
  MAPPED_SYMBOLS_STOP_ANIMATIONS,
  SlotId,
} from '../../config';
import { EventTypes } from '../../global.d';
import { setGameMode, setNextResult } from '../../gql/cache';
import { destroySpine, isFreeSpinsMode } from '../../utils';
import Animation from '../animations/animation';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_SLOTS_TINT,
  eventManager,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  REEL_WIDTH,
  REELS_AMOUNT,
  SLOT_HEIGHT,
  SLOT_SCALE,
  SLOT_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
} from '../config';
import { Icon } from '../d';

export class SlotsStopDisplayContainer extends ViewContainer {
  private screenDisplay: Icon[] = [];

  private stopSymbolAnimations: Animation[] = [];

  private cherryBlinkAnimations: SpineAnimation[] = [];

  private slotSprites: PIXI.Sprite[] = [];

  constructor(spinResult: Icon[]) {
    super();
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.screenDisplay = spinResult;
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const sprite = new PIXI.Sprite(
          PIXI.Texture.from(
            MAPPED_SYMBOLS[spinResult[i * REELS_AMOUNT + j].id],
          ),
        );
        sprite.width = SLOT_WIDTH * SLOT_SCALE;
        sprite.height = SLOT_HEIGHT * SLOT_SCALE;
        sprite.anchor.set(0.5, 0.5);
        sprite.x = REEL_WIDTH * j + REEL_WIDTH / 2;
        sprite.y = SLOT_HEIGHT * i + SLOT_HEIGHT / 2;
        this.addChild(sprite);
        this.slotSprites.push(sprite);
      }
    }
    eventManager.addListener(
      EventTypes.START_SPIN_ANIMATION,
      this.skipStopSymbolAnimations.bind(this),
    );
    eventManager.addListener(
      EventTypes.ANTICIPATION_ANIMATIONS_START,
      this.onAnticipationAnimationStarts.bind(this),
    );
    eventManager.addListener(
      EventTypes.ANTICIPATION_ANIMATIONS_END,
      this.resetSlotsTint.bind(this),
    );
    eventManager.addListener(
      EventTypes.HIDE_STOP_SLOTS_DISPLAY,
      this.hideContainer.bind(this),
    );
    eventManager.addListener(
      EventTypes.SHOW_STOP_SLOTS_DISPLAY,
      this.showSlotStops.bind(this),
    );
    eventManager.addListener(
      EventTypes.REEL_STOPPED,
      this.onReelStopped.bind(this),
    );
    eventManager.addListener(
      EventTypes.JINGLE_START,
      this.skipScatterAnnounce.bind(this),
    );
    eventManager.addListener(
      EventTypes.SET_SLOTS_VISIBILITY,
      this.handleSetSlotsVisibility.bind(this),
    );
    eventManager.addListener(
      EventTypes.START_CHERRY_BLINK_ANIMATION,
      this.startCherryBlinkAnimation.bind(this),
    );
    eventManager.addListener(
      EventTypes.END_CHERRY_BLINK_ANIMATION,
      this.endCherryBlinkAnimation.bind(this),
    );
  }

  private skipScatterAnnounce(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
  }

  private onAnticipationAnimationStarts(): void {
    this.slotSprites.forEach((slot, index) => {
      const slotId = setNextResult()!.bet.result.spinResult[index].id;
      if (!(slotId === SlotId.A || slotId === SlotId.B)) {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      } else {
        slot.zIndex = 3;
      }
    });
  }

  private resetSlotsTint(): void {
    this.slotSprites.forEach((slot) => {
      slot.tint = 0xffffff;
    });
  }

  private onReelStopped(reelId: number): void {
    eventManager.emit(
      EventTypes.SHOW_STOP_SLOTS_DISPLAY,
      setNextResult()!.bet.result.spinResult,
      reelId,
    );
    this.startOnSymbolsStopAnimations(reelId);
  }

  private startOnSymbolsStopAnimations(reelId: number): void {
    // TODO: Refactor
    if (reelId === 0) this.stopSymbolAnimations = [];
    if (isFreeSpinsMode(setGameMode())) {
      const slotId =
        setNextResult()?.bet.result.spinResult[REELS_AMOUNT + reelId].id;

      let canStartAnimation = true;
      for (let i = reelId; i >= 0; i--) {
        const id = setNextResult()?.bet.result.spinResult[REELS_AMOUNT + i].id;
        canStartAnimation = canStartAnimation && id === SlotId.GC;
      }
      if (
        slotId &&
        canStartAnimation &&
        MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId]
      ) {
        const animationData = MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId];
        if (!animationData || !animationData.src || !animationData.animation)
          throw Error('INVALID SPINE DATA');
        const animation = new SpineAnimation(
          {},
          PIXI.Loader.shared.resources[animationData.src].spineData,
        );
        const dummy = Tween.createDelayAnimation(1000);
        dummy.addOnStart(() => {
          animation.spine.y = SLOT_HEIGHT / 2 + SLOT_HEIGHT;
          animation.spine.x = REEL_WIDTH / 2 + REEL_WIDTH * reelId;
          this.addChild(animation.getSpine());
          animation.setAnimation(animationData.animation!, false);
          this.slotSprites[REELS_AMOUNT + reelId].visible = false;
        });

        dummy.addOnComplete(() => {
          destroySpine(animation);
          this.removeChild(animation.spine);
          this.slotSprites[REELS_AMOUNT + reelId].visible = true;
        });

        dummy.addOnSkip(() => {
          destroySpine(animation);
          this.removeChild(animation.spine);
          this.slotSprites[REELS_AMOUNT + reelId].visible = true;
        });
        this.stopSymbolAnimations.push(dummy);
        dummy.start();
      }
      return;
    }
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const slotId =
        setNextResult()?.bet.result.spinResult[i * REELS_AMOUNT + reelId].id;
      if (slotId && MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId]) {
        const animationData = MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId];
        if (!animationData || !animationData.src || !animationData.animation)
          throw Error('INVALID SPINE DATA');
        const animation = new SpineAnimation(
          {},
          PIXI.Loader.shared.resources[animationData.src].spineData,
        );
        const dummy = Tween.createDelayAnimation(1000);
        dummy.addOnStart(() => {
          animation.spine.y = SLOT_HEIGHT / 2 + SLOT_HEIGHT * i;
          animation.spine.x = REEL_WIDTH / 2 + REEL_WIDTH * reelId;
          this.addChild(animation.getSpine());
          animation.setAnimation(animationData.animation!, false);
          this.slotSprites[i * REELS_AMOUNT + reelId].visible = false;
        });

        dummy.addOnComplete(() => {
          destroySpine(animation);
          this.removeChild(animation.spine);
          this.slotSprites[i * REELS_AMOUNT + reelId].visible = true;
        });

        dummy.addOnSkip(() => {
          destroySpine(animation);
          this.removeChild(animation.spine);
          this.slotSprites[i * REELS_AMOUNT + reelId].visible = true;
        });
        this.stopSymbolAnimations.push(dummy);
        dummy.start();
      }
    }
  }

  private skipStopSymbolAnimations(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
    this.stopSymbolAnimations = [];
    this.endCherryBlinkAnimation();
  }

  private startCherryBlinkAnimation(reelId: number): void {
    if (reelId === 0) {
      for (let reelId = 0; reelId < 3; reelId++) {
        for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
          const slotId =
            setNextResult()?.bet.result.spinResult[i * REELS_AMOUNT + reelId]
              .id;
          if (slotId !== SlotId.G) {
            this.slotSprites[i * REELS_AMOUNT + reelId].tint =
              ANTICIPATION_SLOTS_TINT;
            continue;
          }

          const animation = new SpineAnimation(
            {},
            PIXI.Loader.shared.resources.symbol_all.spineData,
          );
          animation.spine.position.set(
            REEL_WIDTH / 2 + REEL_WIDTH * reelId,
            SLOT_HEIGHT / 2 + SLOT_HEIGHT * i,
          );
          this.addChild(animation.getSpine());
          this.cherryBlinkAnimations.push(animation);
        }
      }
    }
    this.cherryBlinkAnimations.forEach((anim, index) => {
      if (index <= reelId) {
        anim.setAnimation('Symbol_G_Cherry_appeal', true);
      }
    });
  }

  private endCherryBlinkAnimation(): void {
    const children = this.cherryBlinkAnimations.map((anim) => anim.spine);
    this.removeChild(...children);
    this.cherryBlinkAnimations.forEach((anim) => destroySpine(anim));
    this.cherryBlinkAnimations = [];
    this.resetSlotsTint();
  }

  private handleSetSlotsVisibility(slots: number[], visible: boolean): void {
    for (let i = 0; i < slots.length; i++) {
      this.slotSprites[slots[i]].visible = visible;
    }
  }

  private showSlotStops(spinResult: Icon[], reelId?: number): void {
    this.visible = true;

    if (reelId !== undefined) {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        this.slotSprites[i * REELS_AMOUNT + reelId].texture = PIXI.Texture.from(
          MAPPED_SYMBOLS[spinResult[i * REELS_AMOUNT + reelId].id],
        );
        this.slotSprites[i * REELS_AMOUNT + reelId].visible = true;
      }
    } else {
      for (let i = 0; i < spinResult.length; i++) {
        this.slotSprites[i].texture = PIXI.Texture.from(
          MAPPED_SYMBOLS[spinResult[i].id],
        );
        this.slotSprites[i].visible = true;
      }
    }
  }

  private hideContainer(): void {
    this.visible = false;
    this.slotSprites.forEach((sprite) => (sprite.visible = false));
  }
}
