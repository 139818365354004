import 'pixi-spine';

import Animation from './animation';
import { IAnimation } from './d';

class SpineAnimation extends Animation {
  public spine: PIXI.spine.Spine;

  constructor(options: IAnimation, skeletonData: PIXI.spine.core.SkeletonData) {
    super(options);
    this.spine = new PIXI.spine.Spine(skeletonData);
    this.spine.state.addListener({
      complete: this.onComplete.bind(this),
    });
  }

  public getSpine(): PIXI.spine.Spine {
    return this.spine!;
  }

  public setAnimation(animationName: string, loop: boolean): void {
    this.spine?.state.setAnimation(0, animationName, loop);
  }

  public addAnimation(
    animationName: string,
    loop: boolean,
    delay: number,
  ): void {
    this.spine?.state.addAnimation(0, animationName, loop, delay);
  }

  public setSkin(skinName: string): void {
    this.spine?.skeleton.setSkinByName(skinName);
  }

  public start(): void {
    super.start();
  }
}
export default SpineAnimation;
