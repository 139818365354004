import { AudioSprite } from '@phoenix7dev/play-music/dist/d';
import _ from 'lodash';

export enum ISongs {
  BGM_BG_Base_Loop = '005_01_BGM_BG_Base_Loop',
  BGM_BG_Melo_Loop = '005_02_BGM_BG_Melo_Loop',
  BGM_HCZ1_Loop = '005_03_BGM_HCZ1_Loop',
  BGM_HCZ2_Loop = '005_04_BGM_HCZ2_Loop',
  BGM_FS_Loop = '005_05_BGM_FS_Loop',
  TotalWinBanner = '005_06_TotalWinBanner',
  BigWin_Loop = '005_07_BigWin_Loop',
  BigWin_End = '005_08_BigWin_End',
  Win_Loop = '005_09_Win_Loop',
  Win_End = '005_10_Win_End',
  LongSpin = '005_11_LongSpin',
  BonusStop1 = '005_12_BonusStop1',
  BonusStop2 = '005_13_BonusStop2',
  BonusStop3 = '005_14_BonusStop3',
  CherryStop1 = '005_15_CherryStop1',
  CherryStop2 = '005_16_CherryStop2',
  CherryStop3 = '005_17_CherryStop3',
  FSStopx1 = '005_18_FSStopx1',
  FSStopx2 = '005_19_FSStopx2',
  FSStopx3 = '005_20_FSStopx3',
  FSStopx5 = '005_21_FSStopx5',
  FSTrigger = '005_22_FSTrigger',
  Quin = '005_23_Quin',
  QQQ = '005_24_QQQ',
  Kana = '005_25_Kana',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_WIN_BIG = 'SFX_Win_Big',
  SFX_WIN_EPIC = 'SFX_Win_Epic',
  SFX_WIN_GREAT = 'SFX_Win_Great',
  SFX_WIN_MEGA = 'SFX_Win_Mega',
}

export const audioSprite: AudioSprite = {
  [ISongs.BGM_BG_Base_Loop]: [0, 60800.04535147393, true],
  [ISongs.BGM_BG_Melo_Loop]: [62000, 60800.04535147393, true],
  [ISongs.BGM_HCZ1_Loop]: [124000, 35121.97278911566, true],
  [ISongs.BGM_HCZ2_Loop]: [161000, 30412.15419501134, true],
  [ISongs.BGM_FS_Loop]: [193000, 34966.938775510185, true],
  [ISongs.TotalWinBanner]: [229000, 5570.340136054427],
  [ISongs.BigWin_Loop]: [236000, 29793.17460317458, true],
  [ISongs.BigWin_End]: [267000, 4138.503401360538],
  [ISongs.Win_Loop]: [273000, 399.7505668934309, true],
  [ISongs.Win_End]: [275000, 933.4693877551103],
  [ISongs.LongSpin]: [277000, 5309.750566893399],
  [ISongs.BonusStop1]: [284000, 2474.1950113378834],
  [ISongs.BonusStop2]: [288000, 2474.26303854877],
  [ISongs.BonusStop3]: [292000, 2783.5147392290196],
  [ISongs.CherryStop1]: [296000, 1333.3786848072577],
  [ISongs.CherryStop2]: [299000, 1600.0453514739092],
  [ISongs.CherryStop3]: [302000, 1758.0045351473927],
  [ISongs.FSStopx1]: [305000, 742.3129251700402],
  [ISongs.FSStopx2]: [307000, 697.7097505669008],
  [ISongs.FSStopx3]: [309000, 894.9659863945385],
  [ISongs.FSStopx5]: [311000, 5309.750566893399],
  [ISongs.FSTrigger]: [318000, 1033.3333333333599],
  [ISongs.Quin]: [321000, 433.3333333333371],
  [ISongs.QQQ]: [323000, 2501.0204081632423],
  [ISongs.Kana]: [327000, 3982.3129251700493],
  [ISongs.SFX_UI_AutoSpin]: [332000, 320.47619047619946],
  [ISongs.SFX_UI_BetChange]: [334000, 32.08616780045759],
  [ISongs.SFX_UI_Close]: [336000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [338000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [340000, 151.76870748297233],
  [ISongs.SFX_UI_MaxBet]: [342000, 122.92517006801518],
  [ISongs.SFX_UI_MenuOpen]: [344000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [346000, 249.59183673468033],
  [ISongs.SFX_UI_SpinStop]: [348000, 151.02040816327644],
  [ISongs.SFX_WIN_BIG]: [350000, 1622.3356009070358],
  [ISongs.SFX_WIN_EPIC]: [353000, 1862.2675736961583],
  [ISongs.SFX_WIN_GREAT]: [356000, 2118.390022675726],
  [ISongs.SFX_WIN_MEGA]: [360000, 1696.1678004535088],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BGM_BG_Base_Loop]: 0.2,
  [ISongs.BGM_BG_Melo_Loop]: 0.175,
  [ISongs.BGM_HCZ1_Loop]: 0.2,
  [ISongs.BGM_HCZ2_Loop]: 0.2,
  [ISongs.BGM_FS_Loop]: 0.2,
  [ISongs.TotalWinBanner]: 0.3,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  [ISongs.Win_Loop]: 0.3,
  [ISongs.Win_End]: 0.3,
  [ISongs.LongSpin]: 0.3,
  [ISongs.BonusStop1]: 0.5,
  [ISongs.BonusStop2]: 0.5,
  [ISongs.BonusStop3]: 0.5,
  [ISongs.CherryStop1]: 0.5,
  [ISongs.CherryStop2]: 0.5,
  [ISongs.CherryStop3]: 0.5,
  [ISongs.FSStopx1]: 0.3,
  [ISongs.FSStopx2]: 0.3,
  [ISongs.FSStopx3]: 0.3,
  [ISongs.FSStopx5]: 0.3,
  [ISongs.FSTrigger]: 0.3,
  [ISongs.Quin]: 0.3,
  [ISongs.QQQ]: 0.3,
  [ISongs.Kana]: 0.5,
  [ISongs.SFX_UI_AutoSpin]: 0.3,
  [ISongs.SFX_UI_BetChange]: 0.3,
  [ISongs.SFX_UI_Close]: 0.3,
  [ISongs.SFX_UI_General]: 0.3,
  [ISongs.SFX_UI_Hover]: 0.3,
  [ISongs.SFX_UI_MaxBet]: 0.3,
  [ISongs.SFX_UI_MenuOpen]: 0.3,
  [ISongs.SFX_UI_SpinStart]: 0.3,
  [ISongs.SFX_UI_SpinStop]: 0.3,
  [ISongs.SFX_WIN_BIG]: 0.3,
  [ISongs.SFX_WIN_EPIC]: 0.3,
  [ISongs.SFX_WIN_GREAT]: 0.3,
  [ISongs.SFX_WIN_MEGA]: 0.3,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
