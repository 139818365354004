import AudioHowl from '@phoenix7dev/play-music';
import * as PIXI from 'pixi.js';

import { ISongs } from '../../config';
import { EventTypes, GameMode, GirlLampType } from '../../global.d';
import { isFreeSpinsMode, isHighChanceMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import { BgSkin } from '../background/background';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

class GirlView extends ViewContainer {
  private girl: SpineAnimation;

  private currentAnim: GirlLampType = GirlLampType.IDLE;

  constructor(skin: BgSkin = 'daytime') {
    super();

    this.girl = this.initGirl();

    this.addChild(this.girl.getSpine());

    this.setSkin(skin);

    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.onChangeMode.bind(this),
    );
    eventManager.addListener(
      EventTypes.MANUAL_CHANGE_BACKGROUND,
      this.onChangeMode.bind(this),
    );

    eventManager.addListener(
      EventTypes.GIRL_LAMP_ANIMATION,
      (girlLampPtn: GirlLampType) => {
        this.girlLamp(girlLampPtn);
      },
    );
  }

  public setSkin(skinName: BgSkin) {
    this.girl.setSkin(skinName);
  }

  private initGirl(): SpineAnimation {
    const girl = new SpineAnimation(
      {},
      PIXI.Loader.shared.resources.ryukyucostume.spineData,
    );
    girl.setAnimation(GirlLampType.IDLE, true);
    girl.spine.stateData.defaultMix = 0;

    return girl;
  }

  private girlLamp(type: GirlLampType): void {
    if (this.currentAnim !== type) {
      this.currentAnim = type;
      this.girl.setAnimation(type, true);

      if (type === GirlLampType.IDLE_LIGHT) {
        AudioHowl.play({ type: ISongs.Kana });
      }
    }
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    const { mode, background } = settings;
    if (isFreeSpinsMode(mode)) {
      this.setSkin('night');
    } else if (isHighChanceMode(mode)) {
      this.setSkin(background ?? 'dusk');
    } else {
      this.setSkin('daytime');
    }

    if (this.currentAnim === GirlLampType.IDLE_LIGHT) {
      this.girlLamp(GirlLampType.IDLE);
    }
  }
}

export default GirlView;
