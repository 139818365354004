/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef } from 'react';

import {
  EventTypes,
  GameMode,
  ISettledBet,
  IUserBalance,
  reelSets,
} from '../../global.d';
import {
  setBetAmount,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setCurrentHCM,
  setFreeRoundBonus,
  setGameMode,
  setIsErrorMessage,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsTimeoutErrorMessage,
  setNextResult,
  setPrevReelsPosition,
  setReelSetId,
  setReservedHCM,
  setSlotConfig,
  setUserLastNotFreeSpinsBetResult,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import { IConfig, ISlotConfig } from '../../gql/d';
import {
  configGql,
  getSlotGql,
  getUserGql,
  ReelSetType,
} from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { ISlotData } from '../../slotMachine/d';
import {
  findSubstituteCoinAmount,
  getSpinResult3x3,
  isBaseGameMode,
  isFreeSpinsMode,
  isHighChanceMode,
  wrap,
} from '../../utils';
import { IPixiViewParentNode } from './d';
import styles from './slotMachineLayout.module.scss';
import { FreeRoundsPopup } from '../../slotMachine/freeRoundBonus/freeRoundsPopup';
import { FreeRoundBonus } from '../../slotMachine/freeRoundBonus/freeRoundBonus';

const SlotMachineLayout: React.FC = () => {
  const { data: clientData } = useQuery<IConfig>(configGql);
  const { isMiniPayTable } = clientData!;
  const { id } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const slotMachine = useRef<SlotMachine | null>(null);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);

  const { data } = useQuery<{ slot: ISlotData }, { input: { id: string } }>(
    getSlotGql,
    {
      variables: { input: { id } },
      onCompleted({ slot }) {
        const lines = slot.lines.map((_, index) => index);
        setSlotConfig({
          ...setSlotConfig(),
          clientSettings: slot.clientSettings,
          icons: slot.icons,
          reels: slot.reels,
          winLines: slot.lines,
          lines,
          lineSet: slot.lineSets[0],
        });
        // todo add logic to pick gamemode and reelsetid
        setReelSetId(
          slot.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)
            ?.id || reelSets[GameMode.REGULAR],
        );
        setGameMode(GameMode.REGULAR);

        let coinValue;
        let coinAmount;
        if (setBrokenGame()) {
          const currentBonus = setCurrentBonus();
          coinValue = currentBonus.coinValue;
          coinAmount = currentBonus.coinAmount;

          const afterBonusModeCoinAmount = findSubstituteCoinAmount(
            coinAmount,
            setSlotConfig().clientSettings.coinAmounts.default,
          );
          if (coinAmount != afterBonusModeCoinAmount) {
            const hcms = setReservedHCM();
            if (afterBonusModeCoinAmount in hcms) {
              setCurrentHCM(hcms[afterBonusModeCoinAmount]);
            }
          }
        } else {
          const lastBetCoinAmount = setUserLastBetResult().id
            ? setUserLastBetResult().coinAmount
            : 1;
          coinAmount = findSubstituteCoinAmount(
            lastBetCoinAmount,
            slot.clientSettings.coinAmounts.default,
          );
          coinValue = slot.clientSettings.coinValues.find(
            (elem) => elem.code === userData?.user.balance.currency,
          )?.variants[0];

          const hcms = setReservedHCM();
          if (coinAmount in hcms) {
            setCurrentHCM(hcms[coinAmount]);
          }
        }
        setCoinValue(coinValue);
        setCoinAmount(coinAmount);
        setWinAmount(setUserLastBetResult().result.winCoinAmount);
        setBetAmount(coinAmount * slot.lineSets[0].coinAmountMultiplier);
        SlotMachine.initSlotMachine(
          slot,
          wrap(setIsSpinInProgress, false),
          wrap(setIsSlotBusy, false),
        );

        // start frb
        if (setFreeRoundBonus().isActive) {
          new FreeRoundBonus();
        }

        // Overriding these methods to avoid certified files.
        SlotMachine.getInstance().setResult = (result: ISettledBet) => {
          const spinResult = getSpinResult3x3({
            reelPositions: result.bet.result.reelPositions.slice(0, 3),
            reelSet: setSlotConfig().reels.find(
              (reelSet) => reelSet.id === result.bet.reelSet.id,
            )!,
            icons: setSlotConfig().icons,
          });
          const newResult = {
            ...result,
            bet: {
              ...result.bet,
              result: {
                ...result.bet.result,
                spinResult,
              },
            },
          };
          if (
            isBaseGameMode(setGameMode()) ||
            isHighChanceMode(setGameMode())
          ) {
            setUserLastNotFreeSpinsBetResult(newResult.bet);
          }
          setUserLastBetResult(newResult.bet);
          setPrevReelsPosition(newResult.bet.result.reelPositions.slice(0, 3));
          SlotMachine.getInstance().nextResult = newResult;
          setNextResult(newResult);

          // Update rounds of frb
          if (setFreeRoundBonus().isActive && !isFreeSpinsMode(setGameMode())) {
            setFreeRoundBonus({
              ...setFreeRoundBonus(),
              currentRound: setFreeRoundBonus().currentRound + 1,
            });
            eventManager.emit(
              EventTypes.UPDATE_FREE_ROUNDS_LEFT,
              setFreeRoundBonus().rounds - setFreeRoundBonus().currentRound,
            );
          }

          if (!isFreeSpinsMode(setGameMode())) {
            eventManager.emit(
              EventTypes.UPDATE_USER_BALANCE,
              SlotMachine.getInstance().nextResult?.balance.placed,
            );
          }
        };
        SlotMachine.getInstance().onSpinStop = () => {
          if (setIsErrorMessage()) {
            SlotMachine.getInstance().state = SlotMachineState.IDLE;
            eventManager.emit(
              EventTypes.DISABLE_PAY_TABLE,
              !isFreeSpinsMode(setGameMode()),
            );
            eventManager.emit(
              EventTypes.SLOT_MACHINE_STATE_CHANGE,
              SlotMachineState.IDLE,
            );
            setIsSpinInProgress(false);
            setIsErrorMessage(false);
            // eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
          } else {
            wrap(setIsSpinInProgress, false)();
            SlotMachine.getInstance().miniPayTableContainer.setSpinResult(
              SlotMachine.getInstance().nextResult!.bet.result.spinResult,
            );
            SlotMachine.getInstance().state = SlotMachineState.JINGLE;
            eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
            eventManager.emit(
              EventTypes.SLOT_MACHINE_STATE_CHANGE,
              SlotMachineState.JINGLE,
            );
          }
        };

        SlotMachine.getInstance().throwTimeoutError = () => {
          if (!setIsRevokeThrowingError()) {
            setIsTimeoutErrorMessage(true);
            setIsErrorMessage(true);
          }
          eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
          eventManager.emit(EventTypes.THROW_ERROR);
        };
        slotMachine.current = SlotMachine.getInstance();
      },
    },
  );

  const resize = (application: PIXI.Application) => (): void => {
    const parent = application.view.parentNode as IPixiViewParentNode;
    const width = parent?.clientWidth;
    const height = parent?.clientHeight;
    eventManager.emit(EventTypes.RESIZE, width, height);
  };

  useEffect((): (() => void) | undefined => {
    if (slotMachine.current) {
      const application = slotMachine.current.getApplication();
      pixiContainerRef.current?.appendChild(
        slotMachine.current.getApplication().view,
      );
      resize(application)();
      window.addEventListener(EventTypes.RESIZE, resize(application));
      return () =>
        window.removeEventListener(EventTypes.RESIZE, resize(application));
    }
    return undefined;
  }, [!!data]);

  useEffect(() => {
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isMiniPayTable);
    if (!isMiniPayTable) {
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
    }
  }, [isMiniPayTable]);

  return <div className={styles.canvasWrapper} ref={pixiContainerRef} />;
};

export default React.memo(SlotMachineLayout);
