/* eslint-disable react-hooks/exhaustive-deps */
import {
  ApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import AudioHowl from '@phoenix7dev/play-music';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';

import { config, ISongs } from '../../config';
import {
  BonusStatus,
  EventTypes,
  GameMode,
  GraphQLErrorsType,
  ISettledBet,
  IUserBalance,
  lineSets,
  UserBonus,
} from '../../global.d';
import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentHCM,
  setFreeRoundBonus,
  setGameHistory,
  setGameMode,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsInTransition,
  setIsOpenedMessageBanner,
  setIsRevokeThrowingError,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setReplayBet,
  setReplayFreeSpinBets,
  setReplaySpinCount,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setWinAmount,
} from '../../gql/cache';
import { IConfig, ISlotConfig, IStressful } from '../../gql/d';
import { activateUserBonus, placeBetGql } from '../../gql/mutation';
import {
  configGql,
  getAutoSpinsGql,
  getBetAmountGql,
  getGameModeGql,
  getProgressGql,
  getUserBonuses,
  getUserGql,
  isStoppedGql,
  replayBetGql,
  stressfulGql,
} from '../../gql/query';
import SlotMachine from '../../slotMachine';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { eventManager, SlotMachineState } from '../../slotMachine/config';
import {
  canPressSpin,
  formatNumber,
  isFreeSpinsMode,
  isHighChanceMode,
  saveReelPosition,
  showCurrency,
} from '../../utils';
import { IPlaceBetInput } from './d';

const handleChangeRestriction = (): void => {
  BgmControl.handleChangeRestriction();
};
const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { data: stressful } = useQuery<{ stressful: IStressful }>(stressfulGql);
  const { id: slotId, lineSet } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(
    isStoppedGql,
  );

  const { data: gameModeData } = useQuery<{
    gameMode: GameMode;
  }>(getGameModeGql);
  const { gameMode } = gameModeData!;
  const balanceAmount = userData?.user.balance.amount || 0;
  const winThreeTimes = useReactiveVar<boolean[]>(setGameHistory);

  const { progress } = dataProgress!;

  const betCompleteCallback = (
    placeBet: ISettledBet,
    client: ApolloClient<unknown>,
  ): void => {
    eventManager.emit(EventTypes.PLACE_BET_COMPLETED);
    const clonnedPlaceBet: ISettledBet = JSON.parse(JSON.stringify(placeBet));
    if (clonnedPlaceBet.rewards) {
      const replayBonusIndex = clonnedPlaceBet?.rewards.findIndex(
        (reward) => reward.__typename === 'ReplayBonusReward',
      );
      for (let i = replayBonusIndex; i < clonnedPlaceBet.rewards.length; i++) {
        if (i > -1) {
          clonnedPlaceBet.rewards[i].__typename = 'BetBonusReward';
        }
      }
    }

    client.writeQuery({
      query: getUserGql,
      data: {
        ...userData,
        user: {
          ...userData?.user,
          balance: clonnedPlaceBet.balance.placed,
        },
      },
    });
    SlotMachine.getInstance().setResult(clonnedPlaceBet);
    if (SlotMachine.getInstance().isStopped) {
      SlotMachine.getInstance().spin(isTurboSpin);
    }
    const callBack = () => {
      const win = placeBet.bet.result.winCoinAmount;
      const lastThreeSpins = [...setGameHistory().slice(1), !!win];
      setGameHistory(lastThreeSpins);
      client.writeQuery({
        query: getUserGql,
        data: {
          ...userData,
          user: {
            ...userData?.user,
            balance: placeBet.balance.settled,
          },
        },
      });
      if (!setReplayBet()) {
        placeBet.bet.result.reelPositions.push(setGameMode());
        saveReelPosition(placeBet.bet.result.reelPositions);
      }
      if (setReplayFreeSpinBets().length === 1) setGameMode(GameMode.REGULAR);
    };
    SlotMachine.getInstance().setStopCallback(callBack.bind(this));
  };

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins, autoSpinsLeft } = autoSpins!;

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isFreeSpinsMode(setGameMode()) &&
    !setCurrentBonus().isActive &&
    setCurrentBonus().rounds === setCurrentBonus().currentRound;

  const [activate] = useMutation<
    { status: { status: BonusStatus } },
    { input: { id: string } }
  >(activateUserBonus, { fetchPolicy: 'network-only' });

  const [fnGet, { client }] = useMutation<
    { placeBet: ISettledBet },
    { input: IPlaceBetInput }
  >(placeBetGql, {
    onError(error) {
      eventManager.emit(EventTypes.PLACE_BET_COMPLETED);

      if (
        error.graphQLErrors.some(
          (err) =>
            err.extensions?.type === GraphQLErrorsType.INSUFFICIENT_FUNDS,
        )
      ) {
        eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
        if (setIsAutoSpins()) setIsAutoSpins(false);
      }
    },

    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
  });

  const [getReplayBet] = useLazyQuery<
    { placeBet: ISettledBet },
    { betId: string }
  >(replayBetGql, {
    async onCompleted({ placeBet }) {
      setReplaySpinCount(setReplaySpinCount() + 1);
      betCompleteCallback(placeBet, client);
    },
  });

  const setPlayBgm = () => {
    setIsShowSoundToast(false);
    BgmControl.playBgm();
  };

  const hcmSpin = useCallback(async () => {
    const hcm = setCurrentHCM();

    if (hcm.isActive && hcm.currentRound < hcm.totalRounds) {
      if (!hcm.activateChecked) {
        const inActiveBonuses = await client.query<{
          userBonuses: UserBonus[];
        }>({
          query: getUserBonuses,
          variables: {
            input: {
              slotId: setSlotConfig().id,
              status: BonusStatus.INACTIVE,
            },
          },
          fetchPolicy: 'network-only',
        });

        if (inActiveBonuses && inActiveBonuses.data.userBonuses.length > 0) {
          hcm.bonusList = hcm.bonusList.map((ub) => {
            return inActiveBonuses.data?.userBonuses.some(
              (inactiveUb) => inactiveUb.id === ub.id,
            )
              ? { ...ub, status: BonusStatus.INACTIVE }
              : ub;
          });
        }

        hcm.activateChecked = true;
        setCurrentHCM(hcm);
      }

      const bonus = hcm.bonusList[0];

      if (bonus.status === BonusStatus.INACTIVE) {
        await activate({ variables: { input: { id: bonus.id } } });
      }
      if (setReplayBet()) {
        getReplayBet({
          variables: { betId: setReplayBet() },
        });
      } else {
        await fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
              lineSetId: lineSets[setGameMode()],
              userBonusId: bonus.id,
            },
          },
        });
      }
    }
  }, [fnGet, slotId, lineSet.id, activate]);

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
      const spinState = SlotMachine.getInstance().state;
      SlotMachine.getInstance().spin(isTurboSpin);
      if (spinState === SlotMachineState.IDLE) {
        if (isFreeSpinsMode(setGameMode())) return;
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber(setCurrency(), 0, showCurrency(setCurrency())),
        );
        setWinAmount(0);
        setLastRegularWinAmount(0);
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        if (setReplayBet()) {
          const replayFreeSpins = setReplayFreeSpinBets();
          const replaySpinCount = setReplaySpinCount() - 1;
          if (
            replaySpinCount != -1 &&
            replayFreeSpins.length >= replaySpinCount
          ) {
            setReplayBet(replayFreeSpins[replaySpinCount]);
          }
          if (isHighChanceMode(setGameMode())) {
            hcmSpin();
          } else {
            getReplayBet({
              variables: { betId: setReplayBet() },
            });
          }
        } else {
          if (isHighChanceMode(setGameMode())) {
            hcmSpin();
          } else {
            const input = {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
              lineSetId: lineSets[GameMode.REGULAR],
            } as IPlaceBetInput;
            const freeRoundBonus =
              setFreeRoundBonus() && setFreeRoundBonus().isActive;
            if (freeRoundBonus) {
              // TODO(FRB) Be sure to turn it ON when pushing to master
              input.userBonusId = setFreeRoundBonus().id;
            }
            fnGet({
              variables: {
                input,
              },
            });
          }
        }
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioHowl.stop({ type: ISongs.SFX_UI_Close });
        AudioHowl.play({ type: ISongs.SFX_UI_SpinStart });
        if (AudioHowl.isRestricted) {
          handleChangeRestriction();
        }
      } else {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
      }

      if (AudioHowl.isRestricted) {
        AudioHowl.changeRestriction(
          false,
          [],
          () => setIsSoundLoading(true),
          () => setPlayBgm(),
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [dataBet?.betAmount, fnGet, lineSet, slotId],
  );
  useEffect(() => {
    const freeSpin = () => {
      if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
      SlotMachine.getInstance().spin(isTurboSpin);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      if (setReplayBet()) {
        const replayFreeSpins = setReplayFreeSpinBets();
        const replaySpinCount = setReplaySpinCount() - 1;
        if (replayFreeSpins.length >= replaySpinCount) {
          setReplayBet(replayFreeSpins[replaySpinCount]);
        }

        getReplayBet({
          variables: { betId: setReplayBet() },
        });
      } else {
        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
              lineSetId: lineSets[GameMode.FREE_SPINS],
              userBonusId: setCurrentBonus().id,
            },
          },
        });
      }
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioHowl.play({ type: ISongs.SFX_UI_SpinStart });
    };
    eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    return () => {
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins() && !stressful?.stressful.show) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus = setIsStopOnFeatureWin() && setCurrentBonus().isActive;

      const stopOnWin =
        setIsStopOnAnyWin() &&
        (setLastRegularWinAmount() > 0 || setCurrentBonus().isActive);

      const stopOnWinExceeds =
        setIsStopOnWinExceeds() &&
        setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <=
          balanceAmount - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <=
          setAutoSpinsStartBalance() - balanceAmount;

      if (
        autoSpinsLeft ||
        bonus ||
        stopOnWin ||
        stopOnWinExceeds ||
        balanceIncrease ||
        balanceDecrease
      ) {
        setIsAutoSpins(false);
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin, stressful?.stressful]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (
      (setGameMode() === GameMode.REGULAR || isHighChanceMode(setGameMode())) &&
      setIsFreeSpinsWin()
    ) {
      return;
    }

    if (setIsOpenedMessageBanner()) {
      eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
      return;
    }

    if (isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
    } else {
      onSpin(isTurboSpin);
    }
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32 && !stressful?.stressful.show) {
        e.preventDefault();
        e.stopPropagation();

        if (!data?.isEnabledSpaceSpin) {
          return;
        }

        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            bonusCurrentRound: setCurrentBonus()?.currentRound || 0,
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isOpenedMessageBanner: setIsOpenedMessageBanner(),
            isInTransition: setIsInTransition(),
          })
        ) {
          return;
        }

        if (setIsOpenedMessageBanner()) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
          return;
        }

        if (isAutoSpins) {
          checkAutoSpinSettings();
          return;
        }
        if (progress?.wasLoaded && !isFreeSpinModeOnTotalWinBannerStep()) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      gameMode,
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      data?.isEnabledSpaceSpin,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful?.stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {
    const play = _.reduce(winThreeTimes.slice(2), (acc, item) => acc && item);
    const stop = _.reduce(winThreeTimes, (acc, item) => acc || item);
    if (play) {
      BgmControl.fadeInMelo(500);
    }

    if (!stop) {
      BgmControl.fadeOutMelo(3000);
    }
  }, [winThreeTimes]);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped) {
      id = setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount()
          ? 0
          : config.autoplay.timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [
    isAutoSpins,
    isFreeSpinsWin,
    checkAutoSpinSettings,
    dataSlotStopped?.isSlotStopped,
  ]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return null;
};

export default Spin;
