import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import type React from 'react';

const ErrorBoundary: React.FC = (props) => {
  const { children } = props;
  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <>
      {isProduction ? (
        <SentryErrorBoundary>{children}</SentryErrorBoundary>
      ) : (
        children
      )}
    </>
  );
};

export default ErrorBoundary;
