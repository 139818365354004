import { GameMode } from '../global';
import { BgSkin } from '../slotMachine/background/background';
import {
  backgroundSelectionTable,
  girlLampTable,
  nullPatlampTable,
  patlampTable,
} from './table';
import {
  getModeId,
  getRandomArrayFromUUID,
  getRandomFromUUID,
  getResultFromTable,
} from './util';

export function getHighChanceModeBackground(
  uuid: string,
  mode: GameMode,
): BgSkin {
  const modeId = getModeId(mode);
  const rand = getRandomFromUUID(uuid, 100, 2);
  const result = getResultFromTable(backgroundSelectionTable[modeId], rand);
  return result ? 'night' : 'dusk';
}

export enum QuinTiming {
  NOTHING,
  START,
  REEL1_STOP,
  REEL2_STOP,
  QQQ,
}

export function getPatlampTiming(uuid: string, spinNum: number): QuinTiming {
  const rand = getRandomFromUUID(uuid, 100);
  if (spinNum === 10) {
    return getResultFromTable(patlampTable[0], rand);
  }
  if (spinNum === 5) {
    return getResultFromTable(patlampTable[1], rand);
  }
  return QuinTiming.NOTHING;
}

export function getNullPatlamp(uuid: string, hasCZ1Reel: boolean): boolean {
  if (!hasCZ1Reel) return false;
  const rand = getRandomFromUUID(uuid, 100, 2);
  return getResultFromTable(nullPatlampTable, rand) === 1;
}

export function getGirlLampStory(uuid: string, hasCZ1Reel: boolean): boolean[] {
  if (!hasCZ1Reel) {
    return [false, false, false, false, false];
  }
  const rands = getRandomArrayFromUUID(
    uuid,
    [100, 100, 100, 100, 100, 100],
  ).slice(1);

  let prev = false;
  return rands.map((rand) => {
    prev = prev || getResultFromTable(girlLampTable, rand) === 1;
    return prev;
  });
}
