import Tween from './tween';

export enum TweenProperties {
  POSITION = 'position',
  X = 'x',
  Y = 'y',
  WIN_VALUE = 'winValue',
  ROTATION = 'rotation',
  ALPHA = 'alpha',
}

export interface ITweenObject {
  [TweenProperties.POSITION]?: number | PIXI.ObservablePoint;
  [TweenProperties.X]?: number;
  [TweenProperties.Y]?: number;
  [TweenProperties.WIN_VALUE]?: number;
  [TweenProperties.ROTATION]?: number;
  [TweenProperties.ALPHA]?: number;
}

export interface ITween extends IAnimation {
  object: ITweenObject;
  property: TweenProperties;
  propertyBeginValue: number;
  target: number;
}

export interface IAnimation {
  startTime?: number;
  change?: (() => void)[];
  complete?: (() => void)[];
  starts?: (() => void)[];
  skips?: (() => void)[];
  easing?: (n: number) => number;
  duration?: number;
  delay?: number;
  isLoop?: boolean;
  ended?: boolean;
  update?: ((n: number) => void) | null;
}

export interface ISpinAnimation extends IAnimation {
  startingAnimation: Tween;
  fakeRollingAnimation: Tween;
  rollingAnimation: Tween;
  endingAnimation: Tween;
}
