import { useQuery } from '@apollo/client';
import AudioHowl from '@phoenix7dev/play-music';
import { Button, Popup } from '@phoenix7dev/shared-components/';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ISongs } from '../../config';
import { ModalOpeningTypes } from '../../global.d';
import {
  setIsFirstBetChangeModal,
  setIsModalOpeningInProgress,
  setIsOpenBetSettingsModal,
} from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import BetSettings from '.';
import styles from './betSettings.module.scss';

const FirstPopup: React.FC = () => {
  const { t } = useTranslation();
  const handleClosePopup = (directClickToCloseIcon = false) => {
    if (
      setIsModalOpeningInProgress() &&
      setIsModalOpeningInProgress() !== ModalOpeningTypes.BET_SETTINGS &&
      !directClickToCloseIcon
    )
      return;

    setTimeout(() => {
      if (
        !AudioHowl.isPlaying(ISongs.SFX_UI_MenuOpen) &&
        !AudioHowl.isPlaying(ISongs.SFX_UI_SpinStart)
      ) {
        AudioHowl.play({ type: ISongs.SFX_UI_Close });
      }
    }, 0);
    setIsFirstBetChangeModal(false);
  };

  return (
    <div>
      <Button
        intent="close"
        className="popup__close"
        onClick={() => handleClosePopup(true)}
      />
      <div className={styles.desc}>{t('firstTimeBetChange_1')}</div>
      <div className={styles.desc}>{t('firstTimeBetChange_2')}</div>
      <div className={styles.desc}>{t('firstTimeBetChange_3')}</div>
    </div>
  );
};

const BetSettingsMenu: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();
  const { data: configData } = useQuery<IConfig>(configGql);
  const { isOpenBetSettingsModal, isFirstBetChangeModal } = configData!;
  const [showFirst, setShowFirst] = useState(false);

  useEffect(() => {
    if (isOpenBetSettingsModal) {
      AudioHowl.stop({ type: ISongs.SFX_UI_Close });
      AudioHowl.play({ type: ISongs.SFX_UI_MenuOpen });
    }
    setShowPopup(isOpenBetSettingsModal);
    setShowFirst(isFirstBetChangeModal);
  }, [isOpenBetSettingsModal, isFirstBetChangeModal]);

  useEffect(() => {
    if (showPopup && configData?.isSpinInProgress) {
      setShowPopup(false);
      setIsOpenBetSettingsModal(false);
      if (showFirst) {
        setIsFirstBetChangeModal(false);
      }
    }
  }, [configData?.isSpinInProgress, showPopup, showFirst]);

  const handleClose = (directClickToCloseIcon = false) => {
    if (
      setIsModalOpeningInProgress() &&
      setIsModalOpeningInProgress() !== ModalOpeningTypes.BET_SETTINGS &&
      !directClickToCloseIcon
    ) {
      setIsFirstBetChangeModal(false);
      return;
    }

    setTimeout(() => {
      if (
        !AudioHowl.isPlaying(ISongs.SFX_UI_MenuOpen) &&
        !AudioHowl.isPlaying(ISongs.SFX_UI_SpinStart)
      ) {
        AudioHowl.play({ type: ISongs.SFX_UI_Close });
      }
    }, 0);
    setIsOpenBetSettingsModal(false);
    if (showFirst) {
      setIsFirstBetChangeModal(false);
    }
  };

  return (
    <Popup
      id="popup"
      className="popup"
      showNow={showPopup}
      setShowNow={() => handleClose()}
    >
      {showFirst && (
        <>
          <div className="popup__title"></div>
          <FirstPopup />
        </>
      )}
      {!showFirst && (
        <>
          <div className="popup__title">{t('betSettings')}</div>
          <Button
            intent="close"
            className="popup__close"
            onClick={() => handleClose(true)}
          />
          <BetSettings />
        </>
      )}
    </Popup>
  );
};

export default BetSettingsMenu;
