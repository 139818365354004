import { TextStyleSet } from 'pixi-multistyle-text';

import {
  CAROUSEL_GAME_FLOW_STROKE_COLOR,
  CAROUSEL_GAME_FLOW_TEXT_COLOR,
  CAROUSEL_LETTER_SIZE,
  CAROUSEL_LETTERSCALE_SIZE,
  CAROUSEL_STROKE_COLOR,
  CAROUSEL_TEXT_COLOR,
  CAROUSEL_TEXT_DIVIDE_COLOR,
  CAROUSEL_TEXT_DIVIDE_STROKE_COLOR,
  CAROUSEL_TEXT_LINE_HEIGHT,
  CAROUSEL_TEXT_SHADOW,
  CAROUSEL_TEXT_WORD_WRAP_WIDTH,
} from './config';

const GAME_FLOW_PAGE = 3;

const IntroStyles = (
  page: number,
  fontSize: number,
  width: number,
): TextStyleSet => {
  const introStylesInfo: TextStyleSet = {
    default: {
      fill:
        page === GAME_FLOW_PAGE
          ? CAROUSEL_GAME_FLOW_TEXT_COLOR
          : CAROUSEL_TEXT_COLOR,
      stroke:
        page === GAME_FLOW_PAGE
          ? CAROUSEL_GAME_FLOW_STROKE_COLOR
          : CAROUSEL_STROKE_COLOR,
      strokeThickness: 5,
      fontSize: fontSize,
      lineHeight: CAROUSEL_TEXT_LINE_HEIGHT,
      letterSpacing: 0,
      wordWrap: false,
      wordWrapWidth: CAROUSEL_TEXT_WORD_WRAP_WIDTH || width,
      fontWeight: '700',
      align: 'center',
      dropShadow: false,
      fontFamily: 'Arial',
      fontStyle: 'normal',
    },
    span: {
      fill: CAROUSEL_TEXT_DIVIDE_COLOR,
      stroke: CAROUSEL_TEXT_DIVIDE_STROKE_COLOR,
    },
    scaleLetter: {
      fontSize: CAROUSEL_LETTERSCALE_SIZE,
    },
  };
  return introStylesInfo;
};

export default IntroStyles;
