import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { isFreeSpinsMode } from '../../../utils';
import ViewContainer from '../../components/container';
import {
  eventManager,
  SLOTS_BACKGROUND_HEIGHT,
  SLOTS_BACKGROUND_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
} from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  private background: PIXI.Graphics;

  private light: PIXI.Sprite;

  private line: PIXI.Sprite;

  constructor() {
    super();

    this.background = this.initBackground();
    this.light = this.initLight();
    this.line = this.initLine();

    this.addChild(this.light, this.line, this.background);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(
      EventTypes.MANUAL_CHANGE_BACKGROUND,
      this.onModeChange.bind(this),
    );
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isFreeSpinsMode(settings.mode)) {
      this.light.visible = true;
      this.line.visible = true;
      this.background.visible = false;
    } else {
      this.light.visible = false;
      this.line.visible = false;
      this.background.visible = true;
    }
  }

  private initBackground(): PIXI.Graphics {
    const rect = new PIXI.Graphics()
      .beginFill(0)
      .drawRect(
        0,
        SLOTS_CONTAINER_HEIGHT - SLOTS_BACKGROUND_HEIGHT,
        SLOTS_BACKGROUND_WIDTH,
        SLOTS_BACKGROUND_HEIGHT,
      )
      .endFill();
    rect.alpha = 0.3;
    return rect;
  }

  private initLight(): PIXI.Sprite {
    const light = new PIXI.Sprite(
      PIXI.Texture.from(ResourceTypes.freeSpinLight),
    );

    light.anchor.set(0.5);
    light.position.set(SLOTS_BACKGROUND_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    light.blendMode = PIXI.BLEND_MODES.ADD;
    light.visible = false;

    return light;
  }

  private initLine(): PIXI.Sprite {
    const line = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.freeSpinLine));

    line.anchor.set(0.5);
    line.position.set(SLOTS_BACKGROUND_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    line.blendMode = PIXI.BLEND_MODES.ADD;
    line.visible = false;

    return line;
  }
}

export default ReelsBackgroundContainer;
