import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import i18n from '../../i18next';
import { isMobile } from 'mobile-device-detect';
import { updateTextScale } from '../../utils/utils';
import SlotMachine from '..';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { eventManager } from '../config';
import {
  HCM_MESSAGE_DELAY_DURATION,
  HCM_MESSAGE_FADEOUT_DURATION,
  HCM_MESSAGE_POSITION_X,
  HCM_MESSAGE_POSITION_Y,
  hcmMessageStyle as hcmMessageStyle,
} from './config';

class HcmMessage extends ViewContainer {
  private text: PIXI.Text;

  private animation?: AnimationChain;

  constructor() {
    super();

    this.text = new PIXI.Text(i18n.t('highChanceMode'), hcmMessageStyle);
    this.text.resolution = 1;
    this.text.anchor.set(0.5);
    this.text.x = HCM_MESSAGE_POSITION_X;
    this.text.y = HCM_MESSAGE_POSITION_Y;
    updateTextScale(this.text, isMobile ? 1200 : 1200, isMobile ? 350 : 350);
    this.startRetrigger();
    eventManager.once(EventTypes.HANDLE_SKIP_HCM_MESSAGE, this.skip.bind(this));
  }

  private startRetrigger(): void {
    this.addChild(this.text);

    this.animation = new AnimationChain();
    const delay = Tween.createDelayAnimation(HCM_MESSAGE_DELAY_DURATION);

    this.animation.appendAnimation(delay);

    const fadeOut = new Tween({
      object: this.text,
      target: 0,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      duration: HCM_MESSAGE_FADEOUT_DURATION,
    });
    this.animation.appendAnimation(fadeOut);

    this.animation.addOnComplete(() => {
      this.handleDestroy();
    });

    this.animation.addOnSkip(() => {
      this.handleDestroy();
    });

    this.animation.start();
  }

  private skip() {
    this.animation?.skip();
  }

  private handleDestroy(): void {
    eventManager.removeAllListeners(EventTypes.HANDLE_SKIP_HCM_MESSAGE);
    SlotMachine.getInstance().gameView.removeChild(this);
    this.destroy({ children: true });
  }
}

export default HcmMessage;
