import * as PIXI from 'pixi.js';

import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { fireworksData } from './fireworksData';

class FireWorks extends ViewContainer {
  private fireworks: SpineAnimation[] = [];

  private animation?: AnimationGroup;

  private counter = 0;

  public start(): void {
    if (this.animation) {
      this.animation.start();
      return;
    }

    this.animation = new AnimationGroup();

    fireworksData.forEach((fw) => {
      const chain = new AnimationChain();
      chain.appendAnimation(Tween.createDelayAnimation(fw.delay));
      const loop = new AnimationChain({ isLoop: true });
      const firework = new SpineAnimation(
        {},
        PIXI.Loader.shared.resources.fireworks.spineData,
      );

      firework.getSpine().position.set(fw.x, fw.y);
      firework.getSpine().scale.set(0.5);

      this.addChild(firework.spine);

      firework.addOnStart(() => {
        this.counter = (this.counter + 1) % 3;
        if (firework?.spine?.state) {
          firework.setAnimation(
            ['fireworks_a', 'fireworks_b', 'fireworks_c'][this.counter],
            false,
          );
        }
      });

      loop.appendAnimation(firework);
      loop.appendAnimation(Tween.createDelayAnimation(fw.interval));
      chain.appendAnimation(loop);

      this.animation!.addAnimation(chain);
      this.fireworks.push(firework);
    });

    this.animation.start();
  }

  public stop(): void {
    this.animation?.skip();
    this.animation = undefined;

    this.removeChildren();

    this.fireworks.forEach((fw) => {
      fw.spine.state.clearTracks();
      fw.spine.destroy();
    });

    this.fireworks = [];
  }
}

export { FireWorks };
