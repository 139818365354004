import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      gameId: 'Game ID',
      date: 'date',
      // balanceStart: 'Start balance',
      bet: 'bet',
      win: 'win',
      // balanceEnd: 'End Balance',
      // detail: 'Detail',
    },
  },
  specialComboData: [
    {
      combo: [
        ResourceTypes.symbolASeven,
        ResourceTypes.symbolASeven,
        ResourceTypes.symbolASeven,
      ],
      multiplier: 50,
      awardText: 'infoAAAFreeSpins',
    },
    {
      combo: [
        ResourceTypes.symbolASeven,
        ResourceTypes.symbolASeven,
        ResourceTypes.symbolBBar,
      ],
      multiplier: 50,
      awardText: 'infoAABFreeSpins',
    },
  ],
  payTableData: [
    {
      slug: ResourceTypes.symbolASeven,
      combos: [],
    },
    {
      slug: ResourceTypes.symbolBBar,
      combos: [],
    },
    {
      slug: ResourceTypes.symbolCLion,
      combos: [{ pattern: 'x3', multiplier: 100 }],
    },
    {
      slug: ResourceTypes.symbolDWatermelon,
      combos: [{ pattern: 'x3', multiplier: 50 }],
    },
    {
      slug: ResourceTypes.symbolEBell,
      combos: [{ pattern: 'x3', multiplier: 20 }],
    },
    {
      slug: ResourceTypes.symbolFBlue,
      combos: [{ pattern: 'x3', multiplier: 10 }],
    },
    {
      slug: ResourceTypes.symbolGCherry,
      combos: [
        { pattern: 'x3', multiplier: 6 },
        { pattern: 'x2', multiplier: 4 },
        { pattern: 'x1', multiplier: 2 },
      ],
    },
  ],
  payTableDataFeature: [
    {
      slug: ResourceTypes.freeSpinSymbolAGoldenCherry,
      combos: [
        { pattern: 'x3', multiplier: 50 },
        { pattern: 'x2', multiplier: 20 },
        { pattern: 'x1', multiplier: 5 },
      ],
    },
  ],
};

export enum SlotId {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  GC = 'GC',
  BL = 'BL',
}
