import React from 'react';
import { useTranslation } from 'react-i18next';

import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';
import styles from './info.module.scss';

const PayLines: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section>
      <div className={styles.payLines}>
        <h1 className={styles.title}>{t('infoPayLinesTitle')}</h1>
        <p
          className={`${styles.p} ${styles.center}`}
          dangerouslySetInnerHTML={{
            __html: t('infoPayLinesHeader'),
          }}
        />
        <img
          draggable="false"
          src={Resources.getSource(ResourceTypes.infoPaylines)}
          alt="payLines"
        />
      </div>
      <div className={styles.payLinesFeature}>
        <p
          className={`${styles.p} ${styles.center}`}
          dangerouslySetInnerHTML={{
            __html: t('infoPayLinesFeatureHeader'),
          }}
        />
        <img
          draggable="false"
          src={Resources.getSource(ResourceTypes.infoPaylines2)}
          alt="payLines"
        />
      </div>
    </section>
  );
};

export default PayLines;
