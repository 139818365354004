import AudioHowl from '@phoenix7dev/play-music';
import * as PIXI from 'pixi.js';

import { ISongs, mappedAudioSprites } from '../../config';
import {
  BgmSoundTypes,
  EventTypes,
  FreeSpinsTitleProps,
  GameMode,
  MessageFreeSpinsBannerProps,
  MessageWinBannerProps,
} from '../../global.d';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  eventManager,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  GIRL_LANDSCAPE_POSITION_X,
  GIRL_LANDSCAPE_POSITION_Y,
  GIRL_PORTRAIT_POSITION_X,
  GIRL_PORTRAIT_POSITION_Y,
  LINES_NUMBER_CONTAINER_WIDTH,
  SHOW_LINES_NUMBER_CONTAINER,
  SLOTS_BACKGROUND_HEIGHT,
  SLOTS_BACKGROUND_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
} from '../config';
import { IGameContainer } from '../d';
import FreeSpinsPanel from '../freeSpinsPanel/freeSpinsPanel';
import HcmMessage from '../hcmMessage/hcmMessage';
import { MessageFreeSpinsBanner } from '../messageBanner/messageFreeSpinsBanner';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import ReelsFrame from '../reels/frame/reelsFrame';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';
import { ForegroundLamp } from './foregroundLamp';
import GameTitle from './gameTitle';
import GirlView from './girlView';
import GameReplay from './gameReplay';
import { setIsTimeoutErrorMessage } from '../../gql/cache';

window.PIXI = PIXI;

class GameView extends ViewContainer {
  public leftLinesContainer: PIXI.Container | null = null;

  public rightLinesContainer: PIXI.Container | null = null;

  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public slotStopDisplayContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public gameReplay: PIXI.Container;

  public coinsAnimationContainer: PIXI.Container;

  public frame: ReelsFrame;

  public girl: GirlView;

  public foregroundLamp: ForegroundLamp;

  public freeSpinsPanel: FreeSpinsPanel | undefined;

  constructor(props: IGameContainer) {
    super();
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.girl = new GirlView();
    this.addChild(this.girl);
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.x = SHOW_LINES_NUMBER_CONTAINER
      ? LINES_NUMBER_CONTAINER_WIDTH
      : 0;
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;
    this.gameTitle = new GameTitle();
    this.gameReplay = new GameReplay();
    this.foregroundLamp = new ForegroundLamp();
    this.foregroundLamp.pivot.set(
      this.gameTitle.pivot.x,
      this.gameTitle.pivot.y,
    );
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.slotStopDisplayContainer = props.slotStopDisplayContainer;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;

    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(
        0,
        SLOTS_CONTAINER_HEIGHT - SLOTS_BACKGROUND_HEIGHT,
        SLOTS_BACKGROUND_WIDTH,
        SLOTS_BACKGROUND_HEIGHT,
      )
      .endFill();
    this.slotsContainer.addChild(this.slotsContainer.mask);
    this.addChild(this.reelsBackgroundContainer);
    this.addChild(this.slotsContainer);
    this.addChild(this.slotStopDisplayContainer);
    this.frame = new ReelsFrame();
    this.addChild(this.frame);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.gameTitle);
    this.addChild(this.gameReplay);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.foregroundLamp);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);

    eventManager.addListener(
      EventTypes.RESIZE_GAME_CONTAINER,
      this.resize.bind(this),
    );
    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.onModeChange.bind(this),
    );
    eventManager.addListener(
      EventTypes.CREATE_FREE_SPINS_TITLE,
      this.createFreeSpinsTitle.bind(this),
    );
    eventManager.addListener(
      EventTypes.REMOVE_FREE_SPINS_TITLE,
      this.removeFreeSpinsTitle.bind(this),
    );
    eventManager.addListener(
      EventTypes.CREATE_MESSAGE_BANNER,
      this.createFreeSpinsMessage.bind(this),
    );
    eventManager.addListener(
      EventTypes.CREATE_WIN_MESSAGE_BANNER,
      this.createWinMessage.bind(this),
    );
    eventManager.addListener(
      EventTypes.CREATE_HCM_MESSAGE,
      this.createHcmMessage.bind(this),
    );
  }

  private getPositionOfFreeSpinPanelInScene(): number {
    const position = this.children.findIndex(
      (child) => child instanceof FreeSpinsPanel,
    );

    return position !== -1 ? position + 1 : 5;
  }

  private createFreeSpinsMessage(props: MessageFreeSpinsBannerProps): void {
    this.addChild(new MessageFreeSpinsBanner(props).init());
    BgmControl.stopBgm();
    BgmControl.playBgm(BgmSoundTypes.FS);
  }

  private createWinMessage(props: MessageWinBannerProps): void {
    if (setIsTimeoutErrorMessage()) return;
    const totalWinDelay = Tween.createDelayAnimation(
      mappedAudioSprites[ISongs.TotalWinBanner].duration,
    );
    totalWinDelay.addOnStart(() => {
      BgmControl.stopBgm();
    });
    totalWinDelay.addOnSkip(() => {
      AudioHowl.fadeOut(1000, ISongs.TotalWinBanner);
    });
    AudioHowl.play({ type: ISongs.TotalWinBanner, stopPrev: true });

    totalWinDelay.start();
    this.addChild(
      new MessageWinBanner({
        ...props,
        callback: () => {
          totalWinDelay.skip();
          if (props.callback) props.callback();
        },
      }).init(),
    );
  }

  private removeFreeSpinsTitle(): void {
    if (this.freeSpinsPanel) {
      this.removeChild(this.freeSpinsPanel);
      this.freeSpinsPanel?.destroy({
        children: true,
      });
      this.freeSpinsPanel = undefined;
    }
  }

  private createFreeSpinsTitle(props: FreeSpinsTitleProps): void {
    this.freeSpinsPanel = new FreeSpinsPanel(props);
    this.addChildAt(
      this.freeSpinsPanel,
      this.getPositionOfFreeSpinPanelInScene(),
    );
  }

  private createHcmMessage(): void {
    const hcmMessage = new HcmMessage();
    this.addChild(hcmMessage);
  }

  private onModeChange(settings: { mode: GameMode }): void {}

  private resize(
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);

    if (width > height) {
      this.girl.position.set(
        GIRL_LANDSCAPE_POSITION_X,
        GIRL_LANDSCAPE_POSITION_Y,
      );
      this.girl.scale.set(1, 1);
    } else {
      this.girl.position.set(
        GIRL_PORTRAIT_POSITION_X,
        GIRL_PORTRAIT_POSITION_Y,
      );
      this.girl.scale.set(1.5);
    }
  }
}

export default GameView;
