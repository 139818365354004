import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { isFreeSpinsMode } from '../../../utils';
import SpineAnimation from '../../animations/spine';
import ViewContainer from '../../components/container';
import {
  eventManager,
  SLOTS_BACKGROUND_HEIGHT,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
} from '../../config';

class ReelsFrame extends ViewContainer {
  private baseFrameTex = PIXI.Texture.from(ResourceTypes.reelFrame);

  private fsFrameTex = PIXI.Texture.from(ResourceTypes.freeSpinReelFrame);

  private frame: PIXI.Sprite;

  private frameAnimation: SpineAnimation | undefined;

  constructor() {
    super();
    this.sortableChildren = true;
    this.frame = this.initFrame();

    this.addChild(this.frame);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(
      EventTypes.MANUAL_CHANGE_BACKGROUND,
      this.onModeChange.bind(this),
    );
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isFreeSpinsMode(settings.mode)) {
      this.frame.texture = this.fsFrameTex;
      this.frame.y = SLOTS_CONTAINER_HEIGHT + 60;

      this.frameAnimation = this.initFrameAnimation();
      this.addChild(this.frameAnimation.spine);
    } else {
      this.frame.texture = this.baseFrameTex;
      this.frame.y = SLOTS_CONTAINER_HEIGHT + 35;

      if (this.frameAnimation) this.removeChild(this.frameAnimation.spine);
    }
  }

  private initFrame(): PIXI.Sprite {
    const frame = new PIXI.Sprite(this.baseFrameTex);
    frame.anchor.set(0.5, 1);
    frame.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT + 35);
    frame.zIndex = 10;
    return frame;
  }

  private initFrameAnimation(): SpineAnimation {
    const anim = new SpineAnimation(
      {},
      PIXI.Loader.shared.resources.reel.spineData,
    );
    anim.setAnimation('reelframe_freespins', true);
    anim
      .getSpine()
      .position.set(
        SLOTS_CONTAINER_WIDTH / 2,
        SLOTS_CONTAINER_HEIGHT - SLOTS_BACKGROUND_HEIGHT / 2,
      );
    return anim;
  }
}

export default ReelsFrame;
