import AudioHowl from '@phoenix7dev/play-music';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { ISongs, SlotId } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import {
  destroySpine,
  isFreeSpinsMode,
  isHighChanceMode,
  queryParams,
} from '../../utils';
import Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ADDITIONAL_SPIN_TIME_PER_REEL,
  ANTICIPATION_SLOTS_TINT,
  BASE_REEL_ENDING_DURATION,
  BASE_REEL_ENDING_FORMULA,
  BASE_REEL_ROLLING_DURATION,
  BASE_REEL_ROLLING_SPEED,
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  eventManager,
  FAKE_REPLAY_ROLLING_DURATION,
  FAKE_ROLLING_DURATION,
  FS_ADDITIONAL_SPIN_TIME_PER_REEL,
  HCM_ADDITIONAL_SPIN_TIME_PER_REEL,
  MINIMUM_SPIN_SLOTS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT,
  REEL_STARTING_SLOTS_AMOUNT,
  REEL_WIDTH,
  ReelState,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  TURBO_ADDITIONAL_SPIN_TIME_PER_REEL,
  TURBO_REEL_ENDING_DURATION,
  TURBO_REEL_ROLLING_DURATION,
  TURBO_REEL_ROLLING_SPEED,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
} from '../config';
import { Slot } from '../slot/slot';
import SpinAnimation from '../spin/spin';
import { IReel } from './d';

class Reel implements IReel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public container: ViewContainer;

  public position = 0;

  public previousPosition = 0;

  public spinAnimation: SpinAnimation | null = null;

  public slots: Slot[] = [];

  public animator: () => void = this.reelAnimator.bind(this);

  public isPlaySoundOnStop = false;

  public isTurboSpin = false;

  public size: number;

  public stopSoundSymbolNo = 0;

  public isLongSpin = false;

  public anticipationAnimation: Animation | undefined;

  constructor(id: number, data: SlotId[], startPosition: number) {
    this.id = id;
    this.data = data;
    this.size = data.length;
    this.state = ReelState.IDLE;
    this.container = new ViewContainer();
    this.container.width = REEL_WIDTH;
    this.container.x = id * REEL_WIDTH;
    this.container.y = 0;
    this.container.sortableChildren = true;
    this.createSlots();
    this.position = this.size - startPosition;
    eventManager.addListener(
      EventTypes.ANTICIPATION_STARTS,
      this.onAnticipationStart.bind(this),
    );
    eventManager.addListener(
      EventTypes.ANTICIPATION_ANIMATIONS_START,
      this.onAnticipationAnimationStarts.bind(this),
    );
    eventManager.addListener(
      EventTypes.ANTICIPATION_ANIMATIONS_END,
      this.resetSlotsTint.bind(this),
    );
    eventManager.addListener(
      EventTypes.REELS_STOPPED,
      this.onReelsStopped.bind(this),
    );
    eventManager.addListener(
      EventTypes.REMOVE_ANTICIPATION_TINT,
      this.resetSlotsTint.bind(this),
    );
  }

  public init(data: SlotId[], position: number): void {
    this.data = data;
    this.size = data.length;
    this.createSlots();
    this.position = position;
  }

  public clean(): void {
    this.container.removeChildren();
    this.slots = [];
  }

  private createAnticipationEffectAnimation(): Animation {
    const nearMiss = new SpineAnimation(
      {},
      PIXI.Loader.shared.resources.near_miss.spineData,
    );
    nearMiss.spine.zIndex = 5;
    nearMiss.spine.position.set(SLOT_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    nearMiss.setAnimation('Nearmiss', false);

    this.container.addChild(nearMiss.spine);

    nearMiss.addOnComplete(() => {
      nearMiss.spine.state.clearTracks();
      destroySpine(nearMiss);
      this.anticipationAnimation = undefined;
    });

    nearMiss.addOnSkip(() => {
      destroySpine(nearMiss);
      this.anticipationAnimation = undefined;
    });

    return nearMiss;
  }

  private onAnticipationAnimationStarts(): void {
    this.slots.forEach((slot) => {
      if (slot.slotId !== SlotId.A && slot.slotId !== SlotId.B) {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      }
    });
  }

  private onAnticipationStart(index: number): void {
    if (this.id !== index) return;
    this.isLongSpin = true;
    AudioHowl.play({ type: ISongs.LongSpin, stopPrev: true });

    this.anticipationAnimation = this.createAnticipationEffectAnimation();
    this.anticipationAnimation.start();
  }

  private onReelsStopped(): void {
    this.resetSlotsTint();
  }

  private resetSlotsTint(): void {
    _.forEach(this.slots, (slot) => {
      slot.tint = 0xffffff;
    });
  }

  private createSlots(): void {
    for (let i = 0; i < this.data.length; i++) {
      const slotId = this.data[i % this.data.length];
      const slot = new Slot(i, slotId);
      this.slots.push(slot);
      this.container.addChild(slot);
    }
  }

  public getTarget(expected: number): number {
    if (expected - this.position > MINIMUM_SPIN_SLOTS_AMOUNT) {
      return expected;
    }
    let amount = expected - this.position;
    while (amount < MINIMUM_SPIN_SLOTS_AMOUNT) amount += this.data.length;
    return amount + this.position;
  }

  private getRollingDuration(gameMode: GameMode): number {
    if (this.isTurboSpin) {
      return (
        TURBO_REEL_ROLLING_DURATION +
        this.id * TURBO_ADDITIONAL_SPIN_TIME_PER_REEL
      );
    }

    if (isFreeSpinsMode(gameMode)) {
      return (
        BASE_REEL_ROLLING_DURATION + this.id * FS_ADDITIONAL_SPIN_TIME_PER_REEL
      );
    }

    if (isHighChanceMode(gameMode)) {
      return (
        BASE_REEL_ROLLING_DURATION + this.id * HCM_ADDITIONAL_SPIN_TIME_PER_REEL
      );
    }

    return BASE_REEL_ROLLING_DURATION + this.id * ADDITIONAL_SPIN_TIME_PER_REEL;
  }

  public createSpinAnimation(isTurboSpin: boolean | undefined): SpinAnimation {
    this.position %= this.data.length;
    this.isTurboSpin = !!isTurboSpin;
    const rollingSpeed = isTurboSpin
      ? TURBO_REEL_ROLLING_SPEED
      : BASE_REEL_ROLLING_SPEED;
    const rollingTime = this.getRollingDuration(setGameMode());
    const target = this.position + Math.round(rollingTime * rollingSpeed);

    const starting = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT,
      easing: BASE_REEL_STARTING_FORMULA,
      delay:
        (isTurboSpin
          ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL
          : SPIN_REEL_ANIMATION_DELAY_PER_REEL) * this.id,
      duration: isTurboSpin
        ? TURBO_REEL_STARTING_DURATION
        : BASE_REEL_STARTING_DURATION,
    });
    starting.addOnStart(() => {
      this.changeState(ReelState.STARTING);
    });
    const fakeRollingDuration = queryParams.has('replayBetId')
      ? FAKE_REPLAY_ROLLING_DURATION
      : FAKE_ROLLING_DURATION;
    const fakeRolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target:
        this.position +
        REEL_STARTING_SLOTS_AMOUNT +
        Math.round(fakeRollingDuration * rollingSpeed),
      duration: fakeRollingDuration,
    });
    fakeRolling.addOnStart(() => {
      this.changeState(ReelState.ROLLING);
    });
    const rolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: target - REEL_ENDING_SLOTS_AMOUNT,
      duration: rollingTime,
    });
    const ending = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: target - REEL_ENDING_SLOTS_AMOUNT,
      target,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: isTurboSpin
        ? TURBO_REEL_ENDING_DURATION
        : BASE_REEL_ENDING_DURATION,
    });
    ending.addOnStart(() => {
      this.changeState(ReelState.ENDING);
    });
    ending.addOnComplete(() => {
      this.changeState(ReelState.IDLE);
      this.onReelStop();
    });
    this.spinAnimation = new SpinAnimation({
      startingAnimation: starting,
      fakeRollingAnimation: fakeRolling,
      rollingAnimation: rolling,
      endingAnimation: ending,
    });
    return this.spinAnimation;
  }

  private onReelEnding(previousState: ReelState, newState: ReelState): void {}

  private onReelStop(): void {
    this.anticipationAnimation?.skip();
    this.anticipationAnimation = undefined;
    if (this.isLongSpin) {
      AudioHowl.stop({ type: ISongs.LongSpin });
    }
    this.isLongSpin = false;

    if (this.isPlaySoundOnStop) {
      const idx = this.stopSoundSymbolNo;
      if (isFreeSpinsMode(setGameMode())) {
        const reelFreeSpinStopSoundList = [
          ISongs.SFX_UI_SpinStop,
          ISongs.FSStopx2,
          ISongs.FSStopx3,
          ISongs.FSStopx5,
        ];
        AudioHowl.play({
          type: reelFreeSpinStopSoundList[idx],
          stopPrev: true,
        });
      } else {
        const reelStopSoundList = [
          ISongs.SFX_UI_SpinStop,
          ISongs.BonusStop1,
          ISongs.BonusStop2,
          ISongs.BonusStop3,
          ISongs.CherryStop1,
          ISongs.CherryStop2,
          ISongs.CherryStop3,
        ];
        AudioHowl.play({
          type: reelStopSoundList[idx],
          stopPrev: true,
        });
      }

      this.isPlaySoundOnStop = false;
      this.stopSoundSymbolNo = 0;
    }
  }

  private onReelIdle(previousState: ReelState, newState: ReelState): void {
    if (previousState === ReelState.ENDING) {
      eventManager.emit(EventTypes.REEL_STOPPED, this.id);
      const reelStopSlots = this.getReelStopSlots(Math.round(this.position));
      _.forEach(reelStopSlots, (slot) => {
        slot.onSlotStopped();
      });
    }
  }

  public stopReel(endingDuration: number): void {
    this.spinAnimation!.getStarting().end();
    this.spinAnimation!.getFakeRolling().end();
    this.spinAnimation!.getRolling().end();
    this.spinAnimation!.getEnding().duration = endingDuration;
  }

  private getReelStopSlots(position: number): Array<Slot> {
    const slots: Array<Slot> = [];
    const top = this.slots.length - ((position % this.slots.length) + 1);
    const middle =
      position % this.slots.length === 0
        ? 0
        : this.slots.length - (position % this.slots.length);
    const bottom =
      (this.slots.length - ((position % this.slots.length) - 1)) %
      this.slots.length;
    const extra =
      (this.slots.length - ((position % this.slots.length) - 2)) %
      this.slots.length;
    slots.push(this.slots[top]);
    slots.push(this.slots[middle]);
    slots.push(this.slots[bottom]);
    slots.push(this.slots[extra]);
    return slots;
  }

  private onReelRolling(previousState: ReelState, newState: ReelState): void {}

  private onReelStarting(previousState: ReelState, newState: ReelState): void {}

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.ROLLING) {
      this.onReelRolling(previousState, ReelState.ROLLING);
    }
    if (newState === ReelState.STARTING) {
      this.onReelStarting(previousState, ReelState.STARTING);
    }
    if (newState === ReelState.ENDING) {
      this.onReelEnding(previousState, ReelState.ENDING);
    }
  }

  public reelAnimator(): void {
    this.previousPosition = this.position;
    // Update symbol positions on reel.
    for (let j = 0; j < this.slots.length; j++) {
      const slot = this.slots[j];
      slot.y =
        ((this.position + j + 2) % this.slots.length) * SLOT_HEIGHT -
        SLOT_HEIGHT;

      slot.toggleBlur(this.state === ReelState.ROLLING);
    }
  }
}

export default Reel;
